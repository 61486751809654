
export const getFechaHoy = () => {
    const fechaToday = new Date();

    let anioHoy = fechaToday.getFullYear('America/Bogota') + '';
    let mesHoy = fechaToday.getMonth('America/Bogota') + 1 + '';
    let diaHoy = fechaToday.getDate('America/Bogota') + '';

    diaHoy = diaHoy.length === 1 ? '0' + diaHoy : diaHoy;
    mesHoy = mesHoy.length === 1 ? '0' + mesHoy : mesHoy;

    return `${diaHoy}/${mesHoy}/${anioHoy}`
}