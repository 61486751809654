import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import TIPOSPRUEBA from "../helpers/tiposPrueba";
import NOMBREPRUEBA from "../helpers/nombrePrueba";
import BRIGADA from "../helpers/brigada";

const schema = yup.object().shape({
    consecutivo: yup
        .number("El consecutivo es un string")
        .typeError('Debe ingresar un consecutivo')
        .required("Debe ingresar un consecutivo")
        .required("Is required")
        .positive("Debe ser positivo")
        .integer("Debe ser un entero")
        .min(1, "Mínimo es 1")
        .max(3000, "Máximo es 3000"),

    brigada: yup
        .string('El lugar es un string')
        .required("Debe seleccionar un lugar")
        .oneOf(BRIGADA.map( brigada => (`${brigada}`)), "El lugar no es válido, elija uno"),

    fechaPrueba: yup
        .date()
        .typeError('Ingresa una fecha DD/MM/AAA')
        .required("Debe ingresar fecha de prueba"),

    nombrePrueba: yup
        .string("El nombre de prueba debe ser un texto")
        .required("Debe seleccionar el nombre de la prueba")
        .oneOf(NOMBREPRUEBA.map( tipo => (`${tipo}`)), "El nombre de prueba no es válido, elija uno"),

    tipoPrueba: yup
        .string("El tipo de prueba debe ser un texto")
        .typeError("Debe seleccionar un tipo de prueba")
        .required("Debe seleccionar un tipo de prueba")
        .oneOf(TIPOSPRUEBA.map( tipo => (`${tipo}`)), "El tipo de prueba no es válido, elija uno"),

   })

export default yupResolver(schema)