import React, {useRef} from 'react';

import {Card, Col, Container, Form, Row} from "react-bootstrap";
import * as XLSX from "xlsx";
import {toast} from "react-toastify";
import useAuth from "../../auth/useAuth";


const AntigenoFindPage = () => {

    const {getResultadosAntigeno, setAllAntigenoResultsXLSX} = useAuth();
    //const [dataFinal, setDataFinal] = useState([]);
    //const [cols, setCols] = useState([]);
    const inputFile = useRef();

    /* generate an array of column objects */
    /*const make_cols = refstr => {
        let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
        for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
        return o;
    };*/

    const SheetJSFT = [
        //"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
        "xlsx"
    ].map(x => `.${x}`).join(",");

    const handleFile = (file) => {

        try{
            const {type, name} = file;
            const ext = name.split('.')[[name.split('.')].length]
            if(type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || ext !== "xlsx" ){
                inputFile.current.value = "";
                return toast.error('El documento no tiene extensión XLSX, debe ser un MS Excel')
            }
            const reader = new FileReader();
            reader.onload = async (e) => {
                /* Parse data */
                const ab = e.target.result;

                const wb = XLSX.read(ab, {type: 'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, {header: 1});

                /* Update state */
                //ajustar data eliminando filas vacías antes de almacenarla en el estado
                const datosExcel = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].length > 0) {
                        if (i === 0) {
                            const fila = data[i];
                            const d0 = fila[0] !== undefined ? fila[0].toString().trim() : undefined;
                            const d1 = fila[1] !== undefined ? fila[1].toString().trim() : undefined;
                            const d2 = fila[2] !== undefined ? fila[2].toString().trim() : undefined;
                            const d3 = fila[3] !== undefined ? fila[3].toString().trim() : undefined;
                            const d4 = fila[4] !== undefined ? fila[4].toString().trim() : undefined;
                            datosExcel.push([d0, d1, d2, d3, d4])
                        } else {
                            const fila = data[i];
                            const d0 = fila[0] !== undefined ? fila[0] : undefined;
                            const d1 = fila[1] !== undefined ? fila[1].toString().trim() : undefined;
                            const d2 = fila[2] !== undefined ? fila[2].toString().trim() : undefined;
                            const d3 = fila[3] !== undefined ? fila[3].toString().trim() : undefined;
                            const d4 = fila[4] !== undefined ? fila[4].toString().trim() : undefined;
                            datosExcel.push([d0, d1, d2, d3, d4])
                        }
                    }
                }
                //Verificar que el documento cuente con 5 encabezados
                const encabezados = datosExcel[0];
                if (encabezados.length !== 5) {
                    inputFile.current.value = "";
                    return toast.error('El documento de resultados solo debe tener 5 columnas')
                }
                // verificar cada uno de los encabezados
                if (encabezados[0] !== '#') {
                    inputFile.current.value = "";
                    return toast.error('La Columna A no tiene el encabezado #')
                }
                if (encabezados[1] !== 'LUGAR') {
                    inputFile.current.value = "";
                    return toast.error('La Columna B no tiene el encabezado LUGAR')
                }
                if (encabezados[2] !== 'RESULTADO') {
                    inputFile.current.value = "";
                    return toast.error('La Columna C no tiene el encabezado RESULTADO')
                }
                if (encabezados[3] !== 'FECHA DE RESULTADO') {
                    inputFile.current.value = "";
                    return toast.error('La Columna D no tiene el encabezado FECHA DE RESULTADO')
                }
                if (encabezados[4] !== 'FECHA RECEPCION') {
                    inputFile.current.value = "";
                    return toast.error('La Columna E no tiene el encabezado FECHA RECEPCION')
                }

                // verificar consecutivo columna # (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];
                    //console.log(fila)
                    if (i !== fila[0]) {
                        inputFile.current.value = "";
                        //console.log('Consecutivo correcto: ', i, 'Incorrecto: ', fila[0])
                        return toast.error(`El consecutivo de la Columna A, Fila ${i + 1} no es correcto`)
                    } else if ((typeof fila[0]) !== "number") {
                        //console.log('(typeof fila[0]) !== "number"', typeof fila[0] !== "number")
                        inputFile.current.value = "";
                        return toast.error(`El consecutivo de la Columna A, Fila ${i + 1} debe ser de tipo numérico`)
                    }
                }

                // verificar columna LUGAR (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if (!(fila[1] === 'PAMPLONA' ||
                        fila[1] === 'CUCUTA')) {
                        inputFile.current.value = "";
                        return toast.error(`El LUGAR en la Columna B, Fila ${i + 1} no es correcto`)
                    }
                }

                // verificar columna LUGAR todos sean iguales(no contemplar el encabezado)
                for (let i = 1; i < (datosExcel.length - 1); i++) {
                    const filaAntes = datosExcel[i];
                    const filaDespues = datosExcel[i+1];
                    if (filaAntes[1] !== filaDespues[1]) {
                        inputFile.current.value = "";
                        return toast.error(`El LUGAR en la Columna B, Fila ${i + 1} no es correcto, todos los lugares deben ser iguales`)
                    }
                }

                // verificar columna RESULTADO (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if (!(fila[2] === 'POSITIVO' ||
                        fila[2] === 'NEGATIVO' ||
                        fila[2] === 'INDETERMINADO' ||
                        fila[2] === 'SIN PROCESAR' ||
                        fila[2] === 'EN PROCESAMIENTO' ||
                        fila[2] === 'INVALIDO')) {

                        inputFile.current.value = "";
                        return toast.error(`El RESULTADO de la Columna B, Fila ${i + 1} no es correcto`)
                    }
                }


                /*// verificar columna CT para positivos (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];
                    if (fila[1] === 'POSITIVO' && fila[2] === '') {
                        //console.log(fila[1], fila[1].length, 'NEGATIVO'.length)
                        inputFile.current.value = "";
                        return toast.error(`Falta CT de la Columna C, Fila ${i + 1}`)
                    }
                    //Poner un positivo sin CT para probar

                }

                // verificar columna CT para no positivos (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];
                    if (fila[1] !== 'POSITIVO' && fila[2] !== undefined) {
                        //console.log(fila[1], fila[1].length, 'NEGATIVO'.length)
                        inputFile.current.value = "";
                        return toast.error(`CT incorrecto en la Columna C, Fila ${i + 1}`)
                    }
                }*/


                // verificar columna "FECHA DE RESULTADO" (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];
                    //console.log(fila, fila[3])

                    if (fila[3].length === 10) {
                        //fecha actual
                        let anioH = new Date().getFullYear('America/Bogota') + '';
                        let mesH = new Date().getMonth('America/Bogota') + 1 + '';
                        let diaH = new Date().getDate('America/Bogota') + '';
                        diaH = diaH.length === 1 ? '0' + diaH : diaH;
                        mesH = mesH.length === 1 ? '0' + mesH : mesH;

                        //Fecha Resultado
                        const dia = fila[3].split('/')[0];
                        const mes = fila[3].split('/')[1];
                        const anio = fila[3].split('/')[2];

                        const fechaResultado = new Date(anio, mes, dia);
                        const fechaHoy = new Date(anioH, mesH, diaH);

                        if (fechaResultado > fechaHoy) {
                            inputFile.current.value = "";
                            return toast.error(`No se permiten fechas futuras, error en la Columna D, Fila ${i + 1}`)
                        }

                        // verificar que el formato sea DD/MM/AAAA

                        const pattern = /^((0[1-9]|[12][0-9]|3[01])(\/)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\/)(02))|((0[1-9]|[12][0-9]|3[0])(\/)(0[469]|11))(\/)\d{4}$/;

                        if (!pattern.test(fila[3])) {
                            //console.log('pattern.test(fila[3])',!pattern.test(fila[3]))
                            inputFile.current.value = "";
                            return toast.error(`Formato de fecha incorrecto en la Columna D, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                        }

                    } else {
                        inputFile.current.value = "";
                        return toast.error(`Fecha incorrecta en la Columna D, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                    }
                }


                // verificar columna "FECHA DE RECEPCION" (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if (fila[4].length === 10) {
                        //fecha actual
                        let anioH = new Date().getFullYear('America/Bogota') + '';
                        let mesH = new Date().getMonth('America/Bogota') + 1 + '';
                        let diaH = new Date().getDate('America/Bogota') + '';
                        diaH = diaH.length === 1 ? '0' + diaH : diaH;
                        mesH = mesH.length === 1 ? '0' + mesH : mesH;

                        //Fecha Resultado
                        const dia = fila[4].split('/')[0];
                        const mes = fila[4].split('/')[1];
                        const anio = fila[4].split('/')[2];

                        const fechaResultado = new Date(anio, mes, dia);
                        const fechaHoy = new Date(anioH, mesH, diaH);

                        if (fechaResultado > fechaHoy) {
                            inputFile.current.value = "";
                            return toast.error(`No se permiten fechas futuras, error en la Columna E, Fila ${i + 1}`)
                        }

                        // verificar que el formato sea DD/MM/AAAA
                        const pattern = /^((0[1-9]|[12][0-9]|3[01])(\/)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\/)(02))|((0[1-9]|[12][0-9]|3[0])(\/)(0[469]|11))(\/)\d{4}$/;

                        if (!pattern.test(fila[4])) {
                            //console.log('pattern.test(fila[3])',!pattern.test(fila[3]))
                            inputFile.current.value = "";
                            return toast.error(`Formato de fecha incorrecto en la Columna E, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                        }

                    } else {
                        inputFile.current.value = "";
                        return toast.error(`Fecha incorrecta en la Columna E, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                    }
                }


                //console.log('Columnas del Excel', cols)

                //validar que todos los campos sean correctos, (dimesiones, encabezados y valores por defecto de resultados)
                //llamar datos en la fecha indicada y si coincide actualizar resultados
                // de lo contrario iniciar que en la fecha no hay resultados para subir

                //Obtener las pruebas de la fecha

                const fecha = datosExcel[1][4]; // fecha de recepción o de realización de pa prueba
                const brigada = datosExcel[1][1]; // Selecciona el lugar de realización de las pruebas para subir los resultados
                console.log('DatosExcel',datosExcel)
                const resultadosAntigenos = await getResultadosAntigeno(fecha, brigada);
                if (resultadosAntigenos) {
                    // comparar las dimensiones de la BD con los del EXCEL
                    if(resultadosAntigenos.length === (datosExcel.length - 1)){
                        console.log('muestras desde el servidor', resultadosAntigenos)
                        const respuesta = await setAllAntigenoResultsXLSX({excel: datosExcel, muestrasAntigeno: resultadosAntigenos});
                        if(respuesta){
                            inputFile.current.value = "";
                            toast.success(respuesta.message)
                        } else {
                            inputFile.current.value = "";
                            return toast.error("Los resultados no pudieron ser actualizados en DB")
                        }


                    } else{
                        inputFile.current.value = "";
                        return toast.error("La cantidad de resultados ingresada en el XLSX no coincide con las DB")
                    }

                } else {
                    const {message} = resultadosAntigenos;
                    toast.error(message)
                }

            };
            reader.readAsArrayBuffer(file);

        } catch (e) {
            console.log(e)
        }

    }



    const handleChange = (e) => {
        const files = e.target.files;
        if(files && files[0]) handleFile(files[0]);
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Subir resultados de antígenos</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>

                        {/*<Form.Group>
                            <Form.Label>Consecutivo del día</Form.Label>
                            <Form.Control
                                placeholder="Pamplona: #P, Cúcuta: #C, ej: 1P o 1C"
                                type="text"
                                {...register("consecutivo")}
                            />
                            {errors?.consecutivo && (
                                <Form.Text>
                                    <Alert variant="danger">
                                        {errors.consecutivo.message}
                                    </Alert>
                                </Form.Text>
                            )}
                        </Form.Group>*/}
                        <Form draggable="false">
                            <Form.Group>
                                <Form.Label htmlFor="file" className="small font-weight-bold">Elija el archivo excel (solo XLSX) de resultados</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="file"
                                    accept={SheetJSFT}
                                    onChange={handleChange}
                                    ref={inputFile}
                                    draggable={false}
                                />
                            </Form.Group>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

export default AntigenoFindPage;