import React, {useEffect} from 'react';
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import TRABAJADORSALUD from "../../helpers/trabajadorSalud";
import AntigenoUpdateResolver from "../../validations/AntigenoUpdateResolver";
import {toast} from "react-toastify";
import useAuth from "../../auth/useAuth";
import {useHistory} from "react-router-dom";
import NOMBREPRUEBA from "../../helpers/nombrePrueba";
import TIPOSPRUEBA from "../../helpers/tiposPrueba";
import eventos from "../../helpers/eventos";
import BRIGADA from "../../helpers/brigada";

const AntigenoUpdatePage = ({location}) => {

    const history = useHistory();
    const {muestra, persona} = location.state;
    const {updateSampleAntigeno} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: AntigenoUpdateResolver});
    const {errors, dirtyFields} = formState;
    const isDirty = !!Object.keys(dirtyFields).length;


    useEffect(() => {
        const [dia, mes, anio] = muestra.fechaPrueba.split('/');
        if(muestra){
           reset({
               consecutivo: muestra.consecutivo,
               brigada: muestra.brigada,
               fechaPrueba: `${anio}-${mes}-${dia}`,
               evento: muestra.evento,
               trabajadorSalud: muestra.trabajadorSalud,
               posSintomatico: muestra.posSintomatico,
               nombrePrueba: muestra.nombrePrueba,
               tipoPrueba: muestra.tipoPrueba,
               resultado: muestra.resultado,
               observacionesInternas: muestra.observacionesInternas,
               observaciones: muestra.observaciones
           });
        }
    },[muestra, reset])

    const onSubmit = async (formData) => {
        formData.idMuestra = muestra._id;
        const fecha = formData.fechaPrueba;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fechaPrueba = `${dia}/${mes}/${anio}`

        const antigeno = await updateSampleAntigeno(formData);
        if(antigeno){
            toast.success('Muestra actualizada exitosamente')
            reset();

            // redireccion para asociar muestra
            history.push({
                pathname: '/sumary',
            })
        } else {
            toast.error('Error al actualizar la muestra')
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card shadow border-0 mt-3">
                        <h3 className="text-center my-3">Actualizar prueba de Antígeno del paciente</h3>
                    </div>
                </div>

            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4 mt-3">

                        <div className="card border-0 shadow p-3">
                            <p className="text-center font-weight-bold m-0">Información del paciente: </p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Nombre:</span> {persona.primerNombre} {persona.primerApellido}</p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Identificación:</span> {persona.numDocumento}</p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Fecha de la prueba:</span> {muestra.fechaPrueba}</p>
                        </div>

                        <div className="card shadow border-0 mt-2 p-3">

                            <div className="row">
                                <div className="col-5 text-center">
                                    <Form.Group>
                                        <Form.Label className="small font-weight-bold">Consecutivo</Form.Label>
                                        <Form.Control
                                            placeholder="#"
                                            type="number"
                                            {...register("consecutivo")}
                                        />
                                        {errors?.consecutivo && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.consecutivo.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-7 text-center">
                                    <Form.Group>
                                        <Form.Label className="small font-weight-bold">Lugar</Form.Label>
                                        <Form.Control
                                            as="select"
                                            {...register("brigada")}
                                        >
                                            <option value="">Lugar</option>
                                            {BRIGADA.map( brigada => <option key={brigada}>{brigada}</option> )}
                                        </Form.Control>
                                        {errors?.brigada && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.brigada.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Fecha de prueba</Form.Label>
                                <Form.Control
                                    placeholder="Escriba la fecha de prueba"
                                    {...register("fechaPrueba")}
                                    type="date"
                                />
                                {errors?.fechaPrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fechaPrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Nombre de prueba</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("nombrePrueba")}
                                >
                                    <option value="">Seleccione una prueba</option>
                                    {NOMBREPRUEBA.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.nombrePrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.nombrePrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>



                        </div>
                    </div>

                    <div className="col-md-4 mt-2">
                        <div className="card shadow border-0 mt-2 p-3">

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Tipo de prueba</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoPrueba")}
                                >
                                    <option value="">Seleccione tipo</option>
                                    {TIPOSPRUEBA.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.tipoPrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoPrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Evento</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("evento")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {eventos.map( evento => <option key={evento}>{evento}</option> )}
                                </Form.Control>
                                {errors?.evento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.evento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Trabajador de la salud</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("trabajadorSalud")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {TRABAJADORSALUD.map( trabajador => <option key={trabajador.tipo}>{trabajador.tipo}</option> )}
                                </Form.Control>
                                {errors?.trabajadorSalud && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.trabajadorSalud.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Pos Sintomático</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("posSintomatico")}
                                >
                                    <option value="">Seleccione uno</option>
                                    {TRABAJADORSALUD.map( trabajador => <option key={trabajador.tipo}>{trabajador.tipo}</option> )}
                                </Form.Control>
                                {errors?.posSintomatico && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.posSintomatico.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>



                        </div>
                    </div>

                    <div className="col-md-4 mt-2">
                        <div className="card shadow border-0 mt-2 p-3 mb-5">


                            {/*<Form.Group>
                                <Form.Label>Cod. Municipio</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("codigoMunicipio")}
                                >
                                    <option value="">Seleccione un código</option>
                                    {DIVIPOLA.map( div => <option key={div.codigoMunicipio}>{div.municipio} - {div.codigoMunicipio}</option> )}
                                </Form.Control>
                                {errors?.codigoMunicipio && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.codigoMunicipio.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>*/}
                            {/*<Form.Group>
                                <Form.Label>Resultado</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("resultado")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {RESULTADOS.map( res => <option key={res}>{res}</option> )}
                                </Form.Control>
                                {errors?.resultado && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.resultado.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>*/}

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Observaciones internas del lab</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="5"
                                    placeholder="Escriba las observaciones internas del laboratorio"
                                    {...register("observacionesInternas")}
                                    type="text"
                                />
                                {errors?.observacionesInternas && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.observacionesInternas.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Observaciones para reporte</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="5"
                                    placeholder="Escriba las observaciones para el reporte en PDF"
                                    {...register("observaciones")}
                                    type="text"
                                />
                                {errors?.observacionesExternas && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.observacionesExternas.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                disabled={!isDirty}
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Actualizar muestra</Button>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12"></div>
                </div>

            </Form>
        </div>
    );
};

export default AntigenoUpdatePage;