export const endpoint = 'https://cedimolserver.unipamplona.edu.co/api';
//export const endpoint = 'http://localhost:8000/api';

export const headers = () => {
    const h =  new Headers()
    h.append('Content-Type', 'application/json')
    h.append('Access-Control-Allow-Origin', '*')
    h.append('Access-Control-Request-Headers', '*')
    h.append('Access-Control-Request-Method', '*')
    h.append('Accept', 'application/json')

    const session = {
        token: localStorage.getItem('token')
    }

    if(session.token) {
        h.append('x-access-token', session.token)
    }
    return h
}
