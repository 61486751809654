import { Route, Switch} from 'react-router-dom';
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";

import AccountPage from "../pages/AccountPage/AccountPage";

import SumaryPage from "../pages/SumaryPage";
import AdminPage from "../pages/admin/AdminPage";
import NotFoundPage from "../pages/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import roles from "../helpers/roles";
import routes from "../helpers/routes";
import RootPage from "../pages/root/RootPage";
import PersonPage from "../pages/PersonPage";
import CreatePerson from "../pages/PersonPage/components/CreatePerson";
import UpdatePerson from "../pages/PersonPage/components/UpdatePerson";
import SamplePage from "../pages/SamplePage";
import SampleUpdatePage from "../pages/SamplePage/SampleUpdatePage";
import PersonFind from "../pages/PersonPage/components/PersonFind";
import SampleFindUpdatePage from "../pages/SamplePage/SampleFindUpdatePage";
import SampleListPage from "../pages/SamplePage/SampleListPage";
import CovidFindPage from "../pages/CovidPage/CovidFindPage";
import CovidListPage from "../pages/CovidPage/CovidListPage";
import CovidUpdatePage from "../pages/CovidPage/CovidUpdatePage";
import CovidHomePage from "../pages/CovidPage/CovidHomePage";
import CovidCreatePage from "../pages/CovidPage/CovidCreatePage";
import AntigenoHomePage from "../pages/AntigenoPage";
import AntigenoCreatePage from "../pages/AntigenoPage/AntigenoCreatePage";
import AntigenoFindPage from "../pages/AntigenoPage/AntigenoFindPage";
import AntigenoListPage from "../pages/AntigenoPage/AntigenoListPage";
import AntigenoUpdatePage from "../pages/AntigenoPage/AntigenoUpdatePage";
import PlanillaAntigenosPage from "../pages/AntigenoPage/PlanillaAntigenosPage";
import ResultadosAntigenoFindPage from "../pages/AntigenoPage/ResultadosAntigenoFindPage";
import ResultadosAntigenoListPage from "../pages/AntigenoPage/ResultadosAntigenoListPage";
import AntigenoUploadResultadosXLSXPage from "../pages/AntigenoPage/AntigenoUploadResultadosXLSXPage";
import PlanillaAntigenosResultadosPage from "../pages/AntigenoPage/PlanillaAntigenosResultadosPage";
import PlanillaSivigilaAntigenosPage from "../pages/AntigenoPage/PlanillaSivigilaAntigenosPage";
import useAuth from "../auth/useAuth";
import PlanillaWordAntigenosPage from "../pages/AntigenoPage/PlanillaWordAntigenosPage";
import PlanillaAntigenosCorreosPage from "../pages/AntigenoPage/PlanillaAntigenosCorreosPage";

const AppRouter = () => {
    const {hasRole}  = useAuth();
    return (
        /*<Router>*/
        /* El Router es usado en el App.js para poder acceder a
           las funciones del react-router-dom desde el AuthProvider
           para controlar la redirección desde allí */

            <Switch>
                <PublicRoute exact path={routes.home} component={HomePage}/>
                <PublicRoute exact path={routes.login} component={LoginPage}/>
                {/*<PublicRoute exact path={routes.register} component={RegisterPage}/>*/}
                <PrivateRoute exact path={routes.account} component={AccountPage}/>
                <PrivateRoute exact path={routes.sumary} component={SumaryPage}/>

                <PrivateRoute exact path={routes.persona.home} component={PersonPage}/>
                <PrivateRoute exact path={routes.persona.crear} component={CreatePerson}/>
                <PrivateRoute exact path={routes.persona.actualizar} component={UpdatePerson}/>
                <PrivateRoute exact path={routes.persona.buscar} component={PersonFind}/>

                {/*Muestras A*/}
                {/*Covid*/}
                <PrivateRoute exact path={routes.muestra.covid.home} component={CovidHomePage}/>
                <PrivateRoute exact path={routes.muestra.covid.crear} component={CovidCreatePage}/>
                <PrivateRoute exact path={routes.muestra.covid.buscar} component={CovidFindPage}/>
                <PrivateRoute exact path={routes.muestra.covid.listar} component={CovidListPage}/>
                <PrivateRoute exact path={routes.muestra.covid.actualizar} component={CovidUpdatePage}/>




                {/*Antigenos*/}
                <PrivateRoute exact path={routes.muestra.antigeno.home} component={AntigenoHomePage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.crear} component={AntigenoCreatePage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.buscar} component={AntigenoFindPage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.listar} component={AntigenoListPage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.actualizar} component={AntigenoUpdatePage}/>
                {/*Generar planilla antigenos*/}
                <PrivateRoute exact path={routes.generar.antigenos} component={PlanillaAntigenosPage}/>
                <PrivateRoute exact path={routes.generar.antigenosResultados} component={PlanillaAntigenosResultadosPage}/>
                {/*Listar resultados antigenos solo Laboratoristas, Administrador y Root*/}
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.buscarAntigenos} component={ResultadosAntigenoFindPage}/>
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.resultadosLista} component={ResultadosAntigenoListPage}/>
                {/*Ingresar resultados antigenos solo Laboratorista admin y root*/}
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.subirResultadosAntigenos} component={AntigenoUploadResultadosXLSXPage}/>
                {/*Generar planilla sivigila*/}
                <PrivateRoute exact path={routes.muestra.antigeno.antigenoSivigila} component={PlanillaSivigilaAntigenosPage}/>
                {/*Ingresar resultados antigenos solo Root*/}
                <PrivateRoute hasRole={hasRole(roles.root) ? roles.root: null} exact path={routes.muestra.antigeno.antigenoWord} component={PlanillaWordAntigenosPage}/>

                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.generar.antigenosPlanillaCorreos} component={PlanillaAntigenosCorreosPage}/>

                <PrivateRoute exact path={routes.muestra.buscar} component={SampleFindUpdatePage}/>
                <PrivateRoute exact path={routes.muestra.listar} component={SampleListPage}/>
                <PrivateRoute exact path={routes.muestra.crear} component={SamplePage}/>
                <PrivateRoute exact path={routes.muestra.actualizar} component={SampleUpdatePage}/>

                {/*<PrivateRoute exact path={routes.project()} component={SumaryPage}/>*/}
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.users} component={AdminPage}/>
                <PrivateRoute hasRole={roles.root} exact path={routes.root.users} component={RootPage}/>

                <Route path="*" component={NotFoundPage}/>
            </Switch>

        /*</Router>*/
    );
};

export default AppRouter;