const EAPBS = [
    {"codigoEps": "11001", "nombre": "DIRECCION DISTRITAL DE SALUD DE BOGOTA"},
    {"codigoEps": "13-14", "nombre": "GENERALI COLOMBIA-SEGUROS GENERALES SA"}, {
        "codigoEps": "13-15",
        "nombre": "ROYAL & SUN ALLIANCE SEGUROS (COLOMBIA) SA"
    }, {"codigoEps": "13-17", "nombre": "COMPAÑIA MUNDIAL DE SEGUROS SA"}, {
        "codigoEps": "13-18",
        "nombre": "COMPAÑIA SURAMERICANA DE SEGUROS SA"
    }, {"codigoEps": "13-20", "nombre": "ASEGURADORA EL LIBERTADOR SA"}, {
        "codigoEps": "13-21",
        "nombre": "CHUBB DE COLOMBIA COMPAÑÍA DE SEGUROS SA"
    }, {"codigoEps": "13-22", "nombre": "LA INTERAMERICANA COMPAÑIA DE SEGUROS GENERALES SA"}, {
        "codigoEps": "13-24",
        "nombre": "LA PREVISORA SA COMPAÑIA DE SEGUROS"
    }, {"codigoEps": "13-25", "nombre": "SEGUROS ALFA SA"}, {
        "codigoEps": "13-26",
        "nombre": "MAPFRE SEGUROS GENERALES DE COLOMBIA SA"
    }, {"codigoEps": "13-27", "nombre": "SEGUROS COMERCIALES BOLIVAR SA"}, {
        "codigoEps": "13-29",
        "nombre": "SEGUROS DEL ESTADO SA"
    }, {"codigoEps": "13-30", "nombre": "SEGUREXPO DE COLOMBIA SA"}, {
        "codigoEps": "13-33",
        "nombre": "LIBERTY SEGUROS SA"
    }, {"codigoEps": "13-41", "nombre": "BBVA SEGUROS"}, {
        "codigoEps": "13-42",
        "nombre": "CREDISEGURO SA"
    }, {"codigoEps": "13000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE BOLIVAR"}, {
        "codigoEps": "1309",
        "nombre": "QBE SEGUROS SA"
    }, {"codigoEps": "14-1", "nombre": "ASEGURADORA DE VIDA COLSEGUROS"}, {
        "codigoEps": "14-14",
        "nombre": "CYBERSEGUROS"
    }, {"codigoEps": "14-15", "nombre": "MUNDIAL DE SEGUROS DE VIDA SA"}, {
        "codigoEps": "14-16",
        "nombre": "PANAMERICANA DE COLOMBIA COMPAÑIA DE SEGUROS DE VIDA SA"
    }, {"codigoEps": "14-17", "nombre": "SEGUROS DE VIDA ALFA SA"}, {
        "codigoEps": "14-18",
        "nombre": "LIBERTY SEGUROS DE VIDA SA"
    }, {"codigoEps": "14-19", "nombre": "SEGUROS DE VIDA DEL ESTADO SA"}, {
        "codigoEps": "14-20",
        "nombre": "ROYAL & SUN ALLIANCE SEGUROS DE VIDA (COLOMBIA) SA"
    }, {"codigoEps": "14-23", "nombre": "POSITIVA COMPAÑIA DE SEGUROS-LA PREVISORA VIDA"}, {
        "codigoEps": "14-25",
        "nombre": "RIESGOS PROFESIONALES COLMENA SA COMPAÑIA DE SEGUROS DE VIDA"
    }, {"codigoEps": "14-26", "nombre": "BBVA SEGUROS DE VIDA COLOMBIA S A"}, {
        "codigoEps": "14-27",
        "nombre": "COMPAÑIA CENTRAL DE SEGUROS DE VIDA SA"
    }, {"codigoEps": "14-28", "nombre": "COMPAÑIA SURAMERICANA"}, {
        "codigoEps": "14-29",
        "nombre": "LA EQUIDAD SEGUROS DE VIDA ORGANISMO COOPERATIVO -LA EQUIDAD VIDA-"
    }, {"codigoEps": "14-30", "nombre": "MAPFRE COLOMBIA VIDA SEGUROS SA"}, {
        "codigoEps": "14-4",
        "nombre": "SEGUROS DE VIDA COLPATRIA S A"
    }, {"codigoEps": "14-5", "nombre": "COMPAÑIA AGRICOLA DE SEGUROS DE VIDA S A"}, {
        "codigoEps": "14-7",
        "nombre": "CIA DE SEGUROS BOLIVAR S A"
    }, {"codigoEps": "14-8", "nombre": "COMPAÑIA DE SEGUROS DE VIDA AURORA"}, {
        "codigoEps": "15000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE BOYACA"
    }, {"codigoEps": "17000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CALDAS"}, {
        "codigoEps": "18000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CAQUETA"
    }, {"codigoEps": "19000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CAUCA"}, {
        "codigoEps": "20000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CESAR"
    }, {"codigoEps": "23000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CORDOBA"}, {
        "codigoEps": "25-10",
        "nombre": "INSTITUTO DE SEGUROS SOCIALES I S S RIESGOS PROFESIONALES"
    }, {"codigoEps": "25000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CUNDINAMARCA"}, {
        "codigoEps": "27000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CHOCO"
    }, {"codigoEps": "36904", "nombre": "ASEGURADORA COLSEGUROS S A"}, {
        "codigoEps": "36906",
        "nombre": "LA EQUIDAD SEGUROS"
    }, {"codigoEps": "36937", "nombre": "ASEGURADORA SOLIDARIA"}, {
        "codigoEps": "36995",
        "nombre": "SEGUROS DE VIDA COLPATRIA SA"
    }, {"codigoEps": "37024", "nombre": "ACE SEGUROS"}, {
        "codigoEps": "37025",
        "nombre": "COMPAÑIA AGRICOLA DE SEGUROS DE VIDA SA"
    }, {"codigoEps": "37055", "nombre": "SEGUROS COLPATRIA SA"}, {
        "codigoEps": "37086",
        "nombre": "COMPAÑIA DE SEGUROS BOLIVAR SA"
    }, {"codigoEps": "37116", "nombre": "COMPAÑIA ASEGURADORA DE FIANZAS"}, {
        "codigoEps": "37117",
        "nombre": "COMPAÑIA DE SEGUROS DE VIDA AURORA SA"
    }, {"codigoEps": "37147", "nombre": "COMPAÑIA CENTRAL DE SEGUROS SA CENTRAL DE SEGUROS"}, {
        "codigoEps": "37148",
        "nombre": "SKANDIA COMPAÑIA DE SEGUROS DE VIDA SA"
    }, {"codigoEps": "37177", "nombre": "ABN AMRO SEGUROS (COLOMBIA) SA"}, {
        "codigoEps": "37178",
        "nombre": "GENERALI COLOMBIA VIDA COMPAÑIA DE SEGUROS SA"
    }, {"codigoEps": "37209", "nombre": "COMPAÑIA SURAMERICANA DE SEGUROS DE VIDA SA"}, {
        "codigoEps": "37238",
        "nombre": "COLMENA SA"
    }, {"codigoEps": "41000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE HUILA"}, {
        "codigoEps": "44000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE GUAJIRA"
    }, {"codigoEps": "47000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE MAGDALENA"}, {
        "codigoEps": "5000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE ANTIOQUIA"
    }, {"codigoEps": "50000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE META"}, {
        "codigoEps": "52000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE NARIÑO"
    }, {
        "codigoEps": "54000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE NORTE DE SANTANDER"
    }, {"codigoEps": "63000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE QUINDIO"}, {
        "codigoEps": "66000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE RISARALDA"
    }, {"codigoEps": "68000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE SANTANDER"}, {
        "codigoEps": "70000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE SUCRE"
    }, {"codigoEps": "73000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE TOLIMA"}, {
        "codigoEps": "76000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE VALLE"
    }, {"codigoEps": "8000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE ATLANTICO"}, {
        "codigoEps": "81000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE ARAUCA"
    }, {"codigoEps": "85000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE CASANARE"}, {
        "codigoEps": "86000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE PUTUMAYO"
    }, {"codigoEps": "88000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE SAN ANDRES"}, {
        "codigoEps": "91000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE AMAZONAS"
    }, {"codigoEps": "94000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE GUAINIA"}, {
        "codigoEps": "95000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE GUAVIARE"
    }, {"codigoEps": "97000", "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE VAUPES"}, {
        "codigoEps": "99000",
        "nombre": "DIRECCION DEPARTAMENTAL DE SALUD DE VICHADA"
    }, {"codigoEps": "999999", "nombre": "SIN ASEGURADORA"}, {
        "codigoEps": "ARS003",
        "nombre": "DESCONOCIDO-07"
    }, {"codigoEps": "CCF001", "nombre": "CAMACOL"}, {
        "codigoEps": "CCF002",
        "nombre": "SAVIA SALUD EPS"
    }, {"codigoEps": "CCF003", "nombre": "CCF COMFENALCO ANTIOQUIA"}, {
        "codigoEps": "CCF004",
        "nombre": "CCF ARAUCA COMFIAR"
    }, {"codigoEps": "CCF005", "nombre": "CCF BARRANQUILLA"}, {
        "codigoEps": "CCF006",
        "nombre": "CCF COMFAMILIAR ATLÁNTICO"
    }, {"codigoEps": "CCF007", "nombre": "CCF COMFAMILIAR CARTAGENA"}, {
        "codigoEps": "CCF008",
        "nombre": "CCF FENALCO ANDI COMFENALCO CA"
    }, {"codigoEps": "CCF009", "nombre": "CCF DE BOYACA COMFABOY"}, {
        "codigoEps": "CCF010",
        "nombre": "CCF DORADA COMFAMILIAR"
    }, {"codigoEps": "CCF011", "nombre": "CCF CALDAS COMFAMILIARES"}, {
        "codigoEps": "CCF012",
        "nombre": "CCF CAQUETÁ COMFACA"
    }, {"codigoEps": "CCF013", "nombre": "CCF CESAR COMFACESAR"}, {
        "codigoEps": "CCF014",
        "nombre": "CCF CAUCA COMFACAUCA"
    }, {"codigoEps": "CCF015", "nombre": "COMFACOR CCF015"}, {
        "codigoEps": "CCF016",
        "nombre": "CCF ASEGURADORES"
    }, {"codigoEps": "CCF017", "nombre": "ASFAMILIAS"}, {
        "codigoEps": "CCF018",
        "nombre": "CAFAM"
    }, {"codigoEps": "CCF019", "nombre": "CCF COLSUBSIDIO"}, {
        "codigoEps": "CCF020",
        "nombre": "CCF CAMPESINA COMCAJA"
    }, {"codigoEps": "CCF021", "nombre": "CCF CUNDINARCA COMFACUNDI"}, {
        "codigoEps": "CCF022",
        "nombre": "CCF COMPENSAR"
    }, {"codigoEps": "CCF023", "nombre": "CCF COMFAMILIAR DE LA GUAJIRA"}, {
        "codigoEps": "CCF024",
        "nombre": "CCF COMFAMILIAR HUILA"
    }, {"codigoEps": "CCF025", "nombre": "CCF MAGDALENA"}, {
        "codigoEps": "CCF026",
        "nombre": "CCF REGIONAL META COFREM"
    }, {"codigoEps": "CCF027", "nombre": "CCF DE NARIÑO"}, {
        "codigoEps": "CCF028",
        "nombre": "CCF COMFENALCO QUINDIO"
    }, {"codigoEps": "CCF029", "nombre": "CCF COMFAMILIAR RISARALDA"}, {
        "codigoEps": "CCF030",
        "nombre": "CCF CAMARA COMERCIO SAN ANDRES"
    }, {"codigoEps": "CCF031", "nombre": "CCF DE SANTANDER CAJASAN"}, {
        "codigoEps": "CCF032",
        "nombre": "CCF COMFENALCO SANTANDER"
    }, {"codigoEps": "CCF033", "nombre": "CCF COMFASUCRE"}, {
        "codigoEps": "CCF034",
        "nombre": "CCF SUR TOLIMA CAFASUR"
    }, {"codigoEps": "CCF035", "nombre": "CCF CAFABA"}, {
        "codigoEps": "CCF036",
        "nombre": "CCF TOLIMA COMFATOLIMA"
    }, {"codigoEps": "CCF037", "nombre": "CCF TOLIMA COMFENALCO"}, {
        "codigoEps": "CCF038",
        "nombre": "CCF COMFAMILIAR BUENAVENTURA"
    }, {"codigoEps": "CCF039", "nombre": "CCF BUGA"}, {
        "codigoEps": "CCF040",
        "nombre": "COMFACARTAGO"
    }, {"codigoEps": "CCF045", "nombre": "CCF DE NORTE DE SANTANDER COMFANORTE"}, {
        "codigoEps": "CCF049",
        "nombre": "CCF COMFAORIENTE"
    }, {
        "codigoEps": "CCF050",
        "nombre": "CAJA DE COMPENSACIÓN FAMILIAR C.C.F. DEL ORIENTE COLOMBIANO -COMFAORIENTE"
    }, {"codigoEps": "CCF053", "nombre": "CCF COMFACUNDI"}, {
        "codigoEps": "CCF054",
        "nombre": "CCF COMFENALCO CUNDINAMARCA"
    }, {"codigoEps": "CCF055", "nombre": "CAJACOPI CCF055"}, {
        "codigoEps": "CCF101",
        "nombre": "COLSUBSIDIO CCF"
    }, {"codigoEps": "CCF102", "nombre": "COMFACHOCO CCF"}, {
        "codigoEps": "CCFC07",
        "nombre": "COMFAMILIAR CARTAGENA CCFC"
    }, {"codigoEps": "CCFC09", "nombre": "COMFABOY CCFC"}, {
        "codigoEps": "CCFC10",
        "nombre": "COLSUBSIDIO CCFC"
    }, {"codigoEps": "CCFC15", "nombre": "COMFACOR CCFC"}, {
        "codigoEps": "CCFC18",
        "nombre": "CAJA DE COMPENSACIÓN FAMILIAR CAFAM EPS"
    }, {"codigoEps": "CCFC20", "nombre": "COMFACHOCO-CCFC"}, {
        "codigoEps": "CCFC23",
        "nombre": "COMFAMILIAR DE LA GUAJIRA EPS-CCF"
    }, {"codigoEps": "CCFC24", "nombre": "COMFAMILIAR HUILA EPS-CCF"}, {
        "codigoEps": "CCFC27",
        "nombre": "COMFAMILIAR NARIÑO EPS-CCF"
    }, {"codigoEps": "CCFC33", "nombre": "COMFASUCRE CCFC"}, {
        "codigoEps": "CCFC50",
        "nombre": "CAJA DE COMPENSACIÓN FAMILIAR C.C.F. DEL ORIENTE COLOMBIANO -COMFAORIENTE"
    }, {"codigoEps": "CCFC53", "nombre": "COMFACUNDI-CCFC"}, {
        "codigoEps": "CCFC55",
        "nombre": "CAJACOPI ATLANTICO-CCFC"
    }, {"codigoEps": "CCP053", "nombre": "DESCONOCIDO-05"}, {
        "codigoEps": "CODPPL",
        "nombre": "CODPPL"
    }, {
        "codigoEps": "EAS001",
        "nombre": "FONDO DE PREVISION SOCIAL DEL CONGRESO DE LA REPUBLICA"
    }, {"codigoEps": "EAS002", "nombre": "UISALUD"}, {
        "codigoEps": "EAS003",
        "nombre": "CAJA DE PREVISION SOCIAL DE NARIÑO"
    }, {"codigoEps": "EAS004", "nombre": "CPS BUCARAMANGA"}, {
        "codigoEps": "EAS005",
        "nombre": "DIVISION DE SERVICIOS MEDICO ASISTENCIAL DE LA CORPORACION ELECTRICA DE LA COSTA ATLANTICA CORELCA"
    }, {"codigoEps": "EAS006", "nombre": "DIVISION DE SALUD DE LA UNIVERSIDAD DE ANTIOQUIA"}, {
        "codigoEps": "EAS007",
        "nombre": "CPS UNIVERSIDAD DEL CAUCA"
    }, {"codigoEps": "EAS008", "nombre": "CPS IBAGUE"}, {
        "codigoEps": "EAS009",
        "nombre": "CP DEPARTAMENTAL DE ARAUCA"
    }, {
        "codigoEps": "EAS010",
        "nombre": "SERVICIO DE SALUD DE LA CORPORACION AUTONOMA REGIONAL DEL VALLE DEL CAUCA CVC DE CALI"
    }, {"codigoEps": "EAS011", "nombre": "SERVICIO MEDICO DE LAS EMPRESAS VARIAS DE MEDELLIN"}, {
        "codigoEps": "EAS012",
        "nombre": "SERVICIO MEDICO UNIVERSIDAD DE CALDAS"
    }, {
        "codigoEps": "EAS013",
        "nombre": "DEPARTAMENTO MEDICO Y ODONTOLOGICO DEL MUNICIPIO DE MEDELLIN"
    }, {
        "codigoEps": "EAS014",
        "nombre": "DIVISION DE SERVICIO MEDICO DEL MUNICIPIO DE SANTIAGO DE CALI"
    }, {"codigoEps": "EAS015", "nombre": "CPS CAPRECAUCA"}, {
        "codigoEps": "EAS016",
        "nombre": "EMPRESAS PUBLICAS DE MEDELLIN DEPARTAMENTO MEDICO"
    }, {"codigoEps": "EAS017", "nombre": "CPS DE BOYACA"}, {
        "codigoEps": "EAS018",
        "nombre": "CPS NORTE DE SANTANDER"
    }, {"codigoEps": "EAS019", "nombre": "CPS SUPERINTENDENCIA BANCARIA"}, {
        "codigoEps": "EAS020",
        "nombre": "CAJA DE PREVISION SOCIAL DE LA UNIVERSIDAD TECNOLOGICA DE CHOCO"
    }, {"codigoEps": "EAS021", "nombre": "UNISALUD EAS"}, {
        "codigoEps": "EAS022",
        "nombre": "INSTITUTO COLOMBIANO DE LA REFORMA AGRARIA PROGRAMA DE SERVICIO MEDICO ASISTENCIAL DEL INCORA"
    }, {"codigoEps": "EAS023", "nombre": "INDUSTRIA LICORERA DE CALDAS SERVICIO MEDICO"}, {
        "codigoEps": "EAS024",
        "nombre": "SERVICIO MEDICO DE LA UNIVERSIDAD DEL VALLE"
    }, {"codigoEps": "EAS025", "nombre": "FONDO DE SALUD DE LA UNIVERSIDAD DE CORDOBA"}, {
        "codigoEps": "EAS026",
        "nombre": "FONDO DE PASIVO SOCIAL DE LA EMPRESA PUERTOS DE COLOMBIA"
    }, {"codigoEps": "EAS027", "nombre": "FERROCARRILES NACIONALES DE COLOMBIA"}, {
        "codigoEps": "EAS028",
        "nombre": "FONDO PRESTACIONAL DE LA UNIVERSIDAD DE NARIÑO"
    }, {
        "codigoEps": "EAS029",
        "nombre": "EMPRESA DE ACUEDUCTO Y ALCANTARILLADO DE BOGOTA EAAB"
    }, {"codigoEps": "EMP002", "nombre": "CAFESALUD MEDICINA PREPAGADA S A"}, {
        "codigoEps": "EMP012",
        "nombre": "HUMANA SA COMPAÑIA DE MEDICINA PREPAGADA"
    }, {
        "codigoEps": "EMP014",
        "nombre": "MEDISALUD COMPAÑIA COLOMBIANA DE MEDICINA PREPAGADA SA"
    }, {"codigoEps": "EMP015", "nombre": "MEDISANITAS SA PREPAGADA"}, {
        "codigoEps": "EMP017",
        "nombre": "COLMENA SA MEDICINA PREPAGADA"
    }, {"codigoEps": "EMP019", "nombre": "COLPATRIA SA MEDICINA PREPAGADA"}, {
        "codigoEps": "EMP021",
        "nombre": "COMPAÑIA SURAMERICANA PREPAGADA"
    }, {"codigoEps": "EMP022", "nombre": "VIVIR SA MEDICINA PREPAGADA"}, {
        "codigoEps": "EMP023",
        "nombre": "COLSANITAS"
    }, {
        "codigoEps": "EMP024",
        "nombre": "ASESORIA NACIONAL EN SALUD MEDICINA PREPAGADA SA ASENALS SA"
    }, {"codigoEps": "EMP025", "nombre": "CAJA COMPENSACION COMFENALCO"}, {
        "codigoEps": "EMP028",
        "nombre": "COOMEVA MEDICINA PREPAGADA S A"
    }, {"codigoEps": "EPS001", "nombre": "ALIANSALUD"}, {
        "codigoEps": "EPS002",
        "nombre": "SALUD TOTAL SA EPS"
    }, {"codigoEps": "EPS003", "nombre": "CAFESALUD EPS SA"}, {
        "codigoEps": "EPS004",
        "nombre": "BONSALUD"
    }, {"codigoEps": "EPS005", "nombre": "SANITAS EPS SA"}, {
        "codigoEps": "EPS006",
        "nombre": "EPS INSTITUTO DE SEGUROS SOCIA"
    }, {"codigoEps": "EPS007", "nombre": "UNIMEC EPS SA"}, {
        "codigoEps": "EPS008",
        "nombre": "COMPENSAR EPS"
    }, {"codigoEps": "EPS009", "nombre": "COMFENALCO ANTIOQUIA"}, {
        "codigoEps": "EPS010",
        "nombre": "SURA EPS"
    }, {"codigoEps": "EPS011", "nombre": "COLSEGUROS EPS"}, {
        "codigoEps": "EPS012",
        "nombre": "COMFENALCO VALLE EPS"
    }, {"codigoEps": "EPS013", "nombre": "SALUDCOOP EPS"}, {
        "codigoEps": "EPS014",
        "nombre": "HUMANA VIVIR SA EPS"
    }, {"codigoEps": "EPS015", "nombre": "COLPATRIA EPS"}, {
        "codigoEps": "EPS016",
        "nombre": "COOMEVA EPS SA"
    }, {"codigoEps": "EPS017", "nombre": "FAMISANAR EPS LTDA"}, {
        "codigoEps": "EPS018",
        "nombre": "SOS EPS"
    }, {"codigoEps": "EPS019", "nombre": "RISARALDA LTDA EN LIQUIDACION EPS"}, {
        "codigoEps": "EPS020",
        "nombre": "CAPRECOM EPS"
    }, {"codigoEps": "EPS021", "nombre": "CORPORANONIMAS EN LIQUIDACION"}, {
        "codigoEps": "EPS022",
        "nombre": "CONVIDA EPS"
    }, {"codigoEps": "EPS023", "nombre": "CRUZ BLANCA EPS SA"}, {
        "codigoEps": "EPS024",
        "nombre": "CAJANAL EPS"
    }, {"codigoEps": "EPS025", "nombre": "CAPRESOCA EPS"}, {
        "codigoEps": "EPS026",
        "nombre": "SOLSALUD EPS SA"
    }, {"codigoEps": "EPS027", "nombre": "BARRANQUILLA SANA EPS EN LIQUIDACION"}, {
        "codigoEps": "EPS028",
        "nombre": "CALISALUD EPS"
    }, {"codigoEps": "EPS029", "nombre": "EPS DE CALDAS SA"}, {
        "codigoEps": "EPS030",
        "nombre": "CONDOR EPS SA"
    }, {"codigoEps": "EPS031", "nombre": "SELVASALUD SA EPS"}, {
        "codigoEps": "EPS032",
        "nombre": "METROPOLITANA DE SALUD EPS EN LIQUIDACION"
    }, {"codigoEps": "EPS033", "nombre": "SALUDVIDA EPS SA"}, {
        "codigoEps": "EPS034",
        "nombre": "SALUDCOLOMBIA ENTIDAD PROMOTORA DE SALUD SA"
    }, {"codigoEps": "EPS035", "nombre": "RED SALUD ATENCIÓN HUMANA EPS SA (EPS PRIVADA)"}, {
        "codigoEps": "EPS037",
        "nombre": "NUEVA EPS"
    }, {"codigoEps": "EPS038", "nombre": "MULTIMEDICAS SALUD CON CALIDAD EPS SA"}, {
        "codigoEps": "EPS039",
        "nombre": "GOLDEN GROUP"
    }, {"codigoEps": "EPS040", "nombre": "SAVIA SALUD"}, {
        "codigoEps": "EPS041",
        "nombre": "NUEVA EPS CONTRIBUTIVO"
    }, {
        "codigoEps": "EPS042",
        "nombre": "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA -COOSALUD"
    }, {"codigoEps": "EPS044", "nombre": "MEDIMAS EPS CONTRIBUTIVO"}, {
        "codigoEps": "EPS045",
        "nombre": "MEDIMAS EPS MOVILIDAD CONTRIBUTIVO"
    }, {"codigoEps": "EPS046", "nombre": "Fundación Salud MIA EPS"}, {
        "codigoEps": "EPS048",
        "nombre": "ASOCIACIÓN MUTUAL SER EMPRESA SOLIDARIA DE SALUD E.S.S. -CM"
    }, {"codigoEps": "EPS101", "nombre": "DUSAKAWI EPS101"}, {
        "codigoEps": "EPS102",
        "nombre": "ASOCIACION ZENU"
    }, {"codigoEps": "EPS103", "nombre": "AIC EPS"}, {
        "codigoEps": "EPS104",
        "nombre": "ANAS WAYUU EPS104"
    }, {"codigoEps": "EPS105", "nombre": "MALLAMAS EPS105"}, {
        "codigoEps": "EPS106",
        "nombre": "PIJAOSALUD EPS106"
    }, {"codigoEps": "EPS129", "nombre": "EPS CALDAS"}, {
        "codigoEps": "EPS130",
        "nombre": "EPS COLSANITAS"
    }, {"codigoEps": "EPS133", "nombre": "DESCONOCIDO-06"}, {
        "codigoEps": "EPSC03",
        "nombre": "CAFESALUD MOVILIDAD CONTRIBUTIVO"
    }, {
        "codigoEps": "EPSC20",
        "nombre": "CAJA DE PREVISIÓN SOCIAL DE COMUNICACIONES EPS CAPRECOM"
    }, {"codigoEps": "EPSC22", "nombre": "CONVIDA EPSC"}, {
        "codigoEps": "EPSC25",
        "nombre": "CAPRESOCA EPS"
    }, {"codigoEps": "EPSC33", "nombre": "SALUD VIDA EPS"}, {
        "codigoEps": "EPSC34",
        "nombre": "CAPITAL SALUD EPSC34"
    }, {"codigoEps": "EPSI01", "nombre": "DUSAKAWI EPSI"}, {
        "codigoEps": "EPSI02",
        "nombre": "MANEXKA EPSI"
    }, {"codigoEps": "EPSI03", "nombre": "AIC EPSI"}, {
        "codigoEps": "EPSI04",
        "nombre": "ANAS WAYUU EPSI"
    }, {"codigoEps": "EPSI05", "nombre": "MALLAMAS EPSI"}, {
        "codigoEps": "EPSI06",
        "nombre": "PIJAOS SALUD EPSI"
    }, {"codigoEps": "EPSIC1", "nombre": "DUSAKAWI EPSIC"}, {
        "codigoEps": "EPSIC2",
        "nombre": "ASOCIACION ZENU EPSIC"
    }, {"codigoEps": "EPSIC3", "nombre": "AIC EPSIC"}, {
        "codigoEps": "EPSIC4",
        "nombre": "ANAS WAYUU EPSIC"
    }, {"codigoEps": "EPSIC5", "nombre": "MALLAMAS EPSIC"}, {
        "codigoEps": "EPSIC6",
        "nombre": "PIJAOSALUD EPSIC"
    }, {"codigoEps": "EPSM03", "nombre": "CAFESALUD MEDICINA PREPAGADA"}, {
        "codigoEps": "EPSM33",
        "nombre": "SALUD VIDA SA ENTIDAD PROMOTORA DE SALUD"
    }, {"codigoEps": "EPSMO3", "nombre": "CAFESALUD EPS"}, {
        "codigoEps": "EPSS01",
        "nombre": "ALIANSALUD"
    }, {"codigoEps": "EPSS02", "nombre": "SALUD TOTAL SA SUBSIDIADO"}, {
        "codigoEps": "EPSS03",
        "nombre": "CAFESALUD SUBSIDIADO"
    }, {"codigoEps": "EPSS05", "nombre": "EPS SANITAS -CM"}, {
        "codigoEps": "EPSS08",
        "nombre": "COMPENSAR E.P.S. -CM"
    }, {"codigoEps": "EPSS10", "nombre": "SURA EPS"}, {
        "codigoEps": "EPSS12",
        "nombre": "COMFENALCO VALLE EPSS"
    }, {"codigoEps": "EPSS13", "nombre": "SALUDCOOP EPS"}, {
        "codigoEps": "EPSS16",
        "nombre": "COOMEVA EPSS"
    }, {"codigoEps": "EPSS17", "nombre": "FAMISANAR EPSS"}, {
        "codigoEps": "EPSS18",
        "nombre": "SOS EPSS"
    }, {"codigoEps": "EPSS23", "nombre": "CRUZ BLANCA EPSS"}, {
        "codigoEps": "EPSS26",
        "nombre": "DESCONOCIDO-02"
    }, {"codigoEps": "EPSS33", "nombre": "SALUDVIDA"}, {
        "codigoEps": "EPSS34",
        "nombre": "CAPITAL SALUD EPS-S"
    }, {"codigoEps": "EPSS37", "nombre": "NUEVA EPSS"}, {
        "codigoEps": "EPSS39",
        "nombre": "GOLDEN GROUP SA ENTIDAD PROMOTORA DE SALUD"
    }, {"codigoEps": "EPSS40", "nombre": "SAVIA SALUD"}, {
        "codigoEps": "EPSS41",
        "nombre": "NUEVA EPS SA SUBSIDIADO"
    }, {
        "codigoEps": "EPSS42",
        "nombre": "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA -COOSALUD"
    }, {"codigoEps": "EPSS44", "nombre": "MEDIMAS EPS MOVILIDAD SUBSIDIADO"}, {
        "codigoEps": "EPSS45",
        "nombre": "MEDIMAS EPS SUBSIDIADO"
    }, {"codigoEps": "EPSS46", "nombre": "Fundación Salud MIA EPS"}, {
        "codigoEps": "EPSS48",
        "nombre": "ASOCIACIÓN MUTUAL SER EMPRESA SOLIDARIA DE SALUD E.S.S. -CM"
    }, {"codigoEps": "EPSSO1", "nombre": "ALIANSALUD EPSS"}, {
        "codigoEps": "EPSSO2",
        "nombre": "SALUD TOTAL EPSS"
    }, {"codigoEps": "EPSSO5", "nombre": "ENTIDAD PROMOTORA DE SALUD SANITAS"}, {
        "codigoEps": "EPSSO8",
        "nombre": "COMPENSAR EPSSO"
    }, {"codigoEps": "ESA016", "nombre": "ESA DEPARTAMENTO MEDICO EPM"}, {
        "codigoEps": "ESE113",
        "nombre": "ESE ASOCIACION NUEVO AMANECER"
    }, {"codigoEps": "ESS002", "nombre": "EMDISALUD ESS"}, {
        "codigoEps": "ESS004",
        "nombre": "ESS EMPRESA MUTUAL INTEGRAL"
    }, {"codigoEps": "ESS005", "nombre": "ESS ASOCIACION MUTUAL FAMILIAS"}, {
        "codigoEps": "ESS006",
        "nombre": "ESS ASOCIACION MUTUAL COMUNIDA"
    }, {"codigoEps": "ESS008", "nombre": "ESS FRONSALUD"}, {
        "codigoEps": "ESS009",
        "nombre": "ESS ASOCIACION MUTUAL SALUD"
    }, {"codigoEps": "ESS010", "nombre": "ESS COORSALUD"}, {
        "codigoEps": "ESS012",
        "nombre": "ESS ASOCIACION MUTUAL PROGRESO"
    }, {"codigoEps": "ESS014", "nombre": "ESS COOPSOSALUD LTDA"}, {
        "codigoEps": "ESS015",
        "nombre": "ESS CORSABAR"
    }, {"codigoEps": "ESS016", "nombre": "ESS ASOCIACION MUTUAL SUROCCID"}, {
        "codigoEps": "ESS017",
        "nombre": "ESS ASOCIACION MUTUAL DISTRITO"
    }, {"codigoEps": "ESS019", "nombre": "ESS ASOCIACION MUTUAL LA INMACULADA"}, {
        "codigoEps": "ESS020",
        "nombre": "ESS ASOCIACION MUTUAL FUTURO"
    }, {"codigoEps": "ESS021", "nombre": "ESS CORSASOL"}, {
        "codigoEps": "ESS022",
        "nombre": "ESS ASOCIACION MUTUAL MONTES"
    }, {"codigoEps": "ESS023", "nombre": "ESS EMSALUD ESS"}, {
        "codigoEps": "ESS024",
        "nombre": "COOSALUD ESS EPS-S"
    }, {"codigoEps": "ESS025", "nombre": "ESS COOVIDA"}, {
        "codigoEps": "ESS026",
        "nombre": "ESS COOPERATIVA DE TUEBACO COO"
    }, {"codigoEps": "ESS027", "nombre": "ESS COOESAB LTDA"}, {
        "codigoEps": "ESS028",
        "nombre": "ESS BUENACOP LTDA"
    }, {"codigoEps": "ESS029", "nombre": "ESS COPAES LTDA"}, {
        "codigoEps": "ESS030",
        "nombre": "ESS ECOSMAR"
    }, {"codigoEps": "ESS031", "nombre": "ESS COESALUD LTDA"}, {
        "codigoEps": "ESS032",
        "nombre": "ESS ECOSBOYACA"
    }, {"codigoEps": "ESS033", "nombre": "ESS COOPSALUDESA LTDA"}, {
        "codigoEps": "ESS034",
        "nombre": "ESS COPESAM LTDA"
    }, {"codigoEps": "ESS035", "nombre": "ESS COESPERANZA LTDA"}, {
        "codigoEps": "ESS036",
        "nombre": "ESS COOPCENTRO"
    }, {"codigoEps": "ESS037", "nombre": "ESS FAMISALUD"}, {
        "codigoEps": "ESS040",
        "nombre": "ESS MUTUALMANI LTDA"
    }, {"codigoEps": "ESS041", "nombre": "ESS MUTUOROCUE LTDA"}, {
        "codigoEps": "ESS042",
        "nombre": "ESS MUTUALPAZ LTDA"
    }, {"codigoEps": "ESS043", "nombre": "ESS MUTUOPALENQUE LTDA"}, {
        "codigoEps": "ESS044",
        "nombre": "ESS MUTUALTAURAMENA LTDA"
    }, {"codigoEps": "ESS045", "nombre": "ESS MUTUALYOPAL LTDA"}, {
        "codigoEps": "ESS046",
        "nombre": "ESS DE ARGELIA AMPEBIV"
    }, {"codigoEps": "ESS047", "nombre": "ESS ASOCIACION MUTUAL LA NUEVA"}, {
        "codigoEps": "ESS050",
        "nombre": "ESS ASOCIACION MUTUAL CAMPESINA"
    }, {"codigoEps": "ESS051", "nombre": "ESS ASOCIACION MUTUAL LA MANO"}, {
        "codigoEps": "ESS054",
        "nombre": "ESS ASOCIACION MUTUAL PROSALUD"
    }, {"codigoEps": "ESS055", "nombre": "ESS ASOCIACION MUTUAL VIVIR MEJOR"}, {
        "codigoEps": "ESS056",
        "nombre": "ESS ASOCIACION MUTUAL PIENDA SA"
    }, {"codigoEps": "ESS057", "nombre": "ESS ASOCIACION MUTUAL ACTIVA S"}, {
        "codigoEps": "ESS058",
        "nombre": "ESS COOSABFAM"
    }, {"codigoEps": "ESS060", "nombre": "ESS ASOCIACION MUTUAL COCONUCO"}, {
        "codigoEps": "ESS061",
        "nombre": "ASOCIACION MUTUAL LA SUIZA DE AMERICA EPS INDIGENA"
    }, {"codigoEps": "ESS062", "nombre": "ASMET SALUD ESS"}, {
        "codigoEps": "ESS064",
        "nombre": "ESS ASOCIACION MUTUAL NUEVO HOGAR"
    }, {"codigoEps": "ESS065", "nombre": "ESS COOPERATIVA LA NUEVA ESPERANZA"}, {
        "codigoEps": "ESS066",
        "nombre": "ESS ASOCIACION MUTUAL JAGUA"
    }, {"codigoEps": "ESS069", "nombre": "ESS ASOCIACION ALCIDES FERNANDO"}, {
        "codigoEps": "ESS070",
        "nombre": "ESS ASOCIACION PLAYAS DEL PACIFICO"
    }, {"codigoEps": "ESS071", "nombre": "ESS ASOCIACION MUTUAL EL REVIVIR"}, {
        "codigoEps": "ESS072",
        "nombre": "ESS ASOCIACION AVANCEMOS DE BO"
    }, {"codigoEps": "ESS073", "nombre": "ESS ASOCIACION DE SALUD CARMEL"}, {
        "codigoEps": "ESS074",
        "nombre": "ESS ASOCIACION GOLFO DE TRIBUG"
    }, {"codigoEps": "ESS076", "nombre": "AMBUQ ESS"}, {
        "codigoEps": "ESS077",
        "nombre": "ESS ASOCIACION MILAGRO DE DIOS"
    }, {"codigoEps": "ESS080", "nombre": "ESS AMBUSANBER"}, {
        "codigoEps": "ESS083",
        "nombre": "ESS AMUCIS"
    }, {"codigoEps": "ESS084", "nombre": "ESS AMSOTI"}, {
        "codigoEps": "ESS085",
        "nombre": "ESS ECOSORIENTE"
    }, {"codigoEps": "ESS086", "nombre": "ESS FONDO PROSEGURIDAD SOCIAL"}, {
        "codigoEps": "ESS087",
        "nombre": "ESS SALUD NORTE"
    }, {"codigoEps": "ESS088", "nombre": "ESS ECOOPSALS"}, {
        "codigoEps": "ESS090",
        "nombre": "ESS COOPSOLSA"
    }, {"codigoEps": "ESS091", "nombre": "ECOOPSOS ESS"}, {
        "codigoEps": "ESS092",
        "nombre": "ESS COOPESAGA LTDA"
    }, {"codigoEps": "ESS093", "nombre": "ESS ECISS"}, {
        "codigoEps": "ESS094",
        "nombre": "ESS COOPSAM"
    }, {"codigoEps": "ESS095", "nombre": "ESS COOPDESU LTDA"}, {
        "codigoEps": "ESS096",
        "nombre": "ESS ECOOPSVERGARA"
    }, {"codigoEps": "ESS097", "nombre": "ESS ASOCIACION MUTUAL DE CHIA"}, {
        "codigoEps": "ESS098",
        "nombre": "ESS ASOCIACION MUTUAL EL PORVENIR"
    }, {"codigoEps": "ESS099", "nombre": "ESS ASOCIACION DE MAICAO"}, {
        "codigoEps": "ESS100",
        "nombre": "ESS ASOCIACION DE RIOHACHA ASM"
    }, {"codigoEps": "ESS101", "nombre": "ESS ASOCIACION GUAVIARE 2000"}, {
        "codigoEps": "ESS103",
        "nombre": "ESS ASOCIACION MUTUAL DE SOLIDARIDAD"
    }, {"codigoEps": "ESS104", "nombre": "ESS ASOCIACION MUTUAL MACIZO"}, {
        "codigoEps": "ESS105",
        "nombre": "ESS COOPERATIVA VILLASALUD LTD"
    }, {"codigoEps": "ESS107", "nombre": "ASOCIACION MUTUAL SOLIDARIA"}, {
        "codigoEps": "ESS109",
        "nombre": "ESS CORSAPIVI"
    }, {"codigoEps": "ESS110", "nombre": "ESS CORPORACION SALUD PARA PUE"}, {
        "codigoEps": "ESS112",
        "nombre": "ESS ASOCIACION DESPERTAR 1"
    }, {"codigoEps": "ESS113", "nombre": "ESS ASOCIACION NUEVO AMANECER"}, {
        "codigoEps": "ESS114",
        "nombre": "ESS ASOCIACION MUTUAL CUASPUD"
    }, {"codigoEps": "ESS115", "nombre": "MALLAMAS ESS"}, {
        "codigoEps": "ESS118",
        "nombre": "EMSSANAR ESS"
    }, {"codigoEps": "ESS119", "nombre": "ESS ALCATRAZ LTDA"}, {
        "codigoEps": "ESS120",
        "nombre": "ESS COOESSALUD"
    }, {"codigoEps": "ESS121", "nombre": "ESS ASOMUSAVIH"}, {
        "codigoEps": "ESS122",
        "nombre": "ES COEESSPATIOS"
    }, {"codigoEps": "ESS123", "nombre": "ESS COPSOSAFA"}, {
        "codigoEps": "ESS124",
        "nombre": "ESS AMESS"
    }, {"codigoEps": "ESS126", "nombre": "ESS BELEN"}, {
        "codigoEps": "ESS127",
        "nombre": "ESS ASODISPRO"
    }, {"codigoEps": "ESS128", "nombre": "ESS COOMULSEB LTDA"}, {
        "codigoEps": "ESS129",
        "nombre": "ESS COPESALUD LTDA"
    }, {"codigoEps": "ESS130", "nombre": "ESS COISBU LTDA"}, {
        "codigoEps": "ESS131",
        "nombre": "ESS COOFLORSALUD LTDA"
    }, {"codigoEps": "ESS132", "nombre": "ESS COVISALUD LTDA"}, {
        "codigoEps": "ESS133",
        "nombre": "COMPARTA"
    }, {"codigoEps": "ESS134", "nombre": "ESS VILLASALUD LTDA"}, {
        "codigoEps": "ESS136",
        "nombre": "ESS COESAN LTDA ESS"
    }, {"codigoEps": "ESS137", "nombre": "ESS SALUDDAR"}, {
        "codigoEps": "ESS138",
        "nombre": "ESS EMPRESSA COOPER SERVICIOS"
    }, {"codigoEps": "ESS139", "nombre": "ESS EMPRESAS COOPERATIVA DEL SUR"}, {
        "codigoEps": "ESS140",
        "nombre": "ESS COOPERATIVA DE BUENAVENTUR"
    }, {"codigoEps": "ESS143", "nombre": "ESS COOPERATIVA DE PALMIRA ESS"}, {
        "codigoEps": "ESS144",
        "nombre": "ESS COOPERATIVA DE ROLDANILLO"
    }, {"codigoEps": "ESS145", "nombre": "ESS COOPESAC"}, {
        "codigoEps": "ESS146",
        "nombre": "ESS COOPSAVER"
    }, {"codigoEps": "ESS147", "nombre": "ESS COOPERATIVA ZARZAL"}, {
        "codigoEps": "ESS148",
        "nombre": "ESS ASSAVI"
    }, {"codigoEps": "ESS149", "nombre": "ESS ASOCIACION MUTUAL PERMANENTE"}, {
        "codigoEps": "ESS151",
        "nombre": "ESS COOPERATIVA INTEGRAL DOSQUEBRADAS"
    }, {"codigoEps": "ESS152", "nombre": "ESS ESSAC"}, {
        "codigoEps": "ESS153",
        "nombre": "ESS BALBOA"
    }, {"codigoEps": "ESS154", "nombre": "ESS COOPREGIOSALUD LTDA"}, {
        "codigoEps": "ESS155",
        "nombre": "ESS ASOCIACION MUTUAL DE PLANA"
    }, {"codigoEps": "ESS156", "nombre": "ESS COOPERATIVA DE SALUD COMUNITARIA"}, {
        "codigoEps": "ESS157",
        "nombre": "ESS MARIA OFELIA VILLAMIZAR BU"
    }, {"codigoEps": "ESS158", "nombre": "ESS SANTA CLARA DE CUCUTA"}, {
        "codigoEps": "ESS159",
        "nombre": "ESS PUERTO SANTANDER"
    }, {"codigoEps": "ESS161", "nombre": "ESS SALAZAR LAS PALMAS"}, {
        "codigoEps": "ESS162",
        "nombre": "ESS COOPERTIVA SOLIDARIA DEL M"
    }, {"codigoEps": "ESS163", "nombre": "ESS DEL TIBU"}, {
        "codigoEps": "ESS164",
        "nombre": "PIJAOSALUD ESS"
    }, {"codigoEps": "ESS165", "nombre": "ESS SALUD SUBA"}, {
        "codigoEps": "ESS166",
        "nombre": "ESS COPORACION DEL CARIBE SOLC"
    }, {"codigoEps": "ESS168", "nombre": "ESS COOPERATIVA DE SALUD TIBU"}, {
        "codigoEps": "ESS170",
        "nombre": "ESS ASOCIACION MUTUAL GUAITAR"
    }, {"codigoEps": "ESS174", "nombre": "ESS SALUD COLOMBIA"}, {
        "codigoEps": "ESS175",
        "nombre": "ESS ASOCIACION MUTUAL ADMON RE"
    }, {"codigoEps": "ESS176", "nombre": "ESS ASOCIACION MUTUAL OCAÑA"}, {
        "codigoEps": "ESS177",
        "nombre": "DUSAKAWI ESS"
    }, {"codigoEps": "ESS178", "nombre": "ESS ASOCIACION MUTUAL DESPERTAR"}, {
        "codigoEps": "ESS179",
        "nombre": "ESS ASOCIACION MUTUAL LA SIERRA"
    }, {"codigoEps": "ESS182", "nombre": "AIC ESS"}, {
        "codigoEps": "ESS183",
        "nombre": "ESS EMPRESA COOPERATIVA DE SALUD"
    }, {"codigoEps": "ESS186", "nombre": "ESS COOPERATIVA LA MAGDALENA"}, {
        "codigoEps": "ESS187",
        "nombre": "ESS ASOCIACION MUTUAL LOS ANDES"
    }, {"codigoEps": "ESS188", "nombre": "DESCONOCIDO-04"}, {
        "codigoEps": "ESS190",
        "nombre": "EMMANUEL EPS INDIGENA"
    }, {"codigoEps": "ESS191", "nombre": "ESS COOPERATIVA GENERACION SALUD"}, {
        "codigoEps": "ESS192",
        "nombre": "ESS EMPRESA COOPERATIVA"
    }, {"codigoEps": "ESS193", "nombre": "ESS COOPERATIVA DE BIENESTAR E"}, {
        "codigoEps": "ESS195",
        "nombre": "ESS COOPERATIVA MULTIACTIVA"
    }, {"codigoEps": "ESS196", "nombre": "ESS COOPERATIVA INTEGRAL DE SALUD"}, {
        "codigoEps": "ESS200",
        "nombre": "ESS COOPERATIVA SALUD Y VIDA"
    }, {"codigoEps": "ESS201", "nombre": "ESS EMPRESA SOLIDARIA DE SALUD"}, {
        "codigoEps": "ESS203",
        "nombre": "TAYRONA EPS INDIGENA"
    }, {"codigoEps": "ESS204", "nombre": "ESS ASOCIACION MUTUAL SOLIDARIDAD"}, {
        "codigoEps": "ESS205",
        "nombre": "ESS COOPERATIVA ESPECIALIZADA"
    }, {"codigoEps": "ESS207", "nombre": "MUTUAL SER ESS"}, {
        "codigoEps": "ESS208",
        "nombre": "ANAS WAYUU EPS INDIGENA"
    }, {
        "codigoEps": "ESSC07",
        "nombre": "ASOCIACIÓN MUTUAL SER EMPRESA SOLIDARIA DE SALUD E.S.S. -CM"
    }, {"codigoEps": "ESSC18", "nombre": "ASMESSALUD DE NARIÑO ESSC"}, {
        "codigoEps": "ESSC24",
        "nombre": "COOSALUD ESSC"
    }, {"codigoEps": "ESSC33", "nombre": "COMPARTA ESSC"}, {
        "codigoEps": "ESSC62",
        "nombre": "ASMET SALUD ESSC"
    }, {"codigoEps": "ESSC76", "nombre": "AMBUQ CONTRIBUTIVO"}, {
        "codigoEps": "ESSC91",
        "nombre": "ECOOPSOS ESSC"
    }, {"codigoEps": "ESSCO2", "nombre": "EMDISALUD ESSC"}, {
        "codigoEps": "ESSCO7",
        "nombre": "ASMESSALUD DE NARIÑO"
    }, {"codigoEps": "FMS001", "nombre": "FUERZAS MILITARES"}, {
        "codigoEps": "HC1413",
        "nombre": "AIG COLOMBIA SEGUROS DE VIDA S"
    }, {"codigoEps": "MIN001", "nombre": "FONDO DE SOLIDARIDAD Y GARANTIA MINISTERIO DE SALUD"}, {
        "codigoEps": "PEC001",
        "nombre": "SERVICIO NACIONAL DE APRENDIZAJE-SENA"
    }, {"codigoEps": "PEC002", "nombre": "BANCO DE LA REPUBLICA"}, {
        "codigoEps": "PEC003",
        "nombre": "EMPRESA DE TELEFONOS DE BOGOTA-ETB"
    }, {"codigoEps": "PEC004", "nombre": "FERROCARRILES NACIONALES"}, {
        "codigoEps": "PEC005",
        "nombre": "AVIANCA"
    }, {"codigoEps": "PEC006", "nombre": "BAVARIA"}, {
        "codigoEps": "POL001",
        "nombre": "POLICIA NACIONAL"
    }, {"codigoEps": "REFM01", "nombre": "DIRECCION GENERAL DE SANIDAD MILITAR"}, {
        "codigoEps": "REMG01",
        "nombre": "FIDUPREVISORA  SA REMG"
    }, {"codigoEps": "REPN01", "nombre": "DIRECCION SANIDAD POLICIA NACIONAL"}, {
        "codigoEps": "RES001",
        "nombre": "POLICIA NACIONAL"
    }, {"codigoEps": "RES002", "nombre": "ECOPETROL"}, {
        "codigoEps": "RES003",
        "nombre": "FUERZAS MILITARES"
    }, {"codigoEps": "RES004", "nombre": "FIDUPREVISORA SA RES"}, {
        "codigoEps": "RES005",
        "nombre": "RES FONDO PRESTACION SOCIAL CO"
    }, {"codigoEps": "RES006", "nombre": "INPEC"}, {
        "codigoEps": "RES007",
        "nombre": "UNIVERSIDAD DEL VALLE SERVICIO DE SALUD"
    }, {"codigoEps": "RES008", "nombre": "UNISALUD RES"}, {
        "codigoEps": "RES009",
        "nombre": "UNIVERSIDAD DEL CAUCA UNIDAD DE SALUD"
    }, {"codigoEps": "RES010", "nombre": "UNIVERSIDAD DE CARTAGENA"}, {
        "codigoEps": "RES011",
        "nombre": "UNIVERSIDAD DE ANTIOQUIA"
    }, {"codigoEps": "RES012", "nombre": "UNIVERSIDAD DE CORDOBA"}, {
        "codigoEps": "RES013",
        "nombre": "UNIVERSIDAD DE NARIÑO"
    }, {"codigoEps": "RES014", "nombre": "UNISALUD-UPTC"}, {
        "codigoEps": "REUE01",
        "nombre": "UNISALUD REUE"
    }, {"codigoEps": "REUE02", "nombre": "CAPRUIS REUE"}, {
        "codigoEps": "REUE03",
        "nombre": "UNIDAD ADMINISTRATIVA ESPECIAL DE SALUD UNIVERS CORDOBA"
    }, {"codigoEps": "REUE04", "nombre": "CPS UNIVERSIDAD DE CARTAGENA"}, {
        "codigoEps": "REUE05",
        "nombre": "FONDO DE SEGURIDAD SOCIAL EN SALUD UNIVERSIDAD DE NARIÑO"
    }, {"codigoEps": "REUE06", "nombre": "UNIDAD DE SALUD UNIVERSIDAD DEL CAUCA"}, {
        "codigoEps": "REUE07",
        "nombre": "PROGRAMA DE SALUD UNIVERSIDAD DE ANTIOQUIA"
    }, {"codigoEps": "REUE08", "nombre": "SERVICIO DE SALUD UNIVERSIDAD DEL VALLE"}, {
        "codigoEps": "REUE09",
        "nombre": "UNIDAD DE SALUD DE LA UNIVERSIDAD DEL ATLANTICO"
    }, {
        "codigoEps": "REUE10",
        "nombre": "UNIDAD DE SERVICIOS DE SALUD DE LA UNIVERSIDAD PEDAGOGICA Y TECNOLOGICA DE COLOMBIA (UNIALUD UPTC)"
    }, {
        "codigoEps": "SAP008",
        "nombre": "EMERGENCIA MÉDICA INTEGRAL EMI COLOMBIA SA SERVICIO DE AMBULANCIA PREPAGADO"
    }, {"codigoEps": "SAP026", "nombre": "EMERMEDICA SA SERVICIO DE AMBULANCIA PREPAGADO"}, {
        "codigoEps": "SAP027",
        "nombre": "SERVICIOS DE URGENCIAS MEDICO ASISTENCIALES EMERGENCIA SA SERVICIO DE AMBULANCIA PREPAGADO"
    }, {
        "codigoEps": "SAP030",
        "nombre": "EMERGENCIA MÉDICA INTEGRAL EMI ANTIOQUIA SA SERVICIO DE AMBULANCIA PREPAGADO"
    }, {
        "codigoEps": "SAP031",
        "nombre": "ASISTENCIA MÉDICA INMEDIATA-SERVICIO DE AMBULANCIA PREPAGADA S A"
    }, {"codigoEps": "SAP032", "nombre": "SERVICIO DE EMERGENCIAS REGIONAL"}, {
        "codigoEps": "SAP033",
        "nombre": "PROGRAMA COOMEVA EMERGENCIAS MEDICAS SAP"
    }, {"codigoEps": "SAP034", "nombre": "ASISTENCIA MEDICA DE EMERGENCIA DE SANTANDER"}, {
        "codigoEps": "SAP035",
        "nombre": "SAMI SERVICIO DE ASISTENCIA MEDICA SA"
    }, {"codigoEps": "UT-001", "nombre": "CAJA SALUD ARS UNION TEMPORAL"}, {
        "codigoEps": "UT-002",
        "nombre": "UNION TEMPORAL COMFAMILIAR Y COMFENALCO CARTAGENA"
    }, {"codigoEps": "UT-003", "nombre": "UNION TEMPORAL ARS COMFENALCO (QUINDIO  SANTANDER)"}, {
        "codigoEps": "UT-004",
        "nombre": "COMFAMA UT"
    }];

export default EAPBS;