import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import TRABAJADORSALUD from "../helpers/trabajadorSalud";
import NOMBREPRUEBA from "../helpers/nombrePrueba";
import TIPOSPRUEBA from "../helpers/tiposPrueba";
import eventos from "../helpers/eventos";
import BRIGADA from "../helpers/brigada";


const schema = yup.object().shape({

    consecutivo: yup
        .number("El consecutivo es un string")
        .typeError('Debe ingresar un consecutivo')
        .required("Debe ingresar un consecutivo")
        .required("Is required")
        .positive("Debe ser positivo")
        .integer("Debe ser un entero")
        .min(1, "Mínimo es 1")
        .max(3000, "Máximo es 3000"),

    brigada: yup
        .string('El lugar es un string')
        .required("Debe seleccionar un lugar")
        .oneOf(BRIGADA.map( brigada => (`${brigada}`)), "El lugar no es válido, elija uno"),

    fechaPrueba: yup
        .date()
        .typeError('Ingresa una fecha DD/MM/AAA')
        .required("Debe ingresar fecha de prueba"),

    nombrePrueba: yup
        .string("El nombre de prueba debe ser un texto")
        .required("Debe seleccionar el nombre de la prueba")
        .oneOf(NOMBREPRUEBA.map( tipo => (`${tipo}`)), "El nombre de prueba no es válido, elija uno"),

    tipoPrueba: yup
        .string("El tipo de prueba debe ser un texto")
        .typeError("Debe seleccionar un tipo de prueba")
        .required("Debe seleccionar un tipo de prueba")
        .oneOf(TIPOSPRUEBA.map( tipo => (`${tipo}`)), "El tipo de prueba no es válido, elija uno"),

/*
    edad: yup
        .number("Debe ingresar la edad")
        .positive("No puede ser número negativo")
        .integer("No puede ser un decimal")
        .typeError('Debe ingresar la edad')
        .min('0', 'La edad debe ser igual o mayor a 0')
        .max('130', 'La edad debe ser menor o igual a 130')
        .required("Debe ingresar la edad"),

    genero: yup
        .string("El genero debe ser un texto")
        .required("Debe seleccionar un genero")
        .oneOf(genero.map( tipo => (`${tipo.tipo}`)), "El genero no es válido, elija uno"),
*/

    trabajadorSalud: yup
        .string("Trabajador de la salud es un texto")
        .required("Debe seleccionar Trabajador de la salud")
        .oneOf(TRABAJADORSALUD.map( trabajador => (`${trabajador.tipo}`)), "El Trabajador de la salud no es válido, elija una"),

    posSintomatico: yup
        .string("Pos Sintomático es un texto")
        .required("Debe seleccionar una opción de Pos Sintomático")
        .oneOf(TRABAJADORSALUD.map( trabajador => (`${trabajador.tipo}`)), "Pos Sintomático no es válido, elija uno"),

    evento: yup
        .string("El evento es un texto")
        .required("Debe seleccionar un evento")
        .oneOf(eventos.map( evento => (`${evento}`)), "El evento no es válido, elija uno"),



    /*

    codigoMunicipio: yup
        .string("El cód del municipio es un texto")
        .required("Debe seleccionar un municipio")
        .oneOf(DIVIPOLA.map( div => (`${div.municipio} - ${div.codigoMunicipio}`)), "El cód. de municipio no es válido, elija uno"),
*/


   })

export default yupResolver(schema)