import useAuth from "../../auth/useAuth";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import AntigenoPlanillaResolver from "../../validations/AntigenoPlanillaResolver";
import ReactExport from 'react-data-export';
import {useState} from "react";
import BRIGADA from "../../helpers/brigada";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const PlanillaAntigenosPage = () => {

    const {getPlanillaSivigilaAntigenos} = useAuth();

    const {register, handleSubmit, formState} = useForm({resolver: AntigenoPlanillaResolver});
    const {errors} = formState;

    const [generarExcel, setGenerarExcel] = useState(null);
    const [brigadaLugar, setBrigadaLugar] = useState(null);
    const [multiDataSet, setMultiDataSet] = useState([{}]);
    const [fecha, setFecha] = useState(null);


    const onSubmit = async (formData) => {
        setBrigadaLugar(formData.brigada);
        const fecha = formData.fecha;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fecha = `${dia}/${mes}/${anio}`

        const data = await getPlanillaSivigilaAntigenos(formData.fecha, formData.brigada);
        if (data) {
            setMultiDataSet(data);
            setFecha(formData.fecha.replaceAll('/', '-'));
            setGenerarExcel(true)
            toast.success('Planilla de antígenos generada exitosamente')
        } else {
            setGenerarExcel(false)
            toast.success('La planilla no pudo ser generada')
        }

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Planilla sivigila de antígenos</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shaÇdow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Ingrese fecha para generar planilla</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una fecha"
                                    {...register("fecha")}
                                    type="date"
                                />
                                {errors?.fecha && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fecha.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Lugar</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("brigada")}
                                >
                                    <option value="">Seleccione un lugar</option>
                                    {BRIGADA.map( brigada => <option key={brigada}>{brigada}</option> )}
                                </Form.Control>
                                {errors?.brigada && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.brigada.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Generar</Button>
                            {
                                generarExcel &&

                                <div>
                                    <ExcelFile element={<Button className="mt-3" variant="outline-dark" block>Descargar XLSX</Button>} filename={`PlanillaSivigilaAntigenos${brigadaLugar}-${fecha}`}>
                                        <ExcelSheet dataSet={multiDataSet} name="hoja 1"/>
                                    </ExcelFile>
                                </div>
                            }
                        </Form>
                    </Card>
                </Col>
            </Row>


        </Container>
    );
};

export default PlanillaAntigenosPage;