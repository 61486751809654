const tiposMuestra = [
    'Sangre total'.toUpperCase(),
    'Hisopado nasofaringeo'.toUpperCase(),
    'Tejido'.toUpperCase(),
    'Aspirado nasofaringeo'.toUpperCase(),
    'Lavado nasal'.toUpperCase(),
    'Lavado broncoalveolar'.toUpperCase(),
    'Lavado bronquial'.toUpperCase()
];

export default tiposMuestra;