import {Container, Row, Col, Button} from 'react-bootstrap';
import routes from "../helpers/routes";
import {Link} from "react-router-dom";

const HomePage = () => {
    return (
        <Container>
            <Row className="mt-5">
                <Col xs={{span: 12}} md={{span: 6}} className="mb-5">
                    <h2 className="mb-3">Bienvenidos a la plataforma de gestión de información CEDIMOL</h2>
                    <p>Desarrollada para generar reportes oportunos, resultados y estadísticas de las pruebas moleculares PCR para detección del SARS Cov-2, enfermedades tropicales e infecciosas de la Universidad de Pamplona.</p>
                    <p>En el marco del proyecto: "Fortalecimiento de capacidades CTEI del laboratorio de ciencias biomédicas para atender la emergencia sanitaria causada por el Covid-19 y enfermedades parasitarias, tropicales e infecciosas de alto riesgo para la salud en el Norte de Santander"</p>
                    <p>La generación automática de reportes, falicita la carga de datos requeridos a la plataforma SISMUESTRAS del Instituto Nacional de Salud (INS) y SIVIGILA - Colombia</p>
                    <div>
                        <Button as={Link} variant="outline-danger" to={routes.login}>Ingreso</Button>
                    </div>
                </Col>
                <Col>
                    <img
                        className="img-fluid"
                        src="/img/task-manager.svg"
                        alt="Gestor de tareas"/>
                    <p className="text-center">Centro Experimental de Diagnóstico e Investigación Molecular en Enfermedades Parasitarias, Tropicales e Infecciosas Universidad de Pamplona</p>
                </Col>
            </Row>
        </Container>
    );
};

export default HomePage;