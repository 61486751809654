import React, {useEffect} from 'react';
import {Alert, Button, Form} from "react-bootstrap";
//import genero from "../../helpers/genero";
import paises from "../../helpers/paises";
import divipola from "../../helpers/departamento";
import {useForm} from "react-hook-form";
import personUpdateResolver from "../../validations/personUpdateResolver";
import tiposMuestra from "../../helpers/tiposMuestra";
import eventos from "../../helpers/eventos";
import UPGDS from "../../helpers/upgds";
import EAPBS from "../../helpers/eapbs";
import REGIMENES from "../../helpers/regimenes";
import MOTIVOS from "../../helpers/motivosToma";
import TRABAJADORSALUD from "../../helpers/trabajadorSalud";
import CONTACTOCASOCONFORMADO from "../../helpers/contactoCaso";
import CONDICIONFINAL from "../../helpers/codicionFinal";

const SampleUpdatePage = ({location}) => {
    const {muestra, persona} = location.state;
    console.log('SampleUpdate', muestra)

    const {register, handleSubmit, formState, reset} = useForm({resolver: personUpdateResolver});
    const {errors, dirtyFields} = formState;
    const isDirty = !!Object.keys(dirtyFields).length;


    useEffect(() => {
        const [dia, mes, anio] = muestra.fechaMuestra.split('/');

        let infoLugarMuestra = undefined;
        if(muestra.municipioMuestra !== undefined && muestra.municipioMuestra !== '') {
            infoLugarMuestra = divipola.find(divipola => divipola.codigoMunicipio === muestra.municipioMuestra);
        }

        if(muestra){
           reset({
               consecutivo: muestra.consecutivo,
               fechaMuestra: `${anio}-${mes}-${dia}`,
               tipoMuestra: muestra.tipoMuestra,
               municipioDeMuestra: infoLugarMuestra ? `${infoLugarMuestra.municipio} - ${infoLugarMuestra.departamento}`:'',
               evento: muestra.evento ? muestra.evento : '',
           });
        }
    },[muestra, reset])

    const onSubmit = (formData) => {

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card shadow border-0 mt-3">
                        <h3 className="text-center my-3">Actualizar muestra del paciente</h3>
                    </div>
                </div>

            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4 mt-3">

                        <div className="card border-0 shadow p-3">
                            <p className="text-center font-weight-bold m-0">Información del paciente: </p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Nombre:</span> {persona.primerNombre} {persona.primerApellido}</p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Identificación:</span> {persona.numDocumento}</p>
                            <p className="m-0" style={{fontSize: "12px"}}><span className="font-weight-bold">Fecha de la muestra:</span> {muestra.creado}</p>
                        </div>

                        <div className="card shadow border-0 mt-2 p-3">

                            <Form.Group>
                                <Form.Label>Consecutivo del día</Form.Label>
                                <Form.Control
                                    placeholder="Escriba el consecutivo"
                                    {...register("consecutivo")}
                                    type="number"
                                />
                                {errors?.consecutivo && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.consecutivo.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Fecha toma de muestra</Form.Label>
                                <Form.Control
                                    placeholder="Escriba fecha de toma de muestra"
                                    {...register("fechaMuestra")}
                                    type="date"
                                />
                                {errors?.fechaMuestra && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fechaMuestra.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Tipo de muestra</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoMuestra")}
                                >
                                    <option value="">Seleccione un tipo</option>
                                    {tiposMuestra.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.tipoMuestra && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoMuestra.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Municipio de muestra</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("municipioDeMuestra")}
                                >
                                    <option value="">Seleccione uno</option>
                                    {divipola.map( lugar => <option key={lugar.codigoMunicipio}>{lugar.municipio} - {lugar.departamento}</option> )}
                                </Form.Control>
                                {errors?.municipioDeMuestra && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.municipioDeMuestra.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Evento</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("evento")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {eventos.map( evento => <option key={evento.tipo}>{evento.tipo}</option> )}
                                </Form.Control>
                                {errors?.evento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.evento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                        </div>
                    </div>

                    <div className="col-md-4 mt-2">
                        <div className="card shadow border-0 mt-2 p-3">



                            <Form.Group>
                                <Form.Label>Código IPS que envía (UPGD)</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("codigoIps")}
                                >
                                    <option value="">Seleccione un código</option>
                                    {UPGDS.map( upgd => <option key={upgd.codigoPre}>{upgd.codigoPre} - {upgd.nombre}</option> )}
                                </Form.Control>
                                {errors?.codigoIps && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.codigoIps.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Código EPS (EAPB)</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("codigoEps")}
                                >
                                    <option value="">Seleccione un código</option>
                                    {EAPBS.map( eapb => <option key={eapb.codigoEps}>{eapb.codigoEps} - {eapb.nombre}</option> )}
                                </Form.Control>
                                {errors?.codigoEps && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.codigoEps.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Regimen</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("regimen")}
                                >
                                    <option value="">Seleccione un regimen</option>
                                    {REGIMENES.map( regimen => <option key={regimen.tipo}>{regimen.tipo}</option> )}
                                </Form.Control>
                                {errors?.regimen && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.regimen.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Motivo de la toma</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("motivoToma")}
                                >
                                    <option value="">Seleccione un motivo</option>
                                    {MOTIVOS.map( motivo => <option key={motivo.tipo}>{motivo.tipo}</option> )}
                                </Form.Control>
                                {errors?.motivoToma && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.motivoToma.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Trabajador de la salud</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("trabajadorSalud")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {TRABAJADORSALUD.map( trabajador => <option key={trabajador.tipo}>{trabajador.tipo}</option> )}
                                </Form.Control>
                                {errors?.trabajadorSalud && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.trabajadorSalud.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Contacto caso confirmado</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("contactoCasoConfirmado")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {CONTACTOCASOCONFORMADO.map( contacto => <option key={contacto.tipo}>{contacto.tipo}</option> )}
                                </Form.Control>
                                {errors?.contactoCasoConfirmado && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.contactoCasoConfirmado.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                        </div>
                    </div>

                    <div className="col-md-4 mt-2">
                        <div className="card shadow border-0 mt-2 p-3">

                            <Form.Group>
                                <Form.Label>Condición final</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("condicionFinal")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {CONDICIONFINAL.map( condicion => <option key={condicion.tipo}>{condicion.tipo}</option> )}
                                </Form.Control>
                                {errors?.condicionFinal && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.condicionFinal.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>País de residencia</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("paisResidencia")}
                                >
                                    <option value="">Seleccione uno</option>
                                    {paises.map( pais => <option key={pais.nombre}>{pais.nombre}</option> )}
                                </Form.Control>
                                {errors?.paisResidencia && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.paisResidencia.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>


                            <Form.Group>
                                <Form.Label>Observaciones internas del lab</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="4"
                                    placeholder="Escriba las observaciones internas del laboratorio"
                                    {...register("observacionesInternas")}
                                    type="text"
                                />
                                {errors?.observacionesInternas && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.observacionesInternas.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Observaciones para reporte</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="4"
                                    placeholder="Escriba las observaciones para el reporte en PDF"
                                    {...register("observacionesExternas")}
                                    type="text"
                                />
                                {errors?.observacionesExternas && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.observacionesExternas.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                disabled={!isDirty}
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Actualizar muestra</Button>
                        </div>
                    </div>

                </div>
            </Form>
        </div>
    );
};

export default SampleUpdatePage;