import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import tiposIdentificacion from "../helpers/tiposIdentificacion";
import EAPBS from "../helpers/eapbs";
import REGIMENES from "../helpers/regimenes";


const schema = yup.object().shape({
    tipoDocumento: yup
        .string("El tipo de identificación debe ser un texto")
        .required("Debe seleccionar un tipo de identificación")
        .oneOf(tiposIdentificacion.map( tipo => (`${tipo.tipo} - ${tipo.sigla}`)), "El tipo de identificación no es válido, elija uno"),
    numDocumento: yup
        .string("La identificación debe ser un texto")
        .required("Debe ingresar la identificación"),
    primerNombre: yup
        .string("El primer nombre debe ser un texto")
        .required("Debe ingresar el primer nombre"),
    segundoNombre: yup
        .string("El segundo nombre debe ser un texto"),
    primerApellido: yup
        .string("El primer apellido debe ser un texto")
        .required("Debe ingresar el primer apellido"),
    segundoApellido: yup
        .string("El segundo apellido debe ser un texto"),
    fechaNacimiento: yup
        .string("La fecha de nacimiento debe ser un texto"),
    sexo: yup
        .string('Seleccione un sexo'),
        //.oneOf(["Masculino", "Femenino"], "El sexo no es válido, elija uno"),
    email: yup
        .string("El correo debe ser un texto")
        .email("Debe ingresar un correo válido"),
    eps: yup
        .string("La EPS debe ser un texto")
        .required("Debe seleccionar una EPS")
        .oneOf(EAPBS.map( eapb => (`${eapb.codigoEps} - ${eapb.nombre}`)), "La EPS no es válida, elija uno"),

    regimen: yup
        .string("El regimen es un texto")
        .required("Debe seleccionar regimen")
        .oneOf(REGIMENES.map( reg => (`${reg}`)), "El regimen no es válido, elija uno"),

    paisNacimiento: yup
        .string('El país de nacimiento es un string'),
        //.oneOf(paises.map( pais => (`${pais.nombre}`)), "El país no es válido, elija uno"),

})

export default yupResolver(schema)