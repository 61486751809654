import useAuth from "../../auth/useAuth";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import AntigenoPlanillaResolver from "../../validations/AntigenoPlanillaResolver";
import {useHistory} from "react-router-dom";
import routes from "../../helpers/routes";
import BRIGADA from "../../helpers/brigada";


const ResultadosAntigenoFindPage = () => {

    const {getResultadosAntigeno} = useAuth();
    const history = useHistory();

    const {register, handleSubmit, formState} = useForm({resolver: AntigenoPlanillaResolver});
    const {errors} = formState;

    const onSubmit = async (formData) => {

        const fecha = formData.fecha;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fecha = `${dia}/${mes}/${anio}`
        const fechaRes = formData.fecha;
        const resultados = await getResultadosAntigeno(formData.fecha, formData.brigada);
        if (resultados) {
            //resultados.push(fecha);
            history.push({
                pathname: routes.muestra.antigeno.resultadosLista,
                state: {resultados, fechaRes}
            });

            toast.success('Resultados encontrados')
        } else {
            const {message} = resultados;
            toast.error(message)
        }

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Listar resultados de antígenos</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Ingrese fecha para ingreso de resultados</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una fecha"
                                    {...register("fecha")}
                                    type="date"
                                />
                                {errors?.fecha && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fecha.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Lugar</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("brigada")}
                                >
                                    <option value="">Seleccione un lugar</option>
                                    {BRIGADA.map( brigada => <option key={brigada}>{brigada}</option> )}
                                </Form.Control>
                                {errors?.brigada && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.brigada.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar resultados</Button>


                        </Form>
                    </Card>
                </Col>
            </Row>


        </Container>
    );
};

export default ResultadosAntigenoFindPage;