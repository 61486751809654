import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import useAuth from "../../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";


const PersonFind = () => {
    const {getPatient} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {
        //const {}

        const persona = await getPatient(formData.identificacion)
        if(persona) {
            //detectar si la persona tiene muestras y si son de la fecha actual cargar el actual o crear uno nuevo
            //const {examenes} = persona;
            //console.log(examenes)

            toast.success('Actualice la persona a continuación...')
            //console.log('Existe, asociar muestra a la identificacion de la persona')
            reset();
            // redireccion para asociar muestra
            history.push({
                pathname: '/persona/actualizar',
                state: persona
            })

        } else {
            //console.log('No existe, crear la persona y luego asociar muestra')
            toast.success('La persona que intenta actualizar no existe')
            reset();
        }

    }
    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Actualizar datos del paciente</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar persona</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PersonFind;