import useAuth from "../../auth/useAuth";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import AntigenoPlanillaResolver from "../../validations/AntigenoPlanillaResolver";
import ReactExport from 'react-data-export';
import {useState} from "react";
import BRIGADA from "../../helpers/brigada";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/*const multiDataSet = [
    {
        columns: [
            {title: "Fecha Muestra(dd/mm/aaaa)", width: {wch: 12}},//char width
            {title: "Nombre Paciente", width: {wch: 36}},//char width
            {title: "Tipo de Identificacion", width: {wch: 22}},//char width
            {title: "Identificacion", width: {wch: 16}},//char width
            {title: "Edad", width: {wch: 6}},//char width
            {title: "Genero(F/M)", width: {wch: 12}},//char width
            {title: "Trabajador de la Salud(S/N)", width: {wch: 30}},//char width
            {title: "Pos_Sintomatico(S/N)", width: {wch: 22}},//char width
            {title: "Nombre Prueba", width: {wch: 32}},//char width
            {title: "Tipo Prueba", width: {wch: 20}},//char width
            {title: "Resultado", width: {wch: 16}},//char width
            {title: "EPS", width: {wch: 10}},//char width
            {title: "COD_Municipio", width: {wch: 15}},//char width
            {title: "Observaciones", width: {wch: 100}},//char width
        ],
        data: [
            [
                {value: "H1", style: {font: {sz: "24", bold: true}}},
                {value: "Bold", style: {font: {bold: true}}},
                {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
            ],
            [
                {value: "H2", style: {font: {sz: "18", bold: true}}},
                {value: "underline", style: {font: {underline: true}}},
                {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
            ],
            [
                {value: "H3", style: {font: {sz: "14", bold: true}}},
                {value: "italic", style: {font: {italic: true}}},
                {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
            ],
            [
                {value: "H4", style: {font: {sz: "12", bold: true}}},
                {value: "strike", style: {font: {strike: true}}},
                {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
            ],
            [
                {value: "H5", style: {font: {sz: "10.5", bold: true}}},
                {value: "outline", style: {font: {outline: true}}},
                {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
            ],
            [
                {value: "H6", style: {font: {sz: "7.5", bold: true}}},
                {value: "shadow", style: {font: {shadow: true}}},
                {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
            ]
        ]
    }
];*/

const PlanillaWordAntigenosPage = () => {

    const {getDataPlanillaWordAntigenos} = useAuth();

    const {register, handleSubmit, formState} = useForm({resolver: AntigenoPlanillaResolver});
    const {errors} = formState;

    const [generarExcel, setGenerarExcel] = useState(null);
    const [brigadaLugar, setBrigadaLugar] = useState(null);
    const [multiDataSet, setMultiDataSet] = useState([{}]);
    const [fecha, setFecha] = useState(null);


    const onSubmit = async (formData) => {
        setBrigadaLugar(formData.brigada);
        const fecha = formData.fecha;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fecha = `${dia}/${mes}/${anio}`

        const data = await getDataPlanillaWordAntigenos(formData);
        if (data) {
            setMultiDataSet(data);
            setFecha(formData.fecha.replaceAll('/', '-'));
            setGenerarExcel(true)
            toast.success('Planilla de antígenos generada exitosamente')
        } else {
            setGenerarExcel(false)
            toast.success('La planilla no pudo ser generada')
        }

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Planilla Word Antígenos</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shaÇdow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Ingrese fecha para generar planilla</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una fecha"
                                    {...register("fecha")}
                                    type="date"
                                />
                                {errors?.fecha && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fecha.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Lugar</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("brigada")}
                                >
                                    <option value="">Seleccione un lugar</option>
                                    {BRIGADA.map( brigada => <option key={brigada}>{brigada}</option> )}
                                </Form.Control>
                                {errors?.brigada && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.brigada.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Generar</Button>
                            {
                                generarExcel &&

                                <div>
                                    <ExcelFile element={<Button className="mt-3" variant="outline-dark" block>Descargar XLSX</Button>} filename={`PlanillaWordAntigenos${brigadaLugar}-${fecha}`}>
                                        <ExcelSheet dataSet={multiDataSet} name="hoja 1"/>
                                    </ExcelFile>
                                </div>
                            }
                        </Form>
                    </Card>
                </Col>
            </Row>


        </Container>
    );
};

export default PlanillaWordAntigenosPage;