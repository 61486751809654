const UPGDS = [
    {
        "codigoPre":"0",
        "codigoSub":"0",
        "nombre":"No se encuentra Upgds"
    },
    {
        "codigoPre": "0000000054",
        "codigoSub": "1",
        "nombre": "Laboratorio de salud publica Norte de Santander"
    },
    {
        "codigoPre": "0000054003",
        "codigoSub": "1",
        "nombre": "Secretaria de Salud Municipio Ábrego"
    },{
        "codigoPre":"0000054051",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Arboledas"
    },{
        "codigoPre":"0000054099",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Bochalema"
    },{
        "codigoPre":"0000054109",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Bucarasica"
    },{
        "codigoPre":"0000054128",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Cáchira"
    },{
        "codigoPre":"0000054125",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Cácota"
    },{
        "codigoPre":"0000054172",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Chinácota"
    },{
        "codigoPre":"0000054174",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Chitagá"
    },{
        "codigoPre":"0000054206",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Convención"
    },{
        "codigoPre":"0000054001",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Cúcuta"
    },{
        "codigoPre":"0000054223",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Cucutilla"
    },{
        "codigoPre":"0000054239",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Durania"
    },{
        "codigoPre":"0000054245",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio El Carmen"
    },{
        "codigoPre":"0000054250",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio El Tarra"
    },{
        "codigoPre":"0000054261",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio El Zulia"
    },{
        "codigoPre":"0000054313",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Gramalote"
    },{
        "codigoPre":"0000054344",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Hacarí"
    },{
        "codigoPre":"0000054347",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Herrán"
    },{
        "codigoPre":"0000054385",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio La Esperanza"
    },{
        "codigoPre":"0000054398",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio La Playa"
    },{
        "codigoPre":"0000054377",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Labateca"
    },{
        "codigoPre":"0000054405",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Los Patios"
    },{
        "codigoPre":"0000054418",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Lourdes"
    },{
        "codigoPre":"0000054480",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Mutiscua"
    },{
        "codigoPre":"0000054498",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Ocaña"
    },{
        "codigoPre":"0000054518",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Pamplona"
    },{
        "codigoPre":"0000054520",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Pamplonita"
    },{
        "codigoPre":"0000054553",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Puerto Santander"
    },{
        "codigoPre":"0000054599",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Ragonvalia"
    },{
        "codigoPre":"0000054660",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Salazar"
    },{
        "codigoPre":"0000054670",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio San Calixto"
    },{
        "codigoPre":"0000054673",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio San Cayetano"
    },{
        "codigoPre":"0000054680",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Santiago"
    },{
        "codigoPre":"0000054720",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Sardinata"
    },{
        "codigoPre":"0000054743",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Silos"
    },{
        "codigoPre":"0000054800",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Teorama"
    },{
        "codigoPre":"0000054810",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Tibú"
    },{
        "codigoPre":"0000054820",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Toledo"
    },{
        "codigoPre":"0000054871",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Villa Caro"
    },{
        "codigoPre":"0000054874",
        "codigoSub":"1",
        "nombre": "Secretaria de Salud Municipio Villa del Rosario"
    },

    {
        "codigoPre": "5451800958",
        "codigoSub": "1",
        "nombre":  "UNIVERSIDAD DE PAMPLONA"
    },


    {
        "codigoPre": "5400100002",
        "codigoSub": "1",
        "nombre": "OLGA MARIA PARIS PINEDA"
    },
    {
        "codigoPre": "5400100003",
        "codigoSub": "1",
        "nombre": "AGUSTIN CASTRO ZAPATA"
    },
    {
        "codigoPre": "5400100006",
        "codigoSub": "1",
        "nombre": "BLANCA ESPERANZA JAIMES PEC\u0011ALOZA"
    },
    {
        "codigoPre": "5400100007",
        "codigoSub": "1",
        "nombre": "ASOCIACION DE PATOLOGOS \"ASOPAT\" LTDA."
    },
    {
        "codigoPre": "5400100008",
        "codigoSub": "1",
        "nombre": "LUIS CARLOS BECERRA ANDRADE"
    },
    {
        "codigoPre": "5400100011",
        "codigoSub": "1",
        "nombre": "CLINICA DE CANCEROLOGIA DEL NORTE DE SANTANDER LTDA."
    },
    {
        "codigoPre": 5400100012,
        "codigoSub": "1",
        "nombre": "RADIOTERAPIA DEL NORTE LTDA."
    },
    {
        "codigoPre": 5400100013,
        "codigoSub": "1",
        "nombre": "ORLANDO AFRANIO VILLAMIZAR GALVIS"
    },
    {
        "codigoPre": 5400100015,
        "codigoSub": "1",
        "nombre": "EMIRO ANDRADE CHAPARRO"
    },
    {
        "codigoPre": 5400100017,
        "codigoSub": "1",
        "nombre": "SERGIO CACERES OROZCO"
    },
    {
        "codigoPre": 5400100020,
        "codigoSub": "1",
        "nombre": "ALVARO GRANADOS SANTAFE"
    },
    {
        "codigoPre": 5400100027,
        "codigoSub": "1",
        "nombre": "DORIS AMPARO PABON HERNANDEZ"
    },
    {
        "codigoPre": 5400100028,
        "codigoSub": "1",
        "nombre": "JOAQUIN ENRIQUE VILLAMIZAR ZUC\u0011IGA"
    },
    {
        "codigoPre": 5400100031,
        "codigoSub": "1",
        "nombre": "MARIA EUGENIA CACERES OROZCO"
    },
    {
        "codigoPre": 5400100032,
        "codigoSub": "1",
        "nombre": "JULIETH SMITH SUAREZ TORRADO"
    },
    {
        "codigoPre": 5400100033,
        "codigoSub": "1",
        "nombre": "MONICA COLMENARES GALVIS"
    },
    {
        "codigoPre": 5400100035,
        "codigoSub": "1",
        "nombre": "OMAR BENITO PAEZ JAIMES"
    },
    {
        "codigoPre": 5400100039,
        "codigoSub": "1",
        "nombre": "SOCIEDAD DE CIRUGIA PEDIATRICA DE NORTE DE SANTANDER"
    },
    {
        "codigoPre": 5400100041,
        "codigoSub": "1",
        "nombre": "ROBERTO JOSE HERRERA COLMENARES"
    },
    {
        "codigoPre": 5400100042,
        "codigoSub": "1",
        "nombre": "GERMAN LOPEZ SANCHEZ"
    },
    {
        "codigoPre": 5400100045,
        "codigoSub": "1",
        "nombre": "SERVICIO DE ASISTENCIA MEDICA INMEDIATA S.A."
    },
    {
        "codigoPre": 5400100050,
        "codigoSub": "1",
        "nombre": "ENDOSCOPIA DIGESTIVA S.A.S."
    },
    {
        "codigoPre": 5400100053,
        "codigoSub": "1",
        "nombre": "RICARDO MARCUCCI VEGA"
    },
    {
        "codigoPre": 5400100054,
        "codigoSub": "1",
        "nombre": "HAROLD HUMBERTO GARCIA TOUCHIE"
    },
    {
        "codigoPre": 5400100055,
        "codigoSub": "1",
        "nombre": "CECILIA CONCHA CORTES"
    },
    {
        "codigoPre": 5400100058,
        "codigoSub": "1",
        "nombre": "PEDRO ALONSO FUENTES TORRADO"
    },
    {
        "codigoPre": 5400100059,
        "codigoSub": "1",
        "nombre": "PATRICIA MERCEDES CHAHIN RUEDA"
    },
    {
        "codigoPre": 5400100060,
        "codigoSub": "1",
        "nombre": "EDUARDO SALIM CHAHIN RUEDA"
    },
    {
        "codigoPre": 5400100061,
        "codigoSub": "1",
        "nombre": "ROBERTO LOBO RODRIGUEZ"
    },
    {
        "codigoPre": 5400100063,
        "codigoSub": "1",
        "nombre": "JOSE RODRIGUEZ SANCHEZ"
    },
    {
        "codigoPre": 5400100064,
        "codigoSub": "1",
        "nombre": "WILLIAM FUENTES TORRADO"
    },
    {
        "codigoPre": 5400100066,
        "codigoSub": "1",
        "nombre": "INEZ LILIANA OSORIO PEREZ"
    },
    {
        "codigoPre": 5400100068,
        "codigoSub": "1",
        "nombre": "JOSE IGNACIO BRAVO TORRES"
    },
    {
        "codigoPre": 5400100073,
        "codigoSub": "1",
        "nombre": "ALVARO ENRIQUE OCHOA CUBEROS"
    },
    {
        "codigoPre": 5400100074,
        "codigoSub": "1",
        "nombre": "NUCLEAR SAN JOSE S.A."
    },
    {
        "codigoPre": 5400100077,
        "codigoSub": "1",
        "nombre": "BERNARDO VEGA HENAO"
    },
    {
        "codigoPre": 5400100081,
        "codigoSub": 2,
        "nombre": "JESUS HERNANDO SOLANO ESPINOSA"
    },
    {
        "codigoPre": 5400100083,
        "codigoSub": "1",
        "nombre": "REINALDO NICOLAS OMAC\u0011A HERRAN"
    },
    {
        "codigoPre": 5400100084,
        "codigoSub": "1",
        "nombre": "PEDRO ELIAS PEREZ CONTRERAS"
    },
    {
        "codigoPre": 5400100086,
        "codigoSub": "1",
        "nombre": "ASOTAC SAN JOSE SA"
    },
    {
        "codigoPre": 5400100092,
        "codigoSub": "1",
        "nombre": "maria ximena castro bonells"
    },
    {
        "codigoPre": 5400100097,
        "codigoSub": "1",
        "nombre": "CARLOS ENRIQUE CASTRO HERNANDEZ"
    },
    {
        "codigoPre": 5400100099,
        "codigoSub": "1",
        "nombre": "UROLOGOS DEL NORTE DE SANTANDER URONORTE S.A. URONORTE S.A."
    },
    {
        "codigoPre": 5400100100,
        "codigoSub": "1",
        "nombre": "JESUS MIGUEL MURIEL OTERO"
    },
    {
        "codigoPre": 5400100102,
        "codigoSub": "1",
        "nombre": "JOSE MANUEL PINZON SARRIA"
    },
    {
        "codigoPre": 5400100106,
        "codigoSub": "1",
        "nombre": "CARLOS FERNANDO SAIEH SAIEH"
    },
    {
        "codigoPre": 5400100107,
        "codigoSub": "1",
        "nombre": "JORGE ELIECER SILVA RODRIGUEZ"
    },
    {
        "codigoPre": 5400100108,
        "codigoSub": "1",
        "nombre": "angel alcides barba barba"
    },
    {
        "codigoPre": 5400100111,
        "codigoSub": "1",
        "nombre": "RAMON ABEL VASQUEZ SANTAFE"
    },
    {
        "codigoPre": 5400100113,
        "codigoSub": "1",
        "nombre": "LUIS FERNANDO CONDE BUITRAGO"
    },
    {
        "codigoPre": 5400100114,
        "codigoSub": "1",
        "nombre": "PATRICIA ELENA RODRIGUEZ GARCIA"
    },
    {
        "codigoPre": 5400100115,
        "codigoSub": "1",
        "nombre": "LUIS ALBERTO AFANADOR GARCIA"
    },
    {
        "codigoPre": 5400100119,
        "codigoSub": "1",
        "nombre": "OSCAR FERNANDO PARADA ROJAS"
    },
    {
        "codigoPre": 5400100123,
        "codigoSub": "1",
        "nombre": "CARLOS AUGUSTO DELGADO SANTAELLA"
    },
    {
        "codigoPre": 5400100127,
        "codigoSub": "1",
        "nombre": "RAMON ALBERTO SANCHEZ CARBALLO"
    },
    {
        "codigoPre": 5400100129,
        "codigoSub": "1",
        "nombre": "MARIA DEL PILAR RAMIREZ OCHOA"
    },
    {
        "codigoPre": 5400100135,
        "codigoSub": "1",
        "nombre": "German Ernesto Duran Duarte"
    },
    {
        "codigoPre": 5400100137,
        "codigoSub": "1",
        "nombre": "LEON ULISES COLMENARES VELASCO"
    },
    {
        "codigoPre": 5400100142,
        "codigoSub": "1",
        "nombre": "IVAN GUILLERMO PEC\u0011ARANDA DONCEL"
    },
    {
        "codigoPre": 5400100148,
        "codigoSub": "1",
        "nombre": "EFRAIN GAMAL PAEZ SUZ"
    },
    {
        "codigoPre": 5400100149,
        "codigoSub": "1",
        "nombre": "WOLFGANG PARADA VIVAS"
    },
    {
        "codigoPre": 5400100155,
        "codigoSub": "1",
        "nombre": "RAFAEL JOSE SUZ COURY"
    },
    {
        "codigoPre": 5400100156,
        "codigoSub": "1",
        "nombre": "GUSTAVO ENRIQUE CARVAJAL FRANKLIN"
    },
    {
        "codigoPre": 5400100157,
        "codigoSub": "1",
        "nombre": "JAIRO ENRIQUE ASCENCIO GUTIERREZ"
    },
    {
        "codigoPre": 5400100158,
        "codigoSub": "1",
        "nombre": "ANTONIO JOSE SANABRIA LABRADOR"
    },
    {
        "codigoPre": 5400100161,
        "codigoSub": "1",
        "nombre": "DANIEL IVAN ARISTIZABAL OLAYA"
    },
    {
        "codigoPre": 5400100165,
        "codigoSub": "1",
        "nombre": "VICTOR ENRRIQUE ANTOLINEZ AYALA"
    },
    {
        "codigoPre": 5400100171,
        "codigoSub": "1",
        "nombre": "LUIS ALBERTO LOBO JACOME"
    },
    {
        "codigoPre": 5400100172,
        "codigoSub": "1",
        "nombre": "ALEXANDRA RODRIGUEZ PEREZ"
    },
    {
        "codigoPre": 5400100173,
        "codigoSub": "1",
        "nombre": "CENTRO DE PSICOLOGIA Y TERAPIAS I.P.S. SAS"
    },
    {
        "codigoPre": 5400100184,
        "codigoSub": "1",
        "nombre": "FUNDACION MARIO GAITAN YANGUAS"
    },
    {
        "codigoPre": 5400100185,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS LUZARDO CASTRO"
    },
    {
        "codigoPre": 5400100188,
        "codigoSub": "1",
        "nombre": "NIDIAN SALAMANCA HERNANDEZ"
    },
    {
        "codigoPre": 5400100190,
        "codigoSub": "1",
        "nombre": "PARRA MONTOYA CARLOS EDUARDO"
    },
    {
        "codigoPre": 5400100191,
        "codigoSub": "1",
        "nombre": "E.S.E. CENTRO DE REHABILITACION CARDIO NEURO MUSCULAR DEL NORTE DE SANTANDER"
    },
    {
        "codigoPre": 5400100192,
        "codigoSub": "1",
        "nombre": "DAIRO MAURICIO SARRAZOLA SANJUAN"
    },
    {
        "codigoPre": 5400100194,
        "codigoSub": "1",
        "nombre": "JAIME ENRIQUE SANCHEZ RAMON"
    },
    {
        "codigoPre": 5400100200,
        "codigoSub": "1",
        "nombre": "LAURENTINO ALBERTO GONZC\u0001LEZ TORRES"
    },
    {
        "codigoPre": 5400100202,
        "codigoSub": "1",
        "nombre": "FUNDACION VIRGILIO BARCO"
    },
    {
        "codigoPre": 5400100208,
        "codigoSub": "1",
        "nombre": "FEDERICO BENCARDINO CARPIO"
    },
    {
        "codigoPre": 5400100210,
        "codigoSub": "1",
        "nombre": "MARTHA FABIOLA GOMEZ URRUTIA"
    },
    {
        "codigoPre": 5400100214,
        "codigoSub": "1",
        "nombre": "VIVIANA CHARRIS SUAREZ"
    },
    {
        "codigoPre": 5400100219,
        "codigoSub": "1",
        "nombre": "EMMA MARIA REYES OVIEDO"
    },
    {
        "codigoPre": 5400100220,
        "codigoSub": "1",
        "nombre": "MANUEL ALBERTO SANCHES RAMON"
    },
    {
        "codigoPre": 5400100221,
        "codigoSub": "1",
        "nombre": "HUGO OSWALDO ALVARADO MONTAC\u0011EZ"
    },
    {
        "codigoPre": 5400100225,
        "codigoSub": "1",
        "nombre": "ANA NELLY BERBESI SANDOVAL"
    },
    {
        "codigoPre": 5400100228,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO SALGAR VILLAMIZAR"
    },
    {
        "codigoPre": 5400100230,
        "codigoSub": "1",
        "nombre": "MARIA ISABEL ARAQUE SANCHEZ"
    },
    {
        "codigoPre": 5400100233,
        "codigoSub": "1",
        "nombre": "ALVARO ERNESTO RAMIREZ MORELLI"
    },
    {
        "codigoPre": 5400100234,
        "codigoSub": "1",
        "nombre": "GERARDO RAMIREZ MORELLI"
    },
    {
        "codigoPre": 5400100235,
        "codigoSub": "1",
        "nombre": "JAIME FAILLACE CASTILLO"
    },
    {
        "codigoPre": 5400100236,
        "codigoSub": "1",
        "nombre": "HERNANDO LIZARAZO PEC\u0011ARANDA"
    },
    {
        "codigoPre": 5400100248,
        "codigoSub": "1",
        "nombre": "MANUEL RICARDO BOBREK OROZCO"
    },
    {
        "codigoPre": 5400100250,
        "codigoSub": "1",
        "nombre": "NESTOR EDUARDO SOTO COTE"
    },
    {
        "codigoPre": 5400100252,
        "codigoSub": "1",
        "nombre": "LIGA NORTE SANTANDEREANA DE LUCHA CONTRA EL CANCER"
    },
    {
        "codigoPre": 5400100253,
        "codigoSub": "1",
        "nombre": "MARTHA BEATRIZ CASTILLO GARCIA"
    },
    {
        "codigoPre": 5400100257,
        "codigoSub": "1",
        "nombre": "WILSON GUTIERREZ RAMIREZ"
    },
    {
        "codigoPre": 5400100261,
        "codigoSub": "1",
        "nombre": "jaime richar quintero caceres"
    },
    {
        "codigoPre": 5400100267,
        "codigoSub": "1",
        "nombre": "JAVIER FRANCISCO HERNANDEZ FLOREZ"
    },
    {
        "codigoPre": 5400100269,
        "codigoSub": "1",
        "nombre": "NORTE SALUD S.A."
    },
    {
        "codigoPre": 5400100271,
        "codigoSub": "1",
        "nombre": "JAVIER DARIO PAZ MONTES"
    },
    {
        "codigoPre": 5400100272,
        "codigoSub": "1",
        "nombre": "ELCY ARANGO RUIZ"
    },
    {
        "codigoPre": 5400100273,
        "codigoSub": "1",
        "nombre": "OLGA CRISTINA GUERRERO RAMIREZ"
    },
    {
        "codigoPre": 5400100275,
        "codigoSub": "1",
        "nombre": "ZULEIMA DEL PILAR CASTILLO PABON"
    },
    {
        "codigoPre": 5400100276,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO FIGUEREDO MOLINA"
    },
    {
        "codigoPre": 5400100292,
        "codigoSub": "1",
        "nombre": "MILTON ISRAEL RUEDA MUC\u0011OZ"
    },
    {
        "codigoPre": 5400100293,
        "codigoSub": "1",
        "nombre": "CANDELARIA RAMOS PADILLA"
    },
    {
        "codigoPre": 5400100294,
        "codigoSub": "1",
        "nombre": "MARIA YASMINE MARCIALES SANTOS"
    },
    {
        "codigoPre": 5400100295,
        "codigoSub": "1",
        "nombre": "ALFREDO JOSE SOTOMAYOR OLIVEROS"
    },
    {
        "codigoPre": 5400100297,
        "codigoSub": "1",
        "nombre": "Adriana Victoria Herrera Becerra"
    },
    {
        "codigoPre": 5400100302,
        "codigoSub": "1",
        "nombre": "CLAUDIA ESTELLA ZAMBRANO ROJAS"
    },
    {
        "codigoPre": 5400100305,
        "codigoSub": "1",
        "nombre": "ADIEL ALBERTO VAZQUEZ QUIJANO"
    },
    {
        "codigoPre": 5400100306,
        "codigoSub": "1",
        "nombre": "UNIOPTICA LIMITADA"
    },
    {
        "codigoPre": 5400100307,
        "codigoSub": "1",
        "nombre": "LUCIA CONSUELO FLOREZ ECHEVERRIA"
    },
    {
        "codigoPre": 5400100310,
        "codigoSub": "1",
        "nombre": "CARLOS GUSTAVO MORA RUIZ"
    },
    {
        "codigoPre": 5400100311,
        "codigoSub": "1",
        "nombre": "ADRIANA TRILLOS VARGAS"
    },
    {
        "codigoPre": 5400100312,
        "codigoSub": "1",
        "nombre": "JAIME LEONARDO CAMACHO CASTRO"
    },
    {
        "codigoPre": 5400100316,
        "codigoSub": "1",
        "nombre": "DANIEL MARTIN NEIRA JAUREGUI"
    },
    {
        "codigoPre": 5400100317,
        "codigoSub": "1",
        "nombre": "MARIA ALEXANDRA RINCON PEREZ"
    },
    {
        "codigoPre": 5400100323,
        "codigoSub": "1",
        "nombre": "LUIS EMILIO ESCALANTE LUZARDO"
    },
    {
        "codigoPre": 5400100325,
        "codigoSub": "1",
        "nombre": "EDGAR ACEVEDO PUERTO"
    },
    {
        "codigoPre": 5400100328,
        "codigoSub": "1",
        "nombre": "IGOR RAMIREZ GOMEZ"
    },
    {
        "codigoPre": 5400100331,
        "codigoSub": 2,
        "nombre": "OMAR JAVIER ALBARRACIN ACOSTA"
    },
    {
        "codigoPre": 5400100334,
        "codigoSub": "1",
        "nombre": "MARCELINO CASTAC\u0011EDA VELASCO"
    },
    {
        "codigoPre": 5400100337,
        "codigoSub": "1",
        "nombre": "MANUEL GUILLERMO SERRANO TRILLOS"
    },
    {
        "codigoPre": 5400100341,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO MARTC\rNEZ GARCIA"
    },
    {
        "codigoPre": 5400100344,
        "codigoSub": "1",
        "nombre": "CARLOS JOSE PARADA ROJAS"
    },
    {
        "codigoPre": 5400100362,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO CASTRO LOBO"
    },
    {
        "codigoPre": 5400100363,
        "codigoSub": "1",
        "nombre": "ELVIS URLEY GONZALEZ QUINTERO"
    },
    {
        "codigoPre": 5400100364,
        "codigoSub": "1",
        "nombre": "RTS S.A.S"
    },
    {
        "codigoPre": 5400100365,
        "codigoSub": "1",
        "nombre": "HERNAN RODRIGUEZ GUITIERREZ"
    },
    {
        "codigoPre": 5400100367,
        "codigoSub": "1",
        "nombre": "MATILDE ELENA LLANOS CAMPO"
    },
    {
        "codigoPre": 5400100368,
        "codigoSub": "1",
        "nombre": "CARLOS HUMBERTO MORA URBINA"
    },
    {
        "codigoPre": 5400100369,
        "codigoSub": "1",
        "nombre": "ALBA YOLIMA CASTILLO BLANCO"
    },
    {
        "codigoPre": 5400100371,
        "codigoSub": "1",
        "nombre": "E.S.E. HOSPITAL UNIVERSITARIO ERASMO MEOZ"
    },
    {
        "codigoPre": 5400100373,
        "codigoSub": "1",
        "nombre": "CUERPO DE BOMBEROS VOLUNTARIOS DE CUCUTA"
    },
    {
        "codigoPre": 5400100383,
        "codigoSub": "1",
        "nombre": "ROBERTO JOSE CLARO JURE"
    },
    {
        "codigoPre": 5400100384,
        "codigoSub": "1",
        "nombre": "MARTHA ESPERANZA VALDIVIESO GONZALEZ"
    },
    {
        "codigoPre": 5400100390,
        "codigoSub": "1",
        "nombre": "LUZ ISMENIA COLMENARES PEC\u0011A"
    },
    {
        "codigoPre": 5400100394,
        "codigoSub": "1",
        "nombre": "RAMON ANTONIO OSPINA SANCHEZ"
    },
    {
        "codigoPre": 5400100397,
        "codigoSub": "1",
        "nombre": "ROSA IRENE ORTEGA ROJAS"
    },
    {
        "codigoPre": 5400100402,
        "codigoSub": "1",
        "nombre": "MARIO NEL IZQUIERDO SANDOVAL"
    },
    {
        "codigoPre": 5400100404,
        "codigoSub": "1",
        "nombre": "YUDY CARDENAS ALVAREZ"
    },
    {
        "codigoPre": 5400100410,
        "codigoSub": "1",
        "nombre": "FABIOLA DEL ROCIO PINTO QUINTERO"
    },
    {
        "codigoPre": 5400100414,
        "codigoSub": "1",
        "nombre": "MARTHA LUCIA GALLARDO CORREA"
    },
    {
        "codigoPre": 5400100416,
        "codigoSub": "1",
        "nombre": "CARMEN ROSARIO RUEDA SANABRIA"
    },
    {
        "codigoPre": 5400100417,
        "codigoSub": "1",
        "nombre": "SERVICIO INTEGRAL DE SALUD OCUPACIONAL LTDA SISO"
    },
    {
        "codigoPre": 5400100418,
        "codigoSub": "1",
        "nombre": "Carlos Eduardo Garcia Parada"
    },
    {
        "codigoPre": 5400100422,
        "codigoSub": "1",
        "nombre": "ALVARO ANDRES FLOREZ FAILLACE"
    },
    {
        "codigoPre": 5400100424,
        "codigoSub": "1",
        "nombre": "AMANDA MARQUEZ SOLANO"
    },
    {
        "codigoPre": 5400100425,
        "codigoSub": "1",
        "nombre": "ABELARDO VESGA CALA"
    },
    {
        "codigoPre": 5400100427,
        "codigoSub": "1",
        "nombre": "CLINICA CEGINOB LTDA"
    },
    {
        "codigoPre": 5400100428,
        "codigoSub": "1",
        "nombre": "CENTRO RADIOLOGICO 15 SA"
    },
    {
        "codigoPre": 5400100429,
        "codigoSub": "1",
        "nombre": "JOSE FERNANDO RODRIGUEZ VALENCIA"
    },
    {
        "codigoPre": 5400100432,
        "codigoSub": "1",
        "nombre": "RODOLFO EDUARDO MARTINEZ FLOREZ"
    },
    {
        "codigoPre": 5400100433,
        "codigoSub": "1",
        "nombre": "NANCY URIBE NAVARRO"
    },
    {
        "codigoPre": 5400100435,
        "codigoSub": "1",
        "nombre": "JORGE EDUARDO ROJAS VALBUENA"
    },
    {
        "codigoPre": 5400100436,
        "codigoSub": "1",
        "nombre": "MARIO ALBERTO IZQUIERDO VELASQUEZ"
    },
    {
        "codigoPre": 5400100437,
        "codigoSub": "1",
        "nombre": "MARTHA TERESA CALDERON MANCILLA"
    },
    {
        "codigoPre": 5400100438,
        "codigoSub": "1",
        "nombre": "MARIA AMPARO MARTINEZ PEREZ"
    },
    {
        "codigoPre": 5400100439,
        "codigoSub": "1",
        "nombre": "LOURDES MARIA ACEVEDO PUERTO"
    },
    {
        "codigoPre": 5400100440,
        "codigoSub": "1",
        "nombre": "JOSE JOAQUIN FIGUEREDO MOLINA"
    },
    {
        "codigoPre": 5400100441,
        "codigoSub": "1",
        "nombre": "CRUZ GABRIELA ROMERO LUNA"
    },
    {
        "codigoPre": 5400100446,
        "codigoSub": "1",
        "nombre": "EDGAR RAFAEL MEJIA VELEZ"
    },
    {
        "codigoPre": 5400100449,
        "codigoSub": "1",
        "nombre": "VIANZA & BIENESTAR Upgds SAS"
    },
    {
        "codigoPre": 5400100451,
        "codigoSub": "1",
        "nombre": "JAIRO FRANCISCO LIZARAZO NIC\u0011O"
    },
    {
        "codigoPre": 5400100452,
        "codigoSub": "1",
        "nombre": "JAIME ALBERTO FIGUEROA VILLARREAL"
    },
    {
        "codigoPre": 5400100454,
        "codigoSub": "1",
        "nombre": "JUAN PABLO PARADA LUZARDO"
    },
    {
        "codigoPre": 5400100456,
        "codigoSub": "1",
        "nombre": "MARIA GABRIELA ESCALANTE LUZARDO"
    },
    {
        "codigoPre": 5400100459,
        "codigoSub": "1",
        "nombre": "LUIS ALBERTO BARBOSA RAMIREZ"
    },
    {
        "codigoPre": 5400100468,
        "codigoSub": "1",
        "nombre": "IVAN ENRIQUE CASTIBLANCO GOMEZ"
    },
    {
        "codigoPre": 5400100470,
        "codigoSub": "1",
        "nombre": "CLINICA SAN JOSE DE CUCUTA SA"
    },
    {
        "codigoPre": 5400100485,
        "codigoSub": 2,
        "nombre": "GEOVANNY MANDON NAVARRO"
    },
    {
        "codigoPre": 5400100487,
        "codigoSub": "1",
        "nombre": "MAGOLA REYES QUINTERO"
    },
    {
        "codigoPre": 5400100488,
        "codigoSub": "1",
        "nombre": "E.S.E. HOSPITAL MENTAL RUDESINDO SOTO"
    },
    {
        "codigoPre": 5400100490,
        "codigoSub": "1",
        "nombre": "MIGUEL ALFONSO CHAHIN RUEDA"
    },
    {
        "codigoPre": 5400100491,
        "codigoSub": "1",
        "nombre": "CATERINE EDITH BUITRAGO SANCHEZ"
    },
    {
        "codigoPre": 5400100495,
        "codigoSub": "1",
        "nombre": "REINALDO GABRIEL VILLABONA PEREZ"
    },
    {
        "codigoPre": 5400100500,
        "codigoSub": "1",
        "nombre": "SERGIO ALBERTO YAC\u0011EZ RAMIREZ"
    },
    {
        "codigoPre": 5400100509,
        "codigoSub": "1",
        "nombre": "JORGE ENRIQUE URIBE GONZALEZ"
    },
    {
        "codigoPre": 5400100513,
        "codigoSub": "1",
        "nombre": "Luz Marina Gonzalez Gutierrez"
    },
    {
        "codigoPre": 5400100514,
        "codigoSub": "1",
        "nombre": "ESTHER SORAYA SIERRA DAZA"
    },
    {
        "codigoPre": 5400100516,
        "codigoSub": "1",
        "nombre": "JOSE LUIS MARIC\u0011O PEREZ"
    },
    {
        "codigoPre": 5400100519,
        "codigoSub": "1",
        "nombre": "NORA STELLA GONZALEZ MEDINA"
    },
    {
        "codigoPre": 5400100520,
        "codigoSub": "1",
        "nombre": "VICTORIA EUGENIA JAIME DELGADO"
    },
    {
        "codigoPre": 5400100524,
        "codigoSub": 2,
        "nombre": "CARLOS MAURICIO CARDENAS SALCEDO"
    },
    {
        "codigoPre": 5400100525,
        "codigoSub": "1",
        "nombre": "NOHEMY HERNANDEZ ACOSTA"
    },
    {
        "codigoPre": 5400100526,
        "codigoSub": "1",
        "nombre": "CLARA INES DUARTE BARRETO"
    },
    {
        "codigoPre": 5400100531,
        "codigoSub": "1",
        "nombre": "DEISSY LILIANA SANTOS TORRES"
    },
    {
        "codigoPre": 5400100532,
        "codigoSub": "1",
        "nombre": "WILSON FERNANDO PICON BOADA"
    },
    {
        "codigoPre": 5400100533,
        "codigoSub": "1",
        "nombre": "JOSE ANTONIO ROZO PEREZ"
    },
    {
        "codigoPre": 5400100555,
        "codigoSub": "1",
        "nombre": "JORGE SERGIO MENDOZA CASADIEGOS"
    },
    {
        "codigoPre": 5400100557,
        "codigoSub": 4,
        "nombre": "Corporacion MI Upgds Norte de Santander"
    },
    {
        "codigoPre": 5400100559,
        "codigoSub": "1",
        "nombre": "IRMA CARDENAS GRANADOS"
    },
    {
        "codigoPre": 5400100560,
        "codigoSub": "1",
        "nombre": "ROBERTH ANTONIO RUIZ CAMARGO"
    },
    {
        "codigoPre": 5400100562,
        "codigoSub": "1",
        "nombre": "ZULLY MARBELLI ESPINEL ORTIZ"
    },
    {
        "codigoPre": 5400100564,
        "codigoSub": "1",
        "nombre": "JOSE EDGAR SALGAR VILLAMIZAR"
    },
    {
        "codigoPre": 5400100565,
        "codigoSub": "1",
        "nombre": "SANDRA ROCIO PARRA SARMIENTO"
    },
    {
        "codigoPre": 5400100566,
        "codigoSub": "1",
        "nombre": "MARIA ALEXANDRA FIGUEREDO MOLINA"
    },
    {
        "codigoPre": 5400100570,
        "codigoSub": "1",
        "nombre": "CRUZ ROJA COLOMBIANA, SECCIONAL NORTE DE SANTANDER"
    },
    {
        "codigoPre": 5400100572,
        "codigoSub": "1",
        "nombre": "CARLOS ALBERTO VIVAS MOSQUERA"
    },
    {
        "codigoPre": 5400100573,
        "codigoSub": "1",
        "nombre": "LUZ AMPARO PINZON ARCINIEGAS"
    },
    {
        "codigoPre": 5400100585,
        "codigoSub": "1",
        "nombre": "GLADYS JULIA ROJAS GUTIERREZ"
    },
    {
        "codigoPre": 5400100587,
        "codigoSub": "1",
        "nombre": "JORGE LUIS FLOREZ PEREZ"
    },
    {
        "codigoPre": 5400100590,
        "codigoSub": "1",
        "nombre": "DELIA BIBIANA SANTAELLA CARRERO"
    },
    {
        "codigoPre": 5400100591,
        "codigoSub": "1",
        "nombre": "CARMEN ELENA SANTAELLA CARRERO"
    },
    {
        "codigoPre": 5400100592,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO BRAHIM SUS"
    },
    {
        "codigoPre": 5400100595,
        "codigoSub": "1",
        "nombre": "ALARCON PREVENIR SALUD OCUPACIONAL LTDA"
    },
    {
        "codigoPre": 5400100597,
        "codigoSub": "1",
        "nombre": "ZORAYA ALEJANDRA RODRIGUEZ SOTO"
    },
    {
        "codigoPre": 5400100600,
        "codigoSub": "1",
        "nombre": "CARLOS ANDRES SUAREZ GRANADA"
    },
    {
        "codigoPre": 5400100602,
        "codigoSub": "1",
        "nombre": "ENRIQUE ANTONIO YAC\u0011EZ ARENAS"
    },
    {
        "codigoPre": 5400100603,
        "codigoSub": "1",
        "nombre": "CLINICA SANTA ANA S.A."
    },
    {
        "codigoPre": 5400100612,
        "codigoSub": "1",
        "nombre": "JORGE ENRIQUE GALVIS RAMIREZ"
    },
    {
        "codigoPre": 5400100613,
        "codigoSub": "1",
        "nombre": "SERGIO FRANCISCO TORRES RAMIREZ"
    },
    {
        "codigoPre": 5400100614,
        "codigoSub": "1",
        "nombre": "RAFAEL DARIO FORERO GAMBOA"
    },
    {
        "codigoPre": 5400100615,
        "codigoSub": 5,
        "nombre": "VILMA OROZCO AYALA"
    },
    {
        "codigoPre": 5400100617,
        "codigoSub": "1",
        "nombre": "MYRIAM STELLA SANCHEZ DUARTE"
    },
    {
        "codigoPre": 5400100620,
        "codigoSub": "1",
        "nombre": "JESSICA ALEIDA ROLON BARROSO"
    },
    {
        "codigoPre": 5400100622,
        "codigoSub": "1",
        "nombre": "JOSUE ALBERTO JAIMES SOLANO"
    },
    {
        "codigoPre": 5400100625,
        "codigoSub": "1",
        "nombre": "JULIO ALBERTO REYES RAMON"
    },
    {
        "codigoPre": 5400100626,
        "codigoSub": "1",
        "nombre": "monica patricia tello baez"
    },
    {
        "codigoPre": 5400100629,
        "codigoSub": "1",
        "nombre": "GIOVANNI HERNANDEZ ARDILA"
    },
    {
        "codigoPre": 5400100630,
        "codigoSub": "1",
        "nombre": "ANA CECILIA ROSO REYES"
    },
    {
        "codigoPre": 5400100632,
        "codigoSub": "1",
        "nombre": "PABLO ENRIQUE COLMENARES PORRAS"
    },
    {
        "codigoPre": 5400100633,
        "codigoSub": "1",
        "nombre": "CENTRO MEDICO DE URGENCIAS SAN RAFAEL LTDA"
    },
    {
        "codigoPre": 5400100635,
        "codigoSub": "1",
        "nombre": "SONIA ESPERANZA ARCINIEGAS VILLAMIZAR"
    },
    {
        "codigoPre": 5400100644,
        "codigoSub": "1",
        "nombre": "CAJA DE COMPENSACION FAMILIAR DEL NORTE DE SANTANDER COMFANORTE"
    },
    {
        "codigoPre": 5400100669,
        "codigoSub": "1",
        "nombre": "GERSON ENRIQUE QUINTERO CACERES"
    },
    {
        "codigoPre": 5400100674,
        "codigoSub": "1",
        "nombre": "GERMAN JIMENEZ PALLARES"
    },
    {
        "codigoPre": 5400100675,
        "codigoSub": "1",
        "nombre": "WILLIAM RICARDO MOGOLLON RODRIGUEZ"
    },
    {
        "codigoPre": 5400100676,
        "codigoSub": "1",
        "nombre": "CESAR AUGUSTO POMPEYO HERNANDEZ"
    },
    {
        "codigoPre": 5400100677,
        "codigoSub": "1",
        "nombre": "LUZ ADRIANA CASTAC\u0011EDA MILLAN"
    },
    {
        "codigoPre": 5400100678,
        "codigoSub": "1",
        "nombre": "YANETH OLIVA RAMIREZ MONTOYA"
    },
    {
        "codigoPre": 5400100685,
        "codigoSub": "1",
        "nombre": "ANDRES MARCIALES TOLOZA"
    },
    {
        "codigoPre": 5400100686,
        "codigoSub": "1",
        "nombre": "EDGAR CORDOBA MELO"
    },
    {
        "codigoPre": 5400100689,
        "codigoSub": "1",
        "nombre": "Universidad Antonio NariC1o"
    },
    {
        "codigoPre": 5400100694,
        "codigoSub": "1",
        "nombre": "JESC\u001aS ANDRC\t DUMES MONTERO"
    },
    {
        "codigoPre": 5400100703,
        "codigoSub": "1",
        "nombre": "CARLOS ARTURO CORZO MARTINEZ"
    },
    {
        "codigoPre": 5400100715,
        "codigoSub": "1",
        "nombre": "LEONARDO DIAZ MARTINEZ"
    },
    {
        "codigoPre": 5400100716,
        "codigoSub": "1",
        "nombre": "MARIA DEL ROSARIO OSORIO OROZCO"
    },
    {
        "codigoPre": 5400100722,
        "codigoSub": "1",
        "nombre": "FELIX MARTIN BERMUDEZ SANTAELLA"
    },
    {
        "codigoPre": 5400100724,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS SUAREZ MOLINA"
    },
    {
        "codigoPre": 5400100728,
        "codigoSub": "1",
        "nombre": "JAIME ERNESTO BAUTISTA GOMEZ"
    },
    {
        "codigoPre": 5400100731,
        "codigoSub": "1",
        "nombre": "RAFAEL LEONARDO CUELLAR SUS"
    },
    {
        "codigoPre": 5400100732,
        "codigoSub": "1",
        "nombre": "GUILLERMO LEON LABRADOR RINCON"
    },
    {
        "codigoPre": 5400100738,
        "codigoSub": "1",
        "nombre": "AMPARO RAMIREZ URIBE"
    },
    {
        "codigoPre": 5400100740,
        "codigoSub": "1",
        "nombre": "JAIME SANTIAGO MACHICADO HERRERA"
    },
    {
        "codigoPre": 5400100741,
        "codigoSub": "1",
        "nombre": "ODONTOCUCUTA S.A"
    },
    {
        "codigoPre": 5400100745,
        "codigoSub": "1",
        "nombre": "HERNANDO ANTONIO VILLAMIZAR GOMEZ"
    },
    {
        "codigoPre": 5400100746,
        "codigoSub": "1",
        "nombre": "RAMIRO HERNANDO GOMEZ FRANCO"
    },
    {
        "codigoPre": 5400100753,
        "codigoSub": "1",
        "nombre": "EDUARDO JOSE VILLAMIZAR GOMEZ"
    },
    {
        "codigoPre": 5400100755,
        "codigoSub": "1",
        "nombre": "MARTHA XIMENA GALEANO RAMIREZ"
    },
    {
        "codigoPre": 5400100756,
        "codigoSub": "1",
        "nombre": "NOE CASTRO GOMEZ"
    },
    {
        "codigoPre": 5400100759,
        "codigoSub": "1",
        "nombre": "Ceny Del Carmen Salas Burgos"
    },
    {
        "codigoPre": 5400100766,
        "codigoSub": "1",
        "nombre": "LIDIA ROCIO DEL PILAR ESCALANTE MENDOZA"
    },
    {
        "codigoPre": 5400100767,
        "codigoSub": "1",
        "nombre": "JOAQUIN AMADO MONTOYA"
    },
    {
        "codigoPre": 5400100768,
        "codigoSub": "1",
        "nombre": "PEDRO ALFONSO AMADO MONTOYA"
    },
    {
        "codigoPre": 5400100771,
        "codigoSub": "1",
        "nombre": "JOAQUIN AMADO GUTIERREZ"
    },
    {
        "codigoPre": 5400100780,
        "codigoSub": "1",
        "nombre": "MARTHA LILIANA SALGAR GALLEGO"
    },
    {
        "codigoPre": 5400100781,
        "codigoSub": "1",
        "nombre": "LUIS ALBERTO CORREDOR JACOME"
    },
    {
        "codigoPre": 5400100782,
        "codigoSub": "1",
        "nombre": "CLINICA OFTALMOLOGICA PEC\u0011ARANDA S.A.S"
    },
    {
        "codigoPre": 5400100784,
        "codigoSub": "1",
        "nombre": "RICARDO ALFREDO LAMUS BECERRA"
    },
    {
        "codigoPre": 5400100793,
        "codigoSub": "1",
        "nombre": "MIGUEL EDUARDO BARRIOS CASTILLO"
    },
    {
        "codigoPre": 5400100796,
        "codigoSub": "1",
        "nombre": "JUAN ARTURO DE LA SANTISIMA TRINIDAD SANJUAN SANGUINO"
    },
    {
        "codigoPre": 5400100810,
        "codigoSub": "1",
        "nombre": "CLINICA NORTE S.A."
    },
    {
        "codigoPre": 5400100820,
        "codigoSub": "1",
        "nombre": "JAVIER RAMIREZ FIGUEROA"
    },
    {
        "codigoPre": 5400100821,
        "codigoSub": "1",
        "nombre": "YOLANDA PEC\u0011ARANDA PEC\u0011ARANDA"
    },
    {
        "codigoPre": 5400100823,
        "codigoSub": "1",
        "nombre": "CENTRO CARDIOLOGICO LTDA"
    },
    {
        "codigoPre": 5400100824,
        "codigoSub": "1",
        "nombre": "RAFAEL DARIO ROLON DUARTE"
    },
    {
        "codigoPre": 5400100825,
        "codigoSub": "1",
        "nombre": "GIANCARLO BOTTA FERNANDEZ"
    },
    {
        "codigoPre": 5400100828,
        "codigoSub": "1",
        "nombre": "CLINICA LOS ANDES LTDA."
    },
    {
        "codigoPre": 5400100830,
        "codigoSub": "1",
        "nombre": "NIDIA MARIA URIBE CASTILLO"
    },
    {
        "codigoPre": 5400100834,
        "codigoSub": "1",
        "nombre": "ESTUDIOS E INVERSIONES MEDICAS S A ESIMED S A"
    },
    {
        "codigoPre": 5400100840,
        "codigoSub": "1",
        "nombre": "LUIS EDUARDO RODRIGUEZ RAMON"
    },
    {
        "codigoPre": 5400100844,
        "codigoSub": "1",
        "nombre": "NHORA PEREZ DE MARIC\u0011O"
    },
    {
        "codigoPre": 5400100847,
        "codigoSub": "1",
        "nombre": "MARIA CAROLINA BRAHIM MUC\u0011OZ"
    },
    {
        "codigoPre": 5400100852,
        "codigoSub": "1",
        "nombre": "JESUS IVAN HERNANDEZ WILCHES"
    },
    {
        "codigoPre": 5400100853,
        "codigoSub": "1",
        "nombre": "LUZ MARINA DIEZ LEDESMA"
    },
    {
        "codigoPre": 5400100855,
        "codigoSub": "1",
        "nombre": "CLINICA MEDICO QUIRURGICA S.A"
    },
    {
        "codigoPre": 5400100857,
        "codigoSub": "1",
        "nombre": "RICARDO ANTONIO ESCOBAR VELASCO"
    },
    {
        "codigoPre": 5400100858,
        "codigoSub": "1",
        "nombre": "NORTAC LTDA"
    },
    {
        "codigoPre": 5400100861,
        "codigoSub": "1",
        "nombre": "EMPRESA SOCIAL DEL ESTADO IMSALUD"
    },
    {
        "codigoPre": 5400100878,
        "codigoSub": "1",
        "nombre": "EDNA MARGARITA PEREZ AREVALO"
    },
    {
        "codigoPre": 5400100882,
        "codigoSub": "1",
        "nombre": "adolfo jesus peralta sanguino"
    },
    {
        "codigoPre": 5400100888,
        "codigoSub": "1",
        "nombre": "JUAN AGUSTIN RAMIREZ MONTOYA"
    },
    {
        "codigoPre": 5400100890,
        "codigoSub": "1",
        "nombre": "MARIA DEL PILAR GRANADOS RAMIREZ"
    },
    {
        "codigoPre": 5400100893,
        "codigoSub": "1",
        "nombre": "MARGIE OJEDA ANAYA"
    },
    {
        "codigoPre": 5400100897,
        "codigoSub": "1",
        "nombre": "ALVARO ENRIQUE GRANADOS DIAZ"
    },
    {
        "codigoPre": 5400100901,
        "codigoSub": "1",
        "nombre": "RAFAEL ALBERTO FANDIC\u0011O PRADA"
    },
    {
        "codigoPre": 5400100903,
        "codigoSub": "1",
        "nombre": "SONIA ESPERANZA GUTIERREZ CLAVIJO"
    },
    {
        "codigoPre": 5400100910,
        "codigoSub": "1",
        "nombre": "OSWALDO ENRIQUE MUC\u0011OZ CARRILLO"
    },
    {
        "codigoPre": 5400100916,
        "codigoSub": "1",
        "nombre": "JAIRO ALFONSO ZERPA LUNA"
    },
    {
        "codigoPre": 5400100918,
        "codigoSub": "1",
        "nombre": "NELSON GUSTAVO CASTRO PEREZ"
    },
    {
        "codigoPre": 5400100919,
        "codigoSub": "1",
        "nombre": "RENE FIGUEROA MELGAREJO"
    },
    {
        "codigoPre": 5400100929,
        "codigoSub": "1",
        "nombre": "ALVARO REYES VEGA"
    },
    {
        "codigoPre": 5400100933,
        "codigoSub": "1",
        "nombre": "FUNDACION MEDICO PREVENTIVA PARA EL BIENESTAR SOCIAL S.A- CUCUTA"
    },
    {
        "codigoPre": 5400100942,
        "codigoSub": "1",
        "nombre": "edgar mauricio salgar gallego"
    },
    {
        "codigoPre": 5400100944,
        "codigoSub": "1",
        "nombre": "carlos martin Rojas carvajal"
    },
    {
        "codigoPre": 5400100945,
        "codigoSub": "1",
        "nombre": "ANDRES EDUARDO MEJIA VELEZ"
    },
    {
        "codigoPre": 5400100947,
        "codigoSub": "1",
        "nombre": "Nicolas Enrique Bitar Mejia"
    },
    {
        "codigoPre": 5400100948,
        "codigoSub": "1",
        "nombre": "JUSTO ORLANDO BALLEN CACERES"
    },
    {
        "codigoPre": 5400100951,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS RODELO PINTO"
    },
    {
        "codigoPre": 5400100961,
        "codigoSub": "1",
        "nombre": "MARTHA LUDY MORALES REY"
    },
    {
        "codigoPre": 5400100963,
        "codigoSub": "1",
        "nombre": "LESLIE RUTH DURAN RAMIREZ"
    },
    {
        "codigoPre": 5400100967,
        "codigoSub": "1",
        "nombre": "FRESENIUS MEDICAL CARE COLOMBIA S.A."
    },
    {
        "codigoPre": 5400100969,
        "codigoSub": "1",
        "nombre": "SOMEDIAG LTDA"
    },
    {
        "codigoPre": 5400100972,
        "codigoSub": "1",
        "nombre": "LIDA MARIA RAMIRES PUERTO"
    },
    {
        "codigoPre": 5400100979,
        "codigoSub": "1",
        "nombre": "EDWIN ARMANDO YAC\u0011EZ MEZA"
    },
    {
        "codigoPre": 5400100980,
        "codigoSub": "1",
        "nombre": "GINETH ADRIANA CARDENAS GONZALES"
    },
    {
        "codigoPre": 5400100981,
        "codigoSub": "1",
        "nombre": "CIRO ENRIQUE ARANGO CORZO"
    },
    {
        "codigoPre": 5400100982,
        "codigoSub": "1",
        "nombre": "NORMA YANETH GARAY GUTIERREZ"
    },
    {
        "codigoPre": 5400100983,
        "codigoSub": "1",
        "nombre": "CARLOS EDUARDO GOMEZ FRANCO"
    },
    {
        "codigoPre": 5400100986,
        "codigoSub": "1",
        "nombre": "NAIDA YEANNETTE BITAR YIDI"
    },
    {
        "codigoPre": 5400100990,
        "codigoSub": "1",
        "nombre": "CLAUDIA LILIANA RAMIREZ PUERTO"
    },
    {
        "codigoPre": 5400101007,
        "codigoSub": "1",
        "nombre": "ASOCIACIC\u0013N PROFAMILIA"
    },
    {
        "codigoPre": 5400101017,
        "codigoSub": "1",
        "nombre": "CORPORACION DE PROFESIONALES PARA EL DESARROLLO INTEGRAL COMUNITARIO-CORPRODINCO"
    },
    {
        "codigoPre": 5400101023,
        "codigoSub": "1",
        "nombre": "ROSA ESPERANZA NIC\u0011O RAMIREZ"
    },
    {
        "codigoPre": 5400101025,
        "codigoSub": "1",
        "nombre": "Marisol Duarte FernC!ndez"
    },
    {
        "codigoPre": 5400101039,
        "codigoSub": "1",
        "nombre": "EDGAR ALBERTO SALAS VILLAMIZAR"
    },
    {
        "codigoPre": 5400101041,
        "codigoSub": "1",
        "nombre": "CARLOS GABRIEL URIBE GIL"
    },
    {
        "codigoPre": 5400101047,
        "codigoSub": "1",
        "nombre": "MANUEL ALBERTO GRANADOS TORRES"
    },
    {
        "codigoPre": 5400101051,
        "codigoSub": "1",
        "nombre": "Luis Carlos Soto Calderon"
    },
    {
        "codigoPre": 5400101052,
        "codigoSub": "1",
        "nombre": "EMILIO RAMIREZ GOMEZ"
    },
    {
        "codigoPre": 5400101053,
        "codigoSub": "1",
        "nombre": "JOSE ANTONIO ASSAF ELCURE"
    },
    {
        "codigoPre": 5400101054,
        "codigoSub": "1",
        "nombre": "MARIO ALFREDO GALVIS MANTILLA"
    },
    {
        "codigoPre": 5400101055,
        "codigoSub": "1",
        "nombre": "CLAUDIA CECILIA PEREZ CHACON"
    },
    {
        "codigoPre": 5400101059,
        "codigoSub": "1",
        "nombre": "JOSE RAFAEL GUERRERO PINZON"
    },
    {
        "codigoPre": 5400101074,
        "codigoSub": 2,
        "nombre": "HERNANDO YEPES HOYOS"
    },
    {
        "codigoPre": 5400101075,
        "codigoSub": "1",
        "nombre": "EDUARDO JOSE FAJARDO JAIMES"
    },
    {
        "codigoPre": 5400101076,
        "codigoSub": "1",
        "nombre": "DORIS ELENA GRANADOS DIAZ"
    },
    {
        "codigoPre": 5400101077,
        "codigoSub": "1",
        "nombre": "JOSE GUSTAVO CAICEDO DIAZ"
    },
    {
        "codigoPre": 5400101078,
        "codigoSub": "1",
        "nombre": "MONICA ROCIO JAIMES ALVAREZ"
    },
    {
        "codigoPre": 5400101079,
        "codigoSub": "1",
        "nombre": "VIRGELMA SOLANO SOLANO"
    },
    {
        "codigoPre": 5400101082,
        "codigoSub": "1",
        "nombre": "GRISELDA LUCIA CASTRELLC\u0013N MARTC\rNEZ"
    },
    {
        "codigoPre": 5400101089,
        "codigoSub": "1",
        "nombre": "ARMANDO DIAZ CARDENAS"
    },
    {
        "codigoPre": 5400101092,
        "codigoSub": "1",
        "nombre": "Alba Luz Perez Perozo"
    },
    {
        "codigoPre": 5400101099,
        "codigoSub": "1",
        "nombre": "DIEGO YEPES HOYOS"
    },
    {
        "codigoPre": 5400101101,
        "codigoSub": "1",
        "nombre": "JORGE IVAN VILLAMIZAR MENDOZA"
    },
    {
        "codigoPre": 5400101103,
        "codigoSub": "1",
        "nombre": "MARIA DOLORES VARGAS MALAVER"
    },
    {
        "codigoPre": 5400101104,
        "codigoSub": "1",
        "nombre": "LUISA FERNANDA GARCIA RODRIGUEZ"
    },
    {
        "codigoPre": 5400101119,
        "codigoSub": "1",
        "nombre": "LUIS HUMBERTO URIBE GIL"
    },
    {
        "codigoPre": 5400101122,
        "codigoSub": "1",
        "nombre": "MAURICIO ALFONSO URIBE GIL"
    },
    {
        "codigoPre": 5400101125,
        "codigoSub": "1",
        "nombre": "OPTIKUS S.A."
    },
    {
        "codigoPre": 5400101133,
        "codigoSub": "1",
        "nombre": "SONIA MARLENI SANCHEZ DUGARTE"
    },
    {
        "codigoPre": 5400101134,
        "codigoSub": "1",
        "nombre": "VIVIANA SLENDY OSORIO ALMEIDA"
    },
    {
        "codigoPre": 5400101136,
        "codigoSub": "1",
        "nombre": "MARIA INES GOMEZ CANABAL"
    },
    {
        "codigoPre": 5400101140,
        "codigoSub": "1",
        "nombre": "MERCEDES CELINA VILLAMIL CARRERO"
    },
    {
        "codigoPre": 5400101144,
        "codigoSub": "1",
        "nombre": "AGESO LTDA - ASESORIAS EN GERENCIA Y SALUD OCUPACIONAL"
    },
    {
        "codigoPre": 5400101145,
        "codigoSub": "1",
        "nombre": "INSTITUTO LA ESPERANZA"
    },
    {
        "codigoPre": 5400101156,
        "codigoSub": "1",
        "nombre": "rosmery coll callejas"
    },
    {
        "codigoPre": 5400101171,
        "codigoSub": "1",
        "nombre": "LUIS FERNANDO PARRA GONZALEZ"
    },
    {
        "codigoPre": 5400101178,
        "codigoSub": "1",
        "nombre": "maria esperanza giraldo naranjo"
    },
    {
        "codigoPre": 5400101181,
        "codigoSub": "1",
        "nombre": "JAIRO HUMBERTO VILLAMIZAR PEC\u0011ARANDA"
    },
    {
        "codigoPre": 5400101188,
        "codigoSub": "1",
        "nombre": "HUGO ALFONSO BLANCO RAMON"
    },
    {
        "codigoPre": 5400101189,
        "codigoSub": "1",
        "nombre": "marzia andrea alvares clavijo"
    },
    {
        "codigoPre": 5400101197,
        "codigoSub": "1",
        "nombre": "MARIA LILIANA DORADO ILLERA"
    },
    {
        "codigoPre": 5400101204,
        "codigoSub": "1",
        "nombre": "FABIO ANTONIO BERBESI ALVAREZ"
    },
    {
        "codigoPre": 5400101221,
        "codigoSub": "1",
        "nombre": "WEIMAR ALONSO LUENGAS ALBA"
    },
    {
        "codigoPre": 5400101230,
        "codigoSub": "1",
        "nombre": "LUIS FELIPE MATAMOROS BARRETO"
    },
    {
        "codigoPre": 5400101240,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS FERNANDEZ ROMERO"
    },
    {
        "codigoPre": 5400101245,
        "codigoSub": "1",
        "nombre": "NESTOR ARCENIO GARZON BARRERA"
    },
    {
        "codigoPre": 5400101267,
        "codigoSub": "1",
        "nombre": "FREDDY TOSCANO SALCEDO"
    },
    {
        "codigoPre": 5400101270,
        "codigoSub": "1",
        "nombre": "SONIA ESPERANZA PRADA GOMEZ"
    },
    {
        "codigoPre": 5400101273,
        "codigoSub": "1",
        "nombre": "CLINICA INTEGRAL DE SALUD ORAL CISO E.U."
    },
    {
        "codigoPre": 5400101276,
        "codigoSub": "1",
        "nombre": "YENNY DEL PILAR ALVARADO VILLAMIZAR"
    },
    {
        "codigoPre": 5400101278,
        "codigoSub": "1",
        "nombre": "EDISON GEOVANNY REYES MONTOYA"
    },
    {
        "codigoPre": 5400101280,
        "codigoSub": "1",
        "nombre": "UNIVERSIDAD DE SANTANDER"
    },
    {
        "codigoPre": 5400101290,
        "codigoSub": "1",
        "nombre": "CLAUDIA CECILIA RIVERA BAYONA"
    },
    {
        "codigoPre": 5400101291,
        "codigoSub": "1",
        "nombre": "CLAUDIA GARCIA PALENCIA"
    },
    {
        "codigoPre": 5400101318,
        "codigoSub": "1",
        "nombre": "JOSE ELISEO VARGAS MALAVER"
    },
    {
        "codigoPre": 5400101330,
        "codigoSub": "1",
        "nombre": "CARLOS GERMAN LOPEZ GOMEZ"
    },
    {
        "codigoPre": 5400101334,
        "codigoSub": "1",
        "nombre": "ANA MERCEDES BACCA DE VILLAMIZAR"
    },
    {
        "codigoPre": 5400101336,
        "codigoSub": "1",
        "nombre": "ERICK ORLANDO FIGUEROA GUERRA"
    },
    {
        "codigoPre": 5400101337,
        "codigoSub": "1",
        "nombre": "MARCO CESAR LEIVA DIAZ"
    },
    {
        "codigoPre": 5400101350,
        "codigoSub": "1",
        "nombre": "CESAR FERNANDO JIMENEZ CORZO"
    },
    {
        "codigoPre": 5400101354,
        "codigoSub": "1",
        "nombre": "LEYLA VIVIANA TRIANA CAMARGO"
    },
    {
        "codigoPre": 5400101374,
        "codigoSub": "1",
        "nombre": "LIDYA MARIA MRAD CALA"
    },
    {
        "codigoPre": 5400101380,
        "codigoSub": "1",
        "nombre": "DEFENSA CIVIL COLOMBIANA"
    },
    {
        "codigoPre": 5400101394,
        "codigoSub": "1",
        "nombre": "SONRICENTER ORTODONCIA A TU ALCANCE LTDA II"
    },
    {
        "codigoPre": 5400101399,
        "codigoSub": "1",
        "nombre": "SILVANA RINCON PERALTA"
    },
    {
        "codigoPre": 5400101400,
        "codigoSub": "1",
        "nombre": "MARIA CRISTINA RAMOS SANCHEZ"
    },
    {
        "codigoPre": 5400101408,
        "codigoSub": "1",
        "nombre": "CARLOS ALBERTO QUINTERO ORTEGA"
    },
    {
        "codigoPre": 5400101410,
        "codigoSub": "1",
        "nombre": "CORREA PARRA EUGENIO"
    },
    {
        "codigoPre": 5400101413,
        "codigoSub": "1",
        "nombre": "ONCOMEDICAL I.P.S. S.A.S"
    },
    {
        "codigoPre": 5400101421,
        "codigoSub": "1",
        "nombre": "MARCEL LEONARDO QUINTERO CONTRERAS"
    },
    {
        "codigoPre": 5400101422,
        "codigoSub": "1",
        "nombre": "DAVID DARIO VARON JAIMES"
    },
    {
        "codigoPre": 5400101436,
        "codigoSub": "1",
        "nombre": "GERMAN ALBERTO MUC\u0011OZ DURAN"
    },
    {
        "codigoPre": 5400101459,
        "codigoSub": "1",
        "nombre": "CORPORACION PARA LA ATENCION Y EL DIAGNOSTICO DE ENFERMEDADES-MILAGROZ"
    },
    {
        "codigoPre": 5400101460,
        "codigoSub": "1",
        "nombre": "HECTOR CASTAC\u0011O MORENO"
    },
    {
        "codigoPre": 5400101479,
        "codigoSub": "1",
        "nombre": "JOSC\t JOAQUC\rN GUERRERO VARGAS"
    },
    {
        "codigoPre": 5400101480,
        "codigoSub": "1",
        "nombre": "COMFAORIENTE I.P.S."
    },
    {
        "codigoPre": 5400101485,
        "codigoSub": "1",
        "nombre": "UNIDAD MEDICA LOS CAOBOS SAS"
    },
    {
        "codigoPre": 5400101496,
        "codigoSub": "1",
        "nombre": "PABLO ALBERTO GALVIS CENTURION"
    },
    {
        "codigoPre": 5400101501,
        "codigoSub": "1",
        "nombre": "MAYRA CONTRERAS GONZALEZ"
    },
    {
        "codigoPre": 5400101502,
        "codigoSub": "1",
        "nombre": "PABLO ENRIQUE CONTRERAS ORTEGA"
    },
    {
        "codigoPre": 5400101509,
        "codigoSub": "1",
        "nombre": "GASTROQUIRURGICA S.A.S."
    },
    {
        "codigoPre": 5400101513,
        "codigoSub": "1",
        "nombre": "LIGIA MILDRED PICON CONTRERAS"
    },
    {
        "codigoPre": 5400101514,
        "codigoSub": "1",
        "nombre": "CENTRO DE ANC\u0001LISIS INMUNO-CLC\rNICO SAS"
    },
    {
        "codigoPre": 5400101517,
        "codigoSub": "1",
        "nombre": "ODONTOVIDA SAS"
    },
    {
        "codigoPre": 5400101520,
        "codigoSub": "1",
        "nombre": "XIMENA CAICEDO G. EMPRESA UNIPERSONAL"
    },
    {
        "codigoPre": 5400101521,
        "codigoSub": "1",
        "nombre": "JOSE DEL CARMEN VILLAMIZAR VILLAMIZAR"
    },
    {
        "codigoPre": 5400101529,
        "codigoSub": "1",
        "nombre": "EVALUACION Y TECNOLOGIA LTDA"
    },
    {
        "codigoPre": 5400101530,
        "codigoSub": "1",
        "nombre": "REHABILITAR CUCUTA LTDA"
    },
    {
        "codigoPre": 5400101532,
        "codigoSub": "1",
        "nombre": "EMERMOVIL S.A.S"
    },
    {
        "codigoPre": 5400101533,
        "codigoSub": "1",
        "nombre": "CLINICA ODONTOLOGICA SONRIA FELIZ SAS"
    },
    {
        "codigoPre": 5400101534,
        "codigoSub": "1",
        "nombre": "MONICA YUDIT HURTADO MORA"
    },
    {
        "codigoPre": 5400101537,
        "codigoSub": "1",
        "nombre": "JAVIER OMAR MORA VICUC\u0011A"
    },
    {
        "codigoPre": 5400101538,
        "codigoSub": "1",
        "nombre": "ADOLFO LEON BAUTISTA RODRIGUEZ"
    },
    {
        "codigoPre": 5400101542,
        "codigoSub": "1",
        "nombre": "OSCAR MAURICIO GOMEZ ARDILA"
    },
    {
        "codigoPre": 5400101544,
        "codigoSub": "1",
        "nombre": "LEONARDO ENRIQUE CARRASCAL JACOME"
    },
    {
        "codigoPre": 5400101546,
        "codigoSub": "1",
        "nombre": "Libardo Pinzon Lopez"
    },
    {
        "codigoPre": 5400101547,
        "codigoSub": "1",
        "nombre": "Sandra Milena Madero Gomez"
    },
    {
        "codigoPre": 5400101550,
        "codigoSub": "1",
        "nombre": "SCARLETH MONTAGUT ASCANIO"
    },
    {
        "codigoPre": 5400101553,
        "codigoSub": "1",
        "nombre": "luz marina buenaver arevalo"
    },
    {
        "codigoPre": 5400101556,
        "codigoSub": 2,
        "nombre": "Guiomar Angelica Aguilar CaC1as"
    },
    {
        "codigoPre": 5400101557,
        "codigoSub": "1",
        "nombre": "GLORIA ELIZABETH ZAPATA LOPEZ"
    },
    {
        "codigoPre": 5400101558,
        "codigoSub": "1",
        "nombre": "HECTOR MAURICIO URON VILLAN"
    },
    {
        "codigoPre": 5400101563,
        "codigoSub": "1",
        "nombre": "UNIDAD HEMATOLOGICA ESPECIALIZADA Upgds SAS"
    },
    {
        "codigoPre": 5400101564,
        "codigoSub": 2,
        "nombre": "DUMIAN MC\tDICAL S.A.S"
    },
    {
        "codigoPre": 5400101569,
        "codigoSub": "1",
        "nombre": "CENTRO DE ATENCION Y DIAGNOSTICO DE ENFERMEDADES INFECCIOSAS C.D.I. S.A"
    },
    {
        "codigoPre": 5400101570,
        "codigoSub": "1",
        "nombre": "karina del pilar matamoros rueda"
    },
    {
        "codigoPre": 5400101572,
        "codigoSub": "1",
        "nombre": "ELIZABETH PEREZ URREGO"
    },
    {
        "codigoPre": 5400101574,
        "codigoSub": "1",
        "nombre": "MARIA ARGENIDA BECERRA CLARO"
    },
    {
        "codigoPre": 5400101576,
        "codigoSub": "1",
        "nombre": "LORENA MARIBEL BARCO ALVARADO"
    },
    {
        "codigoPre": 5400101589,
        "codigoSub": "1",
        "nombre": "WILLLIAM SANDOVAL RODRIGUEZ"
    },
    {
        "codigoPre": 5400101592,
        "codigoSub": "1",
        "nombre": "NURY PATRICIA GOMEZ PINZON"
    },
    {
        "codigoPre": 5400101594,
        "codigoSub": "1",
        "nombre": "JUAN PABLO BALLESTEROS HOYOS"
    },
    {
        "codigoPre": 5400101597,
        "codigoSub": "1",
        "nombre": "Alain Jasaf Bautista Ramirez"
    },
    {
        "codigoPre": 5400101599,
        "codigoSub": "1",
        "nombre": "JOSE ANTONIO ROLON MANTILLA"
    },
    {
        "codigoPre": 5400101602,
        "codigoSub": 2,
        "nombre": "INES ANTONIA TERAN DIAZ"
    },
    {
        "codigoPre": 5400101605,
        "codigoSub": "1",
        "nombre": "JAVIER ELIECER PEREIRA CORREDOR"
    },
    {
        "codigoPre": 5400101606,
        "codigoSub": "1",
        "nombre": "FundaciC3n para el Servicio Integral de AtenciC3n MC)dica - FundaciC3n SIAM"
    },
    {
        "codigoPre": 5400101610,
        "codigoSub": "1",
        "nombre": "OPTICA COLSANITAS SAS"
    },
    {
        "codigoPre": 5400101615,
        "codigoSub": "1",
        "nombre": "CENTRO INTEGRAL DE REHABILITACION - CEIRE S.A.S."
    },
    {
        "codigoPre": 5400101622,
        "codigoSub": "1",
        "nombre": "INSTITUTO DE DIAGNOSTICO MEDICO S.A."
    },
    {
        "codigoPre": 5400101623,
        "codigoSub": "1",
        "nombre": "INVERSIONES DENTALES SANTANDER S.A.S"
    },
    {
        "codigoPre": 5400101624,
        "codigoSub": "1",
        "nombre": "MARIA ELCIDA GOMEZ RAMIREZ"
    },
    {
        "codigoPre": 5400101625,
        "codigoSub": "1",
        "nombre": "TATIANA ROMERO RANGEL"
    },
    {
        "codigoPre": 5400101626,
        "codigoSub": "1",
        "nombre": "GABRIEL FERNANDO FLOREZ ECHEVERRIA"
    },
    {
        "codigoPre": 5400101632,
        "codigoSub": "1",
        "nombre": "MIGUEL ROBERTO MALDONADO RANGEL"
    },
    {
        "codigoPre": 5400101637,
        "codigoSub": "1",
        "nombre": "JORGE ISAAC VILLABONA PEREZ"
    },
    {
        "codigoPre": 5400101638,
        "codigoSub": 2,
        "nombre": "YUANET OMAR SOLIAS VILLALTA"
    },
    {
        "codigoPre": 5400101647,
        "codigoSub": 4,
        "nombre": "WILMER JOSE GARCIA CALDERON"
    },
    {
        "codigoPre": 5400101648,
        "codigoSub": "1",
        "nombre": "RONALD JOSE ARDILA ARDILA"
    },
    {
        "codigoPre": 5400101649,
        "codigoSub": "1",
        "nombre": "TRANSPORTE SALUD IMC\u0001GENES TRANSALIM LTDA"
    },
    {
        "codigoPre": 5400101651,
        "codigoSub": "1",
        "nombre": "ALVARO ADRIAN BARRERA PRADA"
    },
    {
        "codigoPre": 5400101652,
        "codigoSub": "1",
        "nombre": "SANDRA LILIANA CARDENAS CONEJO"
    },
    {
        "codigoPre": 5400101653,
        "codigoSub": "1",
        "nombre": "CLAUDIA BELEN JULIO SEPULVEDA"
    },
    {
        "codigoPre": 5400101655,
        "codigoSub": "1",
        "nombre": "ANA TERESA GOVIN CID"
    },
    {
        "codigoPre": 5400101658,
        "codigoSub": "1",
        "nombre": "CATALINA ESTRADA HERNANDEZ"
    },
    {
        "codigoPre": 5400101659,
        "codigoSub": "1",
        "nombre": "ALIADOS EN SALUD S.A."
    },
    {
        "codigoPre": 5400101660,
        "codigoSub": "1",
        "nombre": "SERGIO ENRIQUE URBINA ECHEVERRY"
    },
    {
        "codigoPre": 5400101661,
        "codigoSub": "1",
        "nombre": "SOCIEDAD MEDICA LOS SAMANES SAS"
    },
    {
        "codigoPre": 5400101663,
        "codigoSub": "1",
        "nombre": "Inversiones en RecreaciC3n Deporte y Salud S.A. - BODYTECH"
    },
    {
        "codigoPre": 5400101665,
        "codigoSub": "1",
        "nombre": "SILVIA CAROLINA FLOREZ FAILLACE"
    },
    {
        "codigoPre": 5400101666,
        "codigoSub": "1",
        "nombre": "JOSE JESUS GOMEZ GIL"
    },
    {
        "codigoPre": 5400101668,
        "codigoSub": "1",
        "nombre": "TATIANA GRANADOS MEJIA"
    },
    {
        "codigoPre": 5400101669,
        "codigoSub": "1",
        "nombre": "LABORATORIO Y SERVICIOS S.A.S"
    },
    {
        "codigoPre": 5400101670,
        "codigoSub": "1",
        "nombre": "JUAN JOSE VANEGAS ACEVEDO"
    },
    {
        "codigoPre": 5400101672,
        "codigoSub": "1",
        "nombre": "MARIA ANGELICA VILLAMIZAR ROMERO"
    },
    {
        "codigoPre": 5400101688,
        "codigoSub": "1",
        "nombre": "MYRIAM MARITZA BUITRAGO SANCHEZ"
    },
    {
        "codigoPre": 5400101689,
        "codigoSub": "1",
        "nombre": "CAROLINA NAVARRO MARQUEZ"
    },
    {
        "codigoPre": 5400101699,
        "codigoSub": "1",
        "nombre": "MEDICUC Upgds LTDA 1"
    },
    {
        "codigoPre": 5400101700,
        "codigoSub": "1",
        "nombre": "VIDAMEDICAL Upgds S.A.S."
    },
    {
        "codigoPre": 5400101705,
        "codigoSub": "1",
        "nombre": "ATENCION EN REHABILITACION INTEGRAL S.A.S. \"ATERIN S.A.S.\""
    },
    {
        "codigoPre": 5400101719,
        "codigoSub": "1",
        "nombre": "angelica maria Barba Rueda"
    },
    {
        "codigoPre": 5400101722,
        "codigoSub": "1",
        "nombre": "SISMEDICA SAS CUCUTA"
    },
    {
        "codigoPre": 5400101733,
        "codigoSub": "1",
        "nombre": "SOMEFYR SAS"
    },
    {
        "codigoPre": 5400101738,
        "codigoSub": "1",
        "nombre": "EVOLUTIONDENT LTDA"
    },
    {
        "codigoPre": 5400101740,
        "codigoSub": "1",
        "nombre": "KAYROS N/S SAS"
    },
    {
        "codigoPre": 5400101744,
        "codigoSub": "1",
        "nombre": "MAXIMO ULISES SPROCKEL COTES"
    },
    {
        "codigoPre": 5400101749,
        "codigoSub": "1",
        "nombre": "Upgds ORTHOCREDIT E.U."
    },
    {
        "codigoPre": 5400101752,
        "codigoSub": "1",
        "nombre": "DARWIN ANTONIO MEDINA JAIMES"
    },
    {
        "codigoPre": 5400101756,
        "codigoSub": "1",
        "nombre": "SERVICIOS INTEGRALES EN SALUD MENTAL LTDA"
    },
    {
        "codigoPre": 5400101757,
        "codigoSub": "1",
        "nombre": "MIRIAM BLANETH REYNA DUARTE"
    },
    {
        "codigoPre": 5400101758,
        "codigoSub": "1",
        "nombre": "JOSE FELIX ARB LACRUZ"
    },
    {
        "codigoPre": 5400101759,
        "codigoSub": "1",
        "nombre": "SERVICIOS ESPECIALIZADOS FCB S.A.S"
    },
    {
        "codigoPre": 5400101763,
        "codigoSub": "1",
        "nombre": "EDGAR GERMAN FRANCO NIC\u0011O"
    },
    {
        "codigoPre": 5400101764,
        "codigoSub": "1",
        "nombre": "TULIA ISABEL COPETE VILLA"
    },
    {
        "codigoPre": 5400101766,
        "codigoSub": "1",
        "nombre": "UNIDAD ESTETICA E IMPLANTOLOGC\rA ORAL ORTHOCLINC LTDA"
    },
    {
        "codigoPre": 5400101770,
        "codigoSub": "1",
        "nombre": "DIANA KARINA DIAZ PINTO"
    },
    {
        "codigoPre": 5400101774,
        "codigoSub": "1",
        "nombre": "MARIA EUGENIA BARBOSA RAMIREZ"
    },
    {
        "codigoPre": 5400101780,
        "codigoSub": "1",
        "nombre": "CONTACTO Upgds S.A.S"
    },
    {
        "codigoPre": 5400101785,
        "codigoSub": "1",
        "nombre": "DEICY LILIANA PARDO CONTRERAS"
    },
    {
        "codigoPre": 5400101788,
        "codigoSub": "1",
        "nombre": "MARIA ALEXANDRA FUENTES TROYA"
    },
    {
        "codigoPre": 5400101791,
        "codigoSub": "1",
        "nombre": "Upgds FIGURA`S SPA CUCUTA S.A.S"
    },
    {
        "codigoPre": 5400101792,
        "codigoSub": "1",
        "nombre": "CLAUDIA YAMILE RUIZ VASQUEZ"
    },
    {
        "codigoPre": 5400101793,
        "codigoSub": "1",
        "nombre": "NURIA ESPERANZA VERGEL TORRENTS"
    },
    {
        "codigoPre": 5400101801,
        "codigoSub": "1",
        "nombre": "CIA DE NEUROLOGOS.NEUROCIRUJANOS Y ESPECIALIDADES AFINES, SOCIEDAD ACCIONES SIMPLIF CONEURO S.A.S."
    },
    {
        "codigoPre": 5400101803,
        "codigoSub": "1",
        "nombre": "SIMETRIC S.A."
    },
    {
        "codigoPre": 5400101806,
        "codigoSub": "1",
        "nombre": "CARLOS AUGUSTO SARMIENTO RIVEROS"
    },
    {
        "codigoPre": 5400101810,
        "codigoSub": "1",
        "nombre": "GABRIEL ARTURO LOBO DIAZ"
    },
    {
        "codigoPre": 5400101812,
        "codigoSub": "1",
        "nombre": "CLAUDIA CASTILLO MORENO"
    },
    {
        "codigoPre": 5400101813,
        "codigoSub": "1",
        "nombre": "Ana Yancy Perez Rivera"
    },
    {
        "codigoPre": 5400101817,
        "codigoSub": "1",
        "nombre": "CARDIOLOGIA DIAGNOSTICA DEL NORTE S.A.S.- CARDINOR S.A.S."
    },
    {
        "codigoPre": 5400101818,
        "codigoSub": "1",
        "nombre": "NEUROCOOP REHABILITACION FISICA Y MEDICA INTEGRAL"
    },
    {
        "codigoPre": 5400101827,
        "codigoSub": "1",
        "nombre": "GINA PAOLA CHAVEZ DURAN"
    },
    {
        "codigoPre": 5400101831,
        "codigoSub": "1",
        "nombre": "SOCIEDAD CLINICA MACHICADO SAS"
    },
    {
        "codigoPre": 5400101834,
        "codigoSub": "1",
        "nombre": "ORALX CUCUTA LTDA"
    },
    {
        "codigoPre": 5400101840,
        "codigoSub": "1",
        "nombre": "PIEL MEDICALSPA CENTRO DERMATOLOGICO Upgds SAS"
    },
    {
        "codigoPre": 5400101850,
        "codigoSub": "1",
        "nombre": "JOHANNA PAOLA CARDENAS CARDENAS"
    },
    {
        "codigoPre": 5400101862,
        "codigoSub": "1",
        "nombre": "CLINICA ABC ORTODONCIA SAS"
    },
    {
        "codigoPre": 5400101865,
        "codigoSub": "1",
        "nombre": "liliana marlenn osorio archila"
    },
    {
        "codigoPre": 5400101868,
        "codigoSub": "1",
        "nombre": "SOCIEDAD DE OFTALMOLOGIA Y CIRUGIA PLASTICA DE CUCUTA S.A."
    },
    {
        "codigoPre": 5400101869,
        "codigoSub": "1",
        "nombre": "Servicio Nacional de Aprendizaje SENA"
    },
    {
        "codigoPre": 5400101872,
        "codigoSub": "1",
        "nombre": "Upgds MEDCARE DE COLOMBIA S.A.S."
    },
    {
        "codigoPre": 5400101880,
        "codigoSub": "1",
        "nombre": "Upgds REHABILITACION INTEGRAL MARTA OMAC\u0011A ASOCIADOS S.A.S"
    },
    {
        "codigoPre": 5400101881,
        "codigoSub": "1",
        "nombre": "JORGE JOSE MIREP CORONA"
    },
    {
        "codigoPre": 5400101882,
        "codigoSub": "1",
        "nombre": "CENTRO ESPECIALIZADO DE DIAGNOSTICO MATERNO INFANTIL I.P.S. SAS"
    },
    {
        "codigoPre": 5400101887,
        "codigoSub": "1",
        "nombre": "JAVIER GIOVANNI JIMENEZ DUARTE"
    },
    {
        "codigoPre": 5400101891,
        "codigoSub": "1",
        "nombre": "ALBERTO OCHOA GOVIN"
    },
    {
        "codigoPre": 5400101895,
        "codigoSub": "1",
        "nombre": "Judith Rodas Mancera"
    },
    {
        "codigoPre": 5400101900,
        "codigoSub": "1",
        "nombre": "MARIA DEL MAR DE JESUS URIBE CANAL"
    },
    {
        "codigoPre": 5400101913,
        "codigoSub": "1",
        "nombre": "optica cientifica Y cia ltda"
    },
    {
        "codigoPre": 5400101915,
        "codigoSub": "1",
        "nombre": "EL PRINCIPIO DE UNA ESPERANZA"
    },
    {
        "codigoPre": 5400101916,
        "codigoSub": 3,
        "nombre": "Upgds ASSOT S.A.S."
    },
    {
        "codigoPre": 5400101918,
        "codigoSub": "1",
        "nombre": "TU BOCA BELLA S.A.S"
    },
    {
        "codigoPre": 5400101919,
        "codigoSub": "1",
        "nombre": "LEIBNIZ PEC\u0011ARANDA ORTIZ"
    },
    {
        "codigoPre": 5400101933,
        "codigoSub": "1",
        "nombre": "beatriz cecilia maldonado villamizar"
    },
    {
        "codigoPre": 5400101939,
        "codigoSub": "1",
        "nombre": "LUDY MARINA RAMIREZ ARIAS"
    },
    {
        "codigoPre": 5400101941,
        "codigoSub": 3,
        "nombre": "INSTITUTO NEUROLOGICO INFANTIL S.A.S."
    },
    {
        "codigoPre": 5400101947,
        "codigoSub": "1",
        "nombre": "ORGANIZACIC\u0013N VIHONCO Upgds SAS CC\u001aCUTA"
    },
    {
        "codigoPre": 5400101948,
        "codigoSub": "1",
        "nombre": "AUDIOCOM CUCUTA"
    },
    {
        "codigoPre": 5400101949,
        "codigoSub": "1",
        "nombre": "UBA VIHONCO SAS"
    },
    {
        "codigoPre": 5400101952,
        "codigoSub": "1",
        "nombre": "sandra milena mora ascencio"
    },
    {
        "codigoPre": 5400101956,
        "codigoSub": "1",
        "nombre": "GERSON GIOVANNY PARIS SALAZAR"
    },
    {
        "codigoPre": 5400101959,
        "codigoSub": "1",
        "nombre": "CLAUDIA LILIANA GOMEZ MARTINEZ"
    },
    {
        "codigoPre": 5400101962,
        "codigoSub": "1",
        "nombre": "Marjory Luz Bautista Capacho"
    },
    {
        "codigoPre": 5400101965,
        "codigoSub": "1",
        "nombre": "amparo grosso bonilla"
    },
    {
        "codigoPre": 5400101968,
        "codigoSub": "1",
        "nombre": "SHESSY ALEXANDRA BENAVIDES LOPEZ"
    },
    {
        "codigoPre": 5400101975,
        "codigoSub": "1",
        "nombre": "ALEJANDRA MARIA ESCALANTE RODRIGUEZ"
    },
    {
        "codigoPre": 5400101987,
        "codigoSub": "1",
        "nombre": "LINEY ISABEL BARRERA ZAPA"
    },
    {
        "codigoPre": 5400101996,
        "codigoSub": "1",
        "nombre": "DANIEL QUINTERO QUINTERO"
    },
    {
        "codigoPre": 5400102002,
        "codigoSub": "1",
        "nombre": "SERVICIOS VIVIR S.A.S."
    },
    {
        "codigoPre": 5400102004,
        "codigoSub": "1",
        "nombre": "CLINICA NEUMOLOGICA Y DEL SUEC\u0011O S.A.S"
    },
    {
        "codigoPre": 5400102005,
        "codigoSub": "1",
        "nombre": "Salud Empresarial Upgds S.A.S"
    },
    {
        "codigoPre": 5400102008,
        "codigoSub": "1",
        "nombre": "JOSE ARMANDO CARRILLO MENDOZA"
    },
    {
        "codigoPre": 5400102010,
        "codigoSub": "1",
        "nombre": "FUNDACION INSTITUCION PRESTADORA DE SALUD SUEC\u0011OS DE COLORES"
    },
    {
        "codigoPre": 5400102013,
        "codigoSub": "1",
        "nombre": "BIENESTAR CLINICAS LASER DE MEDICINA ALEMANA, DISMETABOLISMO Y DOLOR S.A.S."
    },
    {
        "codigoPre": 5400102016,
        "codigoSub": "1",
        "nombre": "MAIRA ALEJANDRA MELO JAIMES"
    },
    {
        "codigoPre": 5400102018,
        "codigoSub": "1",
        "nombre": "JOSE DUVIAN ANGULO CONTRERAS"
    },
    {
        "codigoPre": 5400102019,
        "codigoSub": "1",
        "nombre": "ILCE LORENA RINCON GARCIA"
    },
    {
        "codigoPre": 5400102020,
        "codigoSub": "1",
        "nombre": "ALFREDO BAUTISTA GARCIA"
    },
    {
        "codigoPre": 5400102022,
        "codigoSub": "1",
        "nombre": "rosa gertrudis assia caballero"
    },
    {
        "codigoPre": 5400102024,
        "codigoSub": "1",
        "nombre": "GISELA DEL PILAR PUENTES BUENDIA"
    },
    {
        "codigoPre": 5400102032,
        "codigoSub": "1",
        "nombre": "JAVIER ORLANDO PRIETO PEC\u0011A"
    },
    {
        "codigoPre": 5400102033,
        "codigoSub": "1",
        "nombre": "WILSON ARELIO CACERES VELASQUEZ"
    },
    {
        "codigoPre": 5400102036,
        "codigoSub": "1",
        "nombre": "KARLA BARRETO ROJAS"
    },
    {
        "codigoPre": 5400102039,
        "codigoSub": "1",
        "nombre": "Gerson Didier Guarin"
    },
    {
        "codigoPre": 5400102042,
        "codigoSub": "1",
        "nombre": "DIEGO ANDRES BLANCO FUENTES"
    },
    {
        "codigoPre": 5400102049,
        "codigoSub": "1",
        "nombre": "MEDICINA INTEGRAL MIKEL S.A.S."
    },
    {
        "codigoPre": 5400102050,
        "codigoSub": "1",
        "nombre": "Unidad Movil de Emergencias Medicas Vitales S.A.S"
    },
    {
        "codigoPre": 5400102053,
        "codigoSub": "1",
        "nombre": "EDGAR ALEXIS DIAZ CAMARGO"
    },
    {
        "codigoPre": 5400102058,
        "codigoSub": "1",
        "nombre": "carlos eduardo rincon palechor"
    },
    {
        "codigoPre": 5400102065,
        "codigoSub": "1",
        "nombre": "I.P.S. SANTA CATALINA CLINICA DE HERIDAS Y OSTOMIAS S.A.S."
    },
    {
        "codigoPre": 5400102066,
        "codigoSub": 2,
        "nombre": "FUTUMEDICA PLUS NS NB: 2"
    },
    {
        "codigoPre": 5400102068,
        "codigoSub": "1",
        "nombre": "Manuel Ernesto RiaC1o GarzC3n"
    },
    {
        "codigoPre": 5400102079,
        "codigoSub": "1",
        "nombre": "INESO LTDA"
    },
    {
        "codigoPre": 5400102081,
        "codigoSub": "1",
        "nombre": "RAMON ELIAS PATIC\u0011O GUERRERO"
    },
    {
        "codigoPre": 5400102085,
        "codigoSub": "1",
        "nombre": "CERTINTEGRAL CUCUTA"
    },
    {
        "codigoPre": 5400102088,
        "codigoSub": 9,
        "nombre": "ORGANIZACION LADMEDIS S.A.S."
    },
    {
        "codigoPre": 5400102091,
        "codigoSub": "1",
        "nombre": "LERRY ADOLFO HERNANDEZ CASTAC\u0011O"
    },
    {
        "codigoPre": 5400102094,
        "codigoSub": 2,
        "nombre": "SINERGIA GLOBAL EN SALUD SAS"
    },
    {
        "codigoPre": 5400102100,
        "codigoSub": "1",
        "nombre": "elizabeth zuluaga castro"
    },
    {
        "codigoPre": 5400102101,
        "codigoSub": "1",
        "nombre": "MARYEN TERESA DURAN BOTELLO"
    },
    {
        "codigoPre": 5400102102,
        "codigoSub": "1",
        "nombre": "JAIRO ANTONIO FIGUEROA MELGAREJO"
    },
    {
        "codigoPre": 5400102103,
        "codigoSub": "1",
        "nombre": "RONALD GUTIERREZ RIVERA"
    },
    {
        "codigoPre": 5400102107,
        "codigoSub": "1",
        "nombre": "INGRID JOHANNA GARCIA SILVA"
    },
    {
        "codigoPre": 5400102108,
        "codigoSub": "1",
        "nombre": "SANDRA LILIANA FLOREZ MUC\u0011OZ"
    },
    {
        "codigoPre": 5400102110,
        "codigoSub": "1",
        "nombre": "COLON-GASTRO INSTITUTO I.P.S. Y SERVICIOS MEDICOS LIMITADA"
    },
    {
        "codigoPre": 5400102111,
        "codigoSub": "1",
        "nombre": "ADDY ZENAIDA PEDROZA RANGEL"
    },
    {
        "codigoPre": 5400102112,
        "codigoSub": "1",
        "nombre": "CENTRO DE IMAGENOLOGIA Y LABORATORIO CLINICO CEIMLAB SAS"
    },
    {
        "codigoPre": 5400102113,
        "codigoSub": "1",
        "nombre": "CLINICA URGENCIAS LA MERCED S.A.S"
    },
    {
        "codigoPre": 5400102115,
        "codigoSub": "1",
        "nombre": "NEUROALIADOS MEDICINA ESPECIALIZADA S.A.S."
    },
    {
        "codigoPre": 5400102120,
        "codigoSub": "1",
        "nombre": "DIAGNOSIS ORAL E.U."
    },
    {
        "codigoPre": 5400102121,
        "codigoSub": "1",
        "nombre": "SCIRE CLINICAL DIAGNOSIS"
    },
    {
        "codigoPre": 5400102125,
        "codigoSub": "1",
        "nombre": "ORLANDO ALEXANDER CLAVIJO CACERES"
    },
    {
        "codigoPre": 5400102127,
        "codigoSub": "1",
        "nombre": "Upgds SINSA EMERGENCIAS SAS"
    },
    {
        "codigoPre": 5400102129,
        "codigoSub": "1",
        "nombre": "CENTRO INTEGRAL DE ATENCION DIAGNOSTICA ESPECIALIZADA"
    },
    {
        "codigoPre": 5400102135,
        "codigoSub": "1",
        "nombre": "JIMMY JHON SALCEDO BARRETO"
    },
    {
        "codigoPre": 5400102138,
        "codigoSub": "1",
        "nombre": "EUREKA CLUB DE APRENDIZAJE S.A.S"
    },
    {
        "codigoPre": 5400102139,
        "codigoSub": "1",
        "nombre": "suvision ips 20/20 sas"
    },
    {
        "codigoPre": 5400102142,
        "codigoSub": "1",
        "nombre": "LUIS IGNACIO SANIN JURADO"
    },
    {
        "codigoPre": 5400102145,
        "codigoSub": "1",
        "nombre": "VACUNORTE Upgds SAS"
    },
    {
        "codigoPre": 5400102147,
        "codigoSub": "1",
        "nombre": "ADMINISTRACION DE MODELOS ESPECIALES EN SALUD SAS"
    },
    {
        "codigoPre": 5400102151,
        "codigoSub": "1",
        "nombre": "AURA JAZMIN MARTINEZ MARTINEZ"
    },
    {
        "codigoPre": 5400102152,
        "codigoSub": "1",
        "nombre": "Ivonne MarC-a Quintero PeC1aranda"
    },
    {
        "codigoPre": 5400102162,
        "codigoSub": "1",
        "nombre": "ANGIOVASCULAR MORENO"
    },
    {
        "codigoPre": 5400102165,
        "codigoSub": "1",
        "nombre": "LABORATORIO CLINICO CORREAL ZUC\u0011IGA S.A.S."
    },
    {
        "codigoPre": 5400102169,
        "codigoSub": "1",
        "nombre": "ELKIN DANIEL VERGEL PACHECO"
    },
    {
        "codigoPre": 5400102173,
        "codigoSub": "1",
        "nombre": "SEGURIDAD Y SALUD LABORAL INTEGRAL Upgds SAS"
    },
    {
        "codigoPre": 5400102174,
        "codigoSub": "1",
        "nombre": "OPTICA LINDAVISION Upgds S.A.S."
    },
    {
        "codigoPre": 5400102177,
        "codigoSub": "1",
        "nombre": "CONSULTORIO ODOMED RX S.A.S."
    },
    {
        "codigoPre": 5400102179,
        "codigoSub": "1",
        "nombre": "JESUS IVAN SIERRA LAGUADO"
    },
    {
        "codigoPre": 5400102182,
        "codigoSub": "1",
        "nombre": "XIMENA CARDENAS ACEVEDO"
    },
    {
        "codigoPre": 5400102185,
        "codigoSub": "1",
        "nombre": "MARGARITA RUTH YAC\u0011EZ MOSQUERA"
    },
    {
        "codigoPre": 5400102187,
        "codigoSub": "1",
        "nombre": "TOMAS CASTELBLANCO PARDO"
    },
    {
        "codigoPre": 5400102189,
        "codigoSub": "1",
        "nombre": "SERVIMOS AMBULANCIAS DE COLOMBIA Upgds S.A.S."
    },
    {
        "codigoPre": 5400102193,
        "codigoSub": "1",
        "nombre": "BMGC CUCUTA"
    },
    {
        "codigoPre": 5400102194,
        "codigoSub": "1",
        "nombre": "LIZBETH AZUCENA GOMEZ BECERRA"
    },
    {
        "codigoPre": 5400102196,
        "codigoSub": "1",
        "nombre": "PRINSO I.P.S SAS"
    },
    {
        "codigoPre": 5400102197,
        "codigoSub": "1",
        "nombre": "CENTRO DE REHABILITACION FISICO Y NEUROLOGICO CON-SENTIDOS S.A.S."
    },
    {
        "codigoPre": 5400102198,
        "codigoSub": "1",
        "nombre": "DIANA MILENA MORENO FIGUEREDO"
    },
    {
        "codigoPre": 5400102204,
        "codigoSub": "1",
        "nombre": "JANETH ZORAYA RANGEL LOPEZ"
    },
    {
        "codigoPre": 5400102209,
        "codigoSub": "1",
        "nombre": "Aeropuertos de Oriente SAS"
    },
    {
        "codigoPre": 5400102210,
        "codigoSub": "1",
        "nombre": "diana del pilar villamizar bacca"
    },
    {
        "codigoPre": 5400102217,
        "codigoSub": "1",
        "nombre": "CODIGO AZUL MEDICINA EN SU HOGAR S.A.S."
    },
    {
        "codigoPre": 5400102219,
        "codigoSub": "1",
        "nombre": "SAMUEL ENRIQUE BAUTISTA VARGAS"
    },
    {
        "codigoPre": 5400102223,
        "codigoSub": "1",
        "nombre": "JENNY PAOLA DIAZ CASTILLO"
    },
    {
        "codigoPre": 5400102225,
        "codigoSub": "1",
        "nombre": "EVENITH ZAMBRANO ORTIZ"
    },
    {
        "codigoPre": 5400102226,
        "codigoSub": "1",
        "nombre": "YEZMIN ELIME ABRAJIM RINCON"
    },
    {
        "codigoPre": 5400102232,
        "codigoSub": "1",
        "nombre": "Ivy Andrea Contreras Villamizar"
    },
    {
        "codigoPre": 5400102234,
        "codigoSub": "1",
        "nombre": "ANGEL MIGUEL JAIMES CUBEROS"
    },
    {
        "codigoPre": 5400102235,
        "codigoSub": "1",
        "nombre": "JOSE ORLANDO ARIAS ROJAS"
    },
    {
        "codigoPre": 5400102245,
        "codigoSub": "1",
        "nombre": "NOHORA XIMENA MONSALVE QUINTERO"
    },
    {
        "codigoPre": 5400102248,
        "codigoSub": "1",
        "nombre": "JORGE RICARDO GRANADOS QUIC\u0011ONES"
    },
    {
        "codigoPre": 5400102250,
        "codigoSub": "1",
        "nombre": "ELIZABETH SUAREZ MARIN"
    },
    {
        "codigoPre": 5400102251,
        "codigoSub": "1",
        "nombre": "CENTRO DE REPRODUCCION Y SALUD DE LA MUJER S.A.S."
    },
    {
        "codigoPre": 5400102255,
        "codigoSub": "1",
        "nombre": "JORGE JAVIER GOMEZ BALLESTEROS"
    },
    {
        "codigoPre": 5400102256,
        "codigoSub": 6,
        "nombre": "JUAN CARLOS FLOREZ"
    },
    {
        "codigoPre": 5400102261,
        "codigoSub": "1",
        "nombre": "Leonardo Mauricio Manjarres De La Ossa"
    },
    {
        "codigoPre": 5400102262,
        "codigoSub": "1",
        "nombre": "CARLOS EDUARDO CACERES RINCC\u0013N"
    },
    {
        "codigoPre": 5400102263,
        "codigoSub": "1",
        "nombre": "CRC MEDICARR S.A.S."
    },
    {
        "codigoPre": 5400102264,
        "codigoSub": "1",
        "nombre": "ADRIANA ISABEL ROPERO YAC\u0011EZ"
    },
    {
        "codigoPre": 5400102267,
        "codigoSub": "1",
        "nombre": "CENTRO INTEGRAL DE REHABILITACION SEGURIDAD Y SALUD PARA EL TRABAJO I.P.S S. A.S."
    },
    {
        "codigoPre": 5400102269,
        "codigoSub": "1",
        "nombre": "DAVITA S.A.S."
    },
    {
        "codigoPre": 5400102271,
        "codigoSub": "1",
        "nombre": "LILIANA MARCELA PINTO RINCON"
    },
    {
        "codigoPre": 5400102272,
        "codigoSub": "1",
        "nombre": "GLOBAL SAFE SALUD S.A.S."
    },
    {
        "codigoPre": 5400102273,
        "codigoSub": "1",
        "nombre": "CENTRO MEDICO OFTALMOLOGICO Y LABORATORIO CLINICO ANDRADE NARVAEZ SIGLA COLCAN S.A.S."
    },
    {
        "codigoPre": 5400102274,
        "codigoSub": "1",
        "nombre": "CLAUDIA GISELA VERA HERNC\u0001NDEZ"
    },
    {
        "codigoPre": 5400102278,
        "codigoSub": "1",
        "nombre": "ANDRES FERNANDO MONTILLA PARRA"
    },
    {
        "codigoPre": 5400102282,
        "codigoSub": "1",
        "nombre": "BIOREUMA S.A.S."
    },
    {
        "codigoPre": 5400102283,
        "codigoSub": "1",
        "nombre": "JOSE LUIS FRANCO CALIXTO"
    },
    {
        "codigoPre": 5400102285,
        "codigoSub": "1",
        "nombre": "UNIDAD DE MEDICINA MATERNOFETAL NORFETUS SOCIEDAD POR ACCIONES SIMPLIFICADA"
    },
    {
        "codigoPre": 5400102293,
        "codigoSub": "1",
        "nombre": "ANDREA MILENA ARIAS GUZMAN"
    },
    {
        "codigoPre": 5400102294,
        "codigoSub": "1",
        "nombre": "Upgds CLINICAL HOUSE S.A.S."
    },
    {
        "codigoPre": 5400102296,
        "codigoSub": "1",
        "nombre": "ANGELA DANIELA MEJIA ACEVEDO"
    },
    {
        "codigoPre": 5400102300,
        "codigoSub": "1",
        "nombre": "CENTRO DE MEDICINA HIPERBARICA Upgds SAS"
    },
    {
        "codigoPre": 5400102301,
        "codigoSub": "1",
        "nombre": "Leonardo Adolfo Mesa Vera"
    },
    {
        "codigoPre": 5400102304,
        "codigoSub": "1",
        "nombre": "JAVIER GONZALO CORONA BUENO"
    },
    {
        "codigoPre": 5400102305,
        "codigoSub": "1",
        "nombre": "carlos amilcar calderon valbuena"
    },
    {
        "codigoPre": 5400102309,
        "codigoSub": "1",
        "nombre": "Jaime Alejandro Beltran PeC1a"
    },
    {
        "codigoPre": 5400102310,
        "codigoSub": "1",
        "nombre": "NORDVITAL Upgds S.A.S."
    },
    {
        "codigoPre": 5400102320,
        "codigoSub": "1",
        "nombre": "FUTURO VISION S.A.S."
    },
    {
        "codigoPre": 5400102327,
        "codigoSub": "1",
        "nombre": "ELIANA ISABEL PRADO SANTAFE"
    },
    {
        "codigoPre": 5400102329,
        "codigoSub": "1",
        "nombre": "DANIEL VELASCO VILLAMIZAR"
    },
    {
        "codigoPre": 5400102331,
        "codigoSub": "1",
        "nombre": "MARYOLI HERRERA MONSALVE"
    },
    {
        "codigoPre": 5400102332,
        "codigoSub": "1",
        "nombre": "CORPORACION DE SERVICIO PASTORAL SOCIAL"
    },
    {
        "codigoPre": 5400102333,
        "codigoSub": "1",
        "nombre": "MEDICINA Y SALUD INTEGRAL PARA LA FAMILIA Upgds SAS"
    },
    {
        "codigoPre": 5400102334,
        "codigoSub": "1",
        "nombre": "Gustavo Adolfo Delgado Sierra"
    },
    {
        "codigoPre": 5400102335,
        "codigoSub": "1",
        "nombre": "JuliC!n YC!C1ez Hartmann"
    },
    {
        "codigoPre": 5400102336,
        "codigoSub": "1",
        "nombre": "Upgds MARIA DE LOS ANGELES S.A.S."
    },
    {
        "codigoPre": 5400102337,
        "codigoSub": "1",
        "nombre": "MARIA ALEXANDRA MADARIAGA LA ROCHE"
    },
    {
        "codigoPre": 5400102338,
        "codigoSub": "1",
        "nombre": "CARLOS OMAR FIGUEREDO DIETTES"
    },
    {
        "codigoPre": 5400102340,
        "codigoSub": "1",
        "nombre": "FUNDACION PIA AUTONOMA ASILO ANDRESEN"
    },
    {
        "codigoPre": 5400102344,
        "codigoSub": "1",
        "nombre": "MARIA CRISTINA QUINTERO MARIC\u0011O"
    },
    {
        "codigoPre": 5400102345,
        "codigoSub": "1",
        "nombre": "serviambulancias del oriente sas"
    },
    {
        "codigoPre": 5400102347,
        "codigoSub": "1",
        "nombre": "JUDIT PATRICIA BOTELLO RIVERA"
    },
    {
        "codigoPre": 5400102348,
        "codigoSub": "1",
        "nombre": "JOHANA ISABEL PORTILLA VILLAMIZAR"
    },
    {
        "codigoPre": 5400102349,
        "codigoSub": "1",
        "nombre": "MEDICAL DUARTE ZF S.A.S"
    },
    {
        "codigoPre": 5400102350,
        "codigoSub": "1",
        "nombre": "FUNDACION CASA DEL DIABETICO FUNCADI"
    },
    {
        "codigoPre": 5400102351,
        "codigoSub": "1",
        "nombre": "RICHARD OSWALDO BELTRAN PINILLA"
    },
    {
        "codigoPre": 5400102353,
        "codigoSub": "1",
        "nombre": "LABORATORIO MEDICO ECHAVARRIA S.A.S."
    },
    {
        "codigoPre": 5400102357,
        "codigoSub": "1",
        "nombre": "AUVIMER SALUD INTEGRAL EN CASA SAS"
    },
    {
        "codigoPre": 5400102358,
        "codigoSub": "1",
        "nombre": "DIANA MARIA OVALLES LUNA"
    },
    {
        "codigoPre": 5400102361,
        "codigoSub": "1",
        "nombre": "GIOVANA DELGADO DELGADO"
    },
    {
        "codigoPre": 5400102365,
        "codigoSub": "1",
        "nombre": "JAIME MAURICIO CLAVIJO CC\u0001CERES"
    },
    {
        "codigoPre": 5400102366,
        "codigoSub": "1",
        "nombre": "Marlyn Paola Cristo Moros"
    },
    {
        "codigoPre": 5400102368,
        "codigoSub": "1",
        "nombre": "DENTIX COLOMBIA SAS"
    },
    {
        "codigoPre": 5400102371,
        "codigoSub": "1",
        "nombre": "MEDICAL PDROZ NORTE DE SANTANDER Upgds SAS"
    },
    {
        "codigoPre": 5400102373,
        "codigoSub": "1",
        "nombre": "YIBER JABID SANCHEZ BAEZ"
    },
    {
        "codigoPre": 5400102375,
        "codigoSub": "1",
        "nombre": "SANATY Upgds S.A.S."
    },
    {
        "codigoPre": 5400102376,
        "codigoSub": "1",
        "nombre": "Upgds BEST HOME CARE SAS"
    },
    {
        "codigoPre": 5400102377,
        "codigoSub": "1",
        "nombre": "LIGIA PORTILLA VILLAMIZAR"
    },
    {
        "codigoPre": 5400102381,
        "codigoSub": "1",
        "nombre": "JUAN CAMILO PAEZ GARCIA"
    },
    {
        "codigoPre": 5400102383,
        "codigoSub": 2,
        "nombre": "andry mayury garcia guaman"
    },
    {
        "codigoPre": 5400102384,
        "codigoSub": "1",
        "nombre": "Gustavo Alberto Vejar Ramirez"
    },
    {
        "codigoPre": 5400102385,
        "codigoSub": "1",
        "nombre": "wilmer mendoza manzano"
    },
    {
        "codigoPre": 5400102387,
        "codigoSub": "1",
        "nombre": "Upgds PROGRESANDO EN SALUD S.A.S"
    },
    {
        "codigoPre": 5400102388,
        "codigoSub": "1",
        "nombre": "DIANA TERESA HERRERA VARGAS"
    },
    {
        "codigoPre": 5400102390,
        "codigoSub": "1",
        "nombre": "GESI SALUD Upgds S.A.S"
    },
    {
        "codigoPre": 5400102393,
        "codigoSub": "1",
        "nombre": "luz marina delgado ramirez"
    },
    {
        "codigoPre": 5400102394,
        "codigoSub": "1",
        "nombre": "VAZKEN NERSES ABOUDJDIAN SANTOS"
    },
    {
        "codigoPre": 5400102395,
        "codigoSub": "1",
        "nombre": "RONNY FABIAN ANGEL OROZCO"
    },
    {
        "codigoPre": 5400102396,
        "codigoSub": "1",
        "nombre": "LABORATORIO CLC\rNICO ESPECIALIZADO CARLOS MONTOYA & SUAREZ S.A.S."
    },
    {
        "codigoPre": 5400102398,
        "codigoSub": "1",
        "nombre": "Elkin Mauricio Blanco Clavijo"
    },
    {
        "codigoPre": 5400102405,
        "codigoSub": "1",
        "nombre": "ENDOCRINORTE SAS"
    },
    {
        "codigoPre": 5400102408,
        "codigoSub": "1",
        "nombre": "CAROLINA GONZALEZ GARCIA"
    },
    {
        "codigoPre": 5400102410,
        "codigoSub": "1",
        "nombre": "Jhon Jairo Ojeda Manrique"
    },
    {
        "codigoPre": 5400102411,
        "codigoSub": "1",
        "nombre": "Ruan Rehacer & Cia SAS"
    },
    {
        "codigoPre": 5400102413,
        "codigoSub": "1",
        "nombre": "CENTRO DE EXPERTOS PARA LA ATENCION INTEGRAL Upgds SAS"
    },
    {
        "codigoPre": 5400102415,
        "codigoSub": "1",
        "nombre": "BETZADIA INES JUDEX BALAGUERA"
    },
    {
        "codigoPre": 5400102416,
        "codigoSub": "1",
        "nombre": "MEDMOVIL S.A.S."
    },
    {
        "codigoPre": 5400102418,
        "codigoSub": "1",
        "nombre": "INSTITUCION PRESTADORA DE SERVICIOS DE SALUD DOMICILIARIOS SANARTE CLC\rNICA DE HERIDAS Y OSTOMIAS S.A.S."
    },
    {
        "codigoPre": 5400102422,
        "codigoSub": "1",
        "nombre": "INSTITUTO CASA HOGAR NIC\u0011OS VIDA PLENA"
    },
    {
        "codigoPre": 5400102423,
        "codigoSub": "1",
        "nombre": "NORA LILIANA ESCALANTE JIMENEZ"
    },
    {
        "codigoPre": 5400102424,
        "codigoSub": "1",
        "nombre": "JORGE ENRIQUE CORONA AGUDELO"
    },
    {
        "codigoPre": 5400102425,
        "codigoSub": "1",
        "nombre": "adriana vivas araque"
    },
    {
        "codigoPre": 5400102427,
        "codigoSub": "1",
        "nombre": "JESUS ANTONIO ALVAREZ ANGARITA"
    },
    {
        "codigoPre": 5400102428,
        "codigoSub": "1",
        "nombre": "ANDREA TATIANA ALBARRACIN ORTIZ"
    },
    {
        "codigoPre": 5400102429,
        "codigoSub": "1",
        "nombre": "jhon jose cordero ramos"
    },
    {
        "codigoPre": 5400102430,
        "codigoSub": "1",
        "nombre": "elpidio asprilla guerrero"
    },
    {
        "codigoPre": 5400102432,
        "codigoSub": "1",
        "nombre": "LADY KATHERINE BARRETO ROJAS"
    },
    {
        "codigoPre": 5400102442,
        "codigoSub": "1",
        "nombre": "SALUD SOCIAL SOCIEDAD POR ACCIONES SIMPLIFICADA"
    },
    {
        "codigoPre": 5400102443,
        "codigoSub": "1",
        "nombre": "ADRIANA CRISTINA MILLAN RINCON"
    },
    {
        "codigoPre": 5400102444,
        "codigoSub": "1",
        "nombre": "JUAN FERNANDO QUIC\u0011ONEZ CHAHIN"
    },
    {
        "codigoPre": 5400102445,
        "codigoSub": "1",
        "nombre": "FADDY LUCIA GRATEROL TORRES"
    },
    {
        "codigoPre": 5400102446,
        "codigoSub": "1",
        "nombre": "Rodolfo AndrC)s Jauregui Ojeda"
    },
    {
        "codigoPre": 5400102449,
        "codigoSub": "1",
        "nombre": "MEDISALUD CONSULTORIAS S.A.S"
    },
    {
        "codigoPre": 5400102450,
        "codigoSub": "1",
        "nombre": "Valmore Alberto Morles Contreras"
    },
    {
        "codigoPre": 5400102451,
        "codigoSub": "1",
        "nombre": "MARISOL PANTALEON PINTO"
    },
    {
        "codigoPre": 5400102452,
        "codigoSub": "1",
        "nombre": "ROSSANA CALDERON GARCIA"
    },
    {
        "codigoPre": 5400102454,
        "codigoSub": "1",
        "nombre": "servicios de salud Upgds Suramericana S.A."
    },
    {
        "codigoPre": 5400102455,
        "codigoSub": "1",
        "nombre": "CAMILO ANDRES ROZO NIEVES"
    },
    {
        "codigoPre": 5400102456,
        "codigoSub": 0,
        "nombre": "JESSICA VIVIANA HERRERA RIVERA"
    },
    {
        "codigoPre": 5400102457,
        "codigoSub": "1",
        "nombre": "TATIANA SOFIA NUC\u0011EZ SARMIENTO"
    },
    {
        "codigoPre": 5400102458,
        "codigoSub": "1",
        "nombre": "diana carolina bustamante bermudez"
    },
    {
        "codigoPre": 5400102459,
        "codigoSub": "1",
        "nombre": "Diagnostico y Asistencia Medica SAS Institucion Prestadora de Servicios de Salud"
    },
    {
        "codigoPre": 5400102461,
        "codigoSub": "1",
        "nombre": "ANGEL ANTONIO SALAS LOPEZ"
    },
    {
        "codigoPre": 5400102462,
        "codigoSub": "1",
        "nombre": "adriana marcela romero pardo"
    },
    {
        "codigoPre": 5400102463,
        "codigoSub": "1",
        "nombre": "YUDY TATIANA MEDINA RODRIGUEZ"
    },
    {
        "codigoPre": 5400102464,
        "codigoSub": "1",
        "nombre": "raquel rueda mancipe"
    },
    {
        "codigoPre": 5400102465,
        "codigoSub": "1",
        "nombre": "JUAN ALBERTO BITAR MEJIA"
    },
    {
        "codigoPre": 5400102467,
        "codigoSub": "1",
        "nombre": "nelly del rosario osorio barbosa"
    },
    {
        "codigoPre": 5400102468,
        "codigoSub": "1",
        "nombre": "JOSE MIGUEL GOMEZ VILLAMIZAR"
    },
    {
        "codigoPre": 5400102472,
        "codigoSub": "1",
        "nombre": "MEDICINAS Y TERAPIAS, ATENCION DOMICILIARIA MEDITAD Upgds S.A.S."
    },
    {
        "codigoPre": 5400102475,
        "codigoSub": "1",
        "nombre": "Upgds REHABILIT SPORT SAS"
    },
    {
        "codigoPre": 5400102476,
        "codigoSub": "1",
        "nombre": "LUIS MANUEL MUC\u0011OZ RUIZ"
    },
    {
        "codigoPre": 5400102477,
        "codigoSub": "1",
        "nombre": "CorporaciC3n Juventud por la SuperaciC3n JUPSU"
    },
    {
        "codigoPre": 5400102479,
        "codigoSub": "1",
        "nombre": "LUIS RODRIGO CASTILLO ROJAS"
    },
    {
        "codigoPre": 5400102480,
        "codigoSub": "1",
        "nombre": "ELIANA ISABEL ARMENTA MEDINA"
    },
    {
        "codigoPre": 5400102481,
        "codigoSub": "1",
        "nombre": "EUGENIO HUMBERTO SANCHEZ ARIAS"
    },
    {
        "codigoPre": 5400102482,
        "codigoSub": "1",
        "nombre": "Juan Carlos Martinez Gutierrez"
    },
    {
        "codigoPre": 5400102483,
        "codigoSub": "1",
        "nombre": "ALEXANDER AREVALO TOSCANO"
    },
    {
        "codigoPre": 5400102484,
        "codigoSub": "1",
        "nombre": "FRANCISCO JAVIER CAMERO VERA"
    },
    {
        "codigoPre": 5400102485,
        "codigoSub": "1",
        "nombre": "PEDRO JOSE LUNA PRADA"
    },
    {
        "codigoPre": 5400102487,
        "codigoSub": "1",
        "nombre": "JONATHAN FABIAN CARRERO ORTEGA"
    },
    {
        "codigoPre": 5400102488,
        "codigoSub": "1",
        "nombre": "UNIVERSIDAD LIBRE"
    },
    {
        "codigoPre": 5400102489,
        "codigoSub": "1",
        "nombre": "JAIME ALEXANDER CAMACHO CASTRO"
    },
    {
        "codigoPre": 5400102491,
        "codigoSub": 2,
        "nombre": "Liliana Beltran Franco"
    },
    {
        "codigoPre": 5400102492,
        "codigoSub": "1",
        "nombre": "NATALY ADRIANA SANCHEZ MANTILLA"
    },
    {
        "codigoPre": 5400102494,
        "codigoSub": "1",
        "nombre": "CIRARTI S.A.S."
    },
    {
        "codigoPre": 5400102495,
        "codigoSub": "1",
        "nombre": "Cesar Augusto Pedraza AcuC1a"
    },
    {
        "codigoPre": 5400102496,
        "codigoSub": "1",
        "nombre": "MEDINORTE CC\u001aCUTA Upgds S.A.S."
    },
    {
        "codigoPre": 5400102497,
        "codigoSub": "1",
        "nombre": "YURLEY KARINA DIAZ TORRADO"
    },
    {
        "codigoPre": 5400102498,
        "codigoSub": "1",
        "nombre": "Andrea Paola Cardenas Villamizar"
    },
    {
        "codigoPre": 5400102500,
        "codigoSub": "1",
        "nombre": "PROTECCION INTEGRAL EN SALUD Y RIESGOS S.A.S."
    },
    {
        "codigoPre": 5400102501,
        "codigoSub": "1",
        "nombre": "Red Global Medical S.A.S"
    },
    {
        "codigoPre": 5400102502,
        "codigoSub": "1",
        "nombre": "Dental Health Plus SAS"
    },
    {
        "codigoPre": 5400102503,
        "codigoSub": "1",
        "nombre": "Paola Alexandra Laguado"
    },
    {
        "codigoPre": 5400102504,
        "codigoSub": "1",
        "nombre": "GABRIEL ALBERTO RINCON CLAVIJO"
    },
    {
        "codigoPre": 5400102505,
        "codigoSub": "1",
        "nombre": "MIGUEL FABIAN MANTILLA DURAN"
    },
    {
        "codigoPre": 5400102506,
        "codigoSub": "1",
        "nombre": "JACQUELINE LAGUADO GONZALEZ"
    },
    {
        "codigoPre": 5400102509,
        "codigoSub": "1",
        "nombre": "Carmen Diana Rangel Bonilla"
    },
    {
        "codigoPre": 5400102510,
        "codigoSub": "1",
        "nombre": "DENNIS ROSARIO DELGADO BARRERA"
    },
    {
        "codigoPre": 5400102511,
        "codigoSub": "1",
        "nombre": "ALVARO ALONSO ACEVEDO PUERTO"
    },
    {
        "codigoPre": 5400102516,
        "codigoSub": "1",
        "nombre": "ALBA LUCIA RIVERA LOPEZ"
    },
    {
        "codigoPre": 5400102519,
        "codigoSub": "1",
        "nombre": "MARTHA STELLA RAMIREZ ROJAS"
    },
    {
        "codigoPre": 5400102520,
        "codigoSub": "1",
        "nombre": "JAROLD EDUARDO EFFER TAYLOR"
    },
    {
        "codigoPre": 5400102521,
        "codigoSub": "1",
        "nombre": "NEURO NORTE S.A.S."
    },
    {
        "codigoPre": 5400102523,
        "codigoSub": "1",
        "nombre": "PROFESIONALES DE ESPECIALIDADES MEDICAS S.A.S."
    },
    {
        "codigoPre": 5400102524,
        "codigoSub": "1",
        "nombre": "Maria Salome Sierra Giraldo"
    },
    {
        "codigoPre": 5400102525,
        "codigoSub": "1",
        "nombre": "PROVIDA FARMACEUTICA SAS"
    },
    {
        "codigoPre": 5400102529,
        "codigoSub": "1",
        "nombre": "LUISA DELIA SERRANO VARGAS"
    },
    {
        "codigoPre": 5400102536,
        "codigoSub": "1",
        "nombre": "MARY FERNANDA ARROYO CASTILLO"
    },
    {
        "codigoPre": 5400102537,
        "codigoSub": "1",
        "nombre": "DIANA JASBLEYDI PULIDO VARON"
    },
    {
        "codigoPre": 5400102538,
        "codigoSub": "1",
        "nombre": "HIPERBARICAS CUCUTA Upgds S.A.S."
    },
    {
        "codigoPre": 5400102539,
        "codigoSub": "1",
        "nombre": "ECOIMAGEN SALUD S.A.S."
    },
    {
        "codigoPre": 5400102540,
        "codigoSub": "1",
        "nombre": "GIOVANNA CATHERINE ARAQUE ALARCON"
    },
    {
        "codigoPre": 5400102541,
        "codigoSub": "1",
        "nombre": "DECCY MILEIDY NAVARRO NAVARRO"
    },
    {
        "codigoPre": 5400102544,
        "codigoSub": "1",
        "nombre": "CENTRO DE RECONOCIMIENTO DE CONDUCTORES ANALIZATE S.A.S"
    },
    {
        "codigoPre": 5400102547,
        "codigoSub": "1",
        "nombre": "UNIDAD DE DIAGNOSTICO S.A.S."
    },
    {
        "codigoPre": 5400102548,
        "codigoSub": "1",
        "nombre": "YORDIS FERNANDEZ GARCIA"
    },
    {
        "codigoPre": 5400102549,
        "codigoSub": "1",
        "nombre": "RODOLFO RAMON LEYVA BARRERO"
    },
    {
        "codigoPre": 5400102550,
        "codigoSub": "1",
        "nombre": "INSTITUTO PARA EL RIESGO CARDIOVASCULAR IRC Upgds SAS"
    },
    {
        "codigoPre": 5400102552,
        "codigoSub": "1",
        "nombre": "diego alejandro santafe garcia"
    },
    {
        "codigoPre": 5400102557,
        "codigoSub": "1",
        "nombre": "VIVIANA ANDREA VENGOECHEA SUAREZ"
    },
    {
        "codigoPre": 5400102558,
        "codigoSub": "1",
        "nombre": "OSCAR ANTONIO PARADA PARADA"
    },
    {
        "codigoPre": 5400102559,
        "codigoSub": "1",
        "nombre": "OSCAR ANDRES PARADA DUARTE"
    },
    {
        "codigoPre": 5400102560,
        "codigoSub": "1",
        "nombre": "Jessica Del Pilar Barroso Soto"
    },
    {
        "codigoPre": 5400102561,
        "codigoSub": "1",
        "nombre": "karen julieth ariza garcia"
    },
    {
        "codigoPre": 5400102562,
        "codigoSub": "1",
        "nombre": "maritza sanchez carvajalino"
    },
    {
        "codigoPre": 5400102563,
        "codigoSub": "1",
        "nombre": "DIRIMO DUGLAN MIRANDA CORREA"
    },
    {
        "codigoPre": 5400102564,
        "codigoSub": "1",
        "nombre": "Consuelo Tovar Triana"
    },
    {
        "codigoPre": 5400102565,
        "codigoSub": "1",
        "nombre": "JAIRO ENRIQUE ARIAS HERNC\u0000NDEZ"
    },
    {
        "codigoPre": 5400102567,
        "codigoSub": "1",
        "nombre": "ANDREA SILVANA CONTRERAS ROJAS"
    },
    {
        "codigoPre": 5400102568,
        "codigoSub": "1",
        "nombre": "YADIRA STELLA CARRILLO CRUZ"
    },
    {
        "codigoPre": 5400102570,
        "codigoSub": "1",
        "nombre": "SUSAN DAYANA DARIAS PERDOMO"
    },
    {
        "codigoPre": 5400102571,
        "codigoSub": "1",
        "nombre": "GREISLY KATHERINNE QUINTERO SOTO"
    },
    {
        "codigoPre": 5400102572,
        "codigoSub": "1",
        "nombre": "ANGELICA RODRIGUEZ GAMEZ"
    },
    {
        "codigoPre": 5400102574,
        "codigoSub": "1",
        "nombre": "diego alexander ramirez puentes"
    },
    {
        "codigoPre": 5400102575,
        "codigoSub": "1",
        "nombre": "LEYDI YULISSA MORENO ALBA"
    },
    {
        "codigoPre": 5400102576,
        "codigoSub": "1",
        "nombre": "EDWIN DAVID RODRIGUEZ SEPULVEDA"
    },
    {
        "codigoPre": 5400102577,
        "codigoSub": "1",
        "nombre": "LABORATORIO BIOIMAGEN SOCIEDAD LIMITADA"
    },
    {
        "codigoPre": 5400102578,
        "codigoSub": "1",
        "nombre": "JESSICA KATHERINE GOMEZ GONZALEZ"
    },
    {
        "codigoPre": 5400102579,
        "codigoSub": "1",
        "nombre": "ARLEY FERNANDO BERMEO GUANEME"
    },
    {
        "codigoPre": 5400102581,
        "codigoSub": "1",
        "nombre": "XIMENA MARCELA CERVANTES MEJIA"
    },
    {
        "codigoPre": 5400102582,
        "codigoSub": "1",
        "nombre": "lia briggett calderon lopez"
    },
    {
        "codigoPre": 5400102584,
        "codigoSub": "1",
        "nombre": "Juan Manuel Franco Fernandez"
    },
    {
        "codigoPre": 5400102585,
        "codigoSub": "1",
        "nombre": "ANDREA JULIETH GOMEZ SANCHEZ"
    },
    {
        "codigoPre": 5400102586,
        "codigoSub": "1",
        "nombre": "Lenny Katerine CalderC3n PanchC!"
    },
    {
        "codigoPre": 5400102587,
        "codigoSub": "1",
        "nombre": "ANGELICA MARIA DUQUE LEAL"
    },
    {
        "codigoPre": 5400102588,
        "codigoSub": "1",
        "nombre": "REYNEL ARMANDO GARNICA ALBA"
    },
    {
        "codigoPre": 5400102589,
        "codigoSub": "1",
        "nombre": "RICHARD DANIEL CLARO CEBALLOS"
    },
    {
        "codigoPre": 5400102590,
        "codigoSub": "1",
        "nombre": "MILENA SILVIA VIZCAINO DE LA CRUZ"
    },
    {
        "codigoPre": 5400102591,
        "codigoSub": "1",
        "nombre": "EDWAR ANDRES CAMARGO VANEGAS"
    },
    {
        "codigoPre": 5400102592,
        "codigoSub": "1",
        "nombre": "LISA FERNANDA CASTRO CLAVIJO"
    },
    {
        "codigoPre": 5400102593,
        "codigoSub": "1",
        "nombre": "ELVIA PINTO DURAN"
    },
    {
        "codigoPre": 5400102594,
        "codigoSub": "1",
        "nombre": "PETER HARLEY RODRIGUEZ RINCON"
    },
    {
        "codigoPre": 5400102595,
        "codigoSub": "1",
        "nombre": "ELASER RADIOLOGOS S.A.S."
    },
    {
        "codigoPre": 5400102596,
        "codigoSub": "1",
        "nombre": "Jose Octavio Arnaez Contrera"
    },
    {
        "codigoPre": 5400102598,
        "codigoSub": "1",
        "nombre": "EDWIN SUAREZ FERNANDEZ"
    },
    {
        "codigoPre": 5400102600,
        "codigoSub": "1",
        "nombre": "MARTHA LUCIA FLOREZ NUCIRA"
    },
    {
        "codigoPre": 5400102601,
        "codigoSub": "1",
        "nombre": "NICOL STEFANNY CORREDOR FIGUEREDO"
    },
    {
        "codigoPre": 5400102602,
        "codigoSub": "1",
        "nombre": "SAN LUIS GONZAGA Upgds SAS"
    },
    {
        "codigoPre": 5400102603,
        "codigoSub": "1",
        "nombre": "Adriana patricia barba rueda"
    },
    {
        "codigoPre": 5400102604,
        "codigoSub": "1",
        "nombre": "Claudia Andrea Barrios Rojas"
    },
    {
        "codigoPre": 5400102605,
        "codigoSub": "1",
        "nombre": "MANUEL ESAU ROJAS PARRA"
    },
    {
        "codigoPre": 5400102606,
        "codigoSub": "1",
        "nombre": "MARTHA PATRICIA GARCIA RAMIREZ"
    },
    {
        "codigoPre": 5400102607,
        "codigoSub": "1",
        "nombre": "CENTRO NEUMOLOGICO DEL NORTE SAS"
    },
    {
        "codigoPre": 5400102609,
        "codigoSub": "1",
        "nombre": "Daniela Alexandra Bayona Buitrago"
    },
    {
        "codigoPre": 5400102610,
        "codigoSub": "1",
        "nombre": "alexander gonzalez acevedo"
    },
    {
        "codigoPre": 5400102611,
        "codigoSub": "1",
        "nombre": "MARTHA LUCIA BURBANO RODRIGUEZ"
    },
    {
        "codigoPre": 5400102613,
        "codigoSub": "1",
        "nombre": "erika andrea moreno alvarez"
    },
    {
        "codigoPre": 5400102614,
        "codigoSub": "1",
        "nombre": "JAVIER MAURICIO VARGAS HOYOS"
    },
    {
        "codigoPre": 5400102615,
        "codigoSub": "1",
        "nombre": "JUAN VIANNEY ARIAS SANCHEZ"
    },
    {
        "codigoPre": 5400102616,
        "codigoSub": "1",
        "nombre": "MARTINA ISABEL PRADA PEREZ"
    },
    {
        "codigoPre": 5400102617,
        "codigoSub": "1",
        "nombre": "DIVA HEIDY CASTILLO MEZA"
    },
    {
        "codigoPre": 5400102618,
        "codigoSub": "1",
        "nombre": "ALEXIS FERRALES VERDECIA"
    },
    {
        "codigoPre": 5400102621,
        "codigoSub": "1",
        "nombre": "Dario Antonio Ramirez Vargas"
    },
    {
        "codigoPre": 5400102622,
        "codigoSub": "1",
        "nombre": "Maria del Pilar Jaramillo Ortiz"
    },
    {
        "codigoPre": 5400102623,
        "codigoSub": "1",
        "nombre": "PILAR SOFIA CARDENAS GARCIA"
    },
    {
        "codigoPre": 5400102624,
        "codigoSub": "1",
        "nombre": "Ronald Fabian Guevara Paez"
    },
    {
        "codigoPre": 5400102625,
        "codigoSub": "1",
        "nombre": "Yuri Patricia Jaimes Suarez"
    },
    {
        "codigoPre": 5400102630,
        "codigoSub": "1",
        "nombre": "gerardo enrique jimenez barba"
    },
    {
        "codigoPre": 5400102631,
        "codigoSub": "1",
        "nombre": "Trino neil rincC3n vargas"
    },
    {
        "codigoPre": 5400102632,
        "codigoSub": "1",
        "nombre": "LUZ KARIME PEREZ ALVAREZ"
    },
    {
        "codigoPre": 5400102633,
        "codigoSub": "1",
        "nombre": "JULIANA MENESES PEREZ"
    },
    {
        "codigoPre": 5400102634,
        "codigoSub": "1",
        "nombre": "Ruth Lorena PeC1aranda Gomez"
    },
    {
        "codigoPre": 5400102635,
        "codigoSub": "1",
        "nombre": "LUZ DIVINA FERNANDEZ CARBAL"
    },
    {
        "codigoPre": 5400102636,
        "codigoSub": "1",
        "nombre": "NELSON GERARDO CASTAC\u0011EDA PABON"
    },
    {
        "codigoPre": 5400102638,
        "codigoSub": "1",
        "nombre": "HARRIS ARIEL PEC\u0011ARANDA MOLINA"
    },
    {
        "codigoPre": 5400102639,
        "codigoSub": "1",
        "nombre": "JEZABETH LOZANO MENESES"
    },
    {
        "codigoPre": 5400102640,
        "codigoSub": "1",
        "nombre": "Alberto Jose Rodriguez Ramirez"
    },
    {
        "codigoPre": 5400102641,
        "codigoSub": "1",
        "nombre": "LEONEL ALIRIO LEAL RANGEL"
    },
    {
        "codigoPre": 5400102642,
        "codigoSub": "1",
        "nombre": "MELISSA HERNANDEZ TORRES"
    },
    {
        "codigoPre": 5400102643,
        "codigoSub": "1",
        "nombre": "ANDREA MILDRED MALDONADO MENDEZ"
    },
    {
        "codigoPre": 5400102646,
        "codigoSub": "1",
        "nombre": "Jaime Alveiro Sierra Hernandez"
    },
    {
        "codigoPre": 5400102648,
        "codigoSub": "1",
        "nombre": "RAFAEL ALFONSO GALVIS MOLINA"
    },
    {
        "codigoPre": 5400102649,
        "codigoSub": "1",
        "nombre": "Servimedig del Norte Ips S.A.S"
    },
    {
        "codigoPre": 5400102650,
        "codigoSub": "1",
        "nombre": "MARIA STEFANIA RIVERA VALENCIA"
    },
    {
        "codigoPre": 5400102651,
        "codigoSub": "1",
        "nombre": "JAVIER ALBERTO ARROYO SOLANO"
    },
    {
        "codigoPre": 5400102652,
        "codigoSub": "1",
        "nombre": "ANGELA MARIA ESTRADA LLANOS"
    },
    {
        "codigoPre": 5400102654,
        "codigoSub": "1",
        "nombre": "Luzardo Alberto Porras Quevedo"
    },
    {
        "codigoPre": 5400102656,
        "codigoSub": "1",
        "nombre": "GRUPO EMERG COLOMBIA S.A.S."
    },
    {
        "codigoPre": 5400102658,
        "codigoSub": "1",
        "nombre": "CAMILO CARLOS GAVIRIA VILLADIEGO"
    },
    {
        "codigoPre": 5400102659,
        "codigoSub": "1",
        "nombre": "DIALY SER SAS"
    },
    {
        "codigoPre": 5400102660,
        "codigoSub": "1",
        "nombre": "SOCIEDAD REPROVID SAS"
    },
    {
        "codigoPre": 5400102661,
        "codigoSub": "1",
        "nombre": "Isabel Cristina PeC1aranda GC3mez"
    },
    {
        "codigoPre": 5400102662,
        "codigoSub": "1",
        "nombre": "ZULMA ESPERANZA URBINA CONTRERAS"
    },
    {
        "codigoPre": 5400102668,
        "codigoSub": "1",
        "nombre": "MARITZA VILLAMIZAR TELLEZ"
    },
    {
        "codigoPre": 5400102669,
        "codigoSub": "1",
        "nombre": "JAIME ALEJANDRO SANCHEZ RIVAS"
    },
    {
        "codigoPre": 5400102670,
        "codigoSub": "1",
        "nombre": "HEIDI JACQUELINE HERNANDEZ LEON"
    },
    {
        "codigoPre": 5400102671,
        "codigoSub": "1",
        "nombre": "NESTOR MICHAEL OLAVE GARCIA"
    },
    {
        "codigoPre": 5400102672,
        "codigoSub": "1",
        "nombre": "MIGUEL ANGEL HERNANDEZ RIVERA"
    },
    {
        "codigoPre": 5400102673,
        "codigoSub": "1",
        "nombre": "ABIGAIL MANTILLA MARTINEZ"
    },
    {
        "codigoPre": 5400102674,
        "codigoSub": "1",
        "nombre": "KATHERINE PULIDO MOLINA"
    },
    {
        "codigoPre": 5400102676,
        "codigoSub": "1",
        "nombre": "Sandra Simona RodrC-guez BeltrC!n"
    },
    {
        "codigoPre": 5400102677,
        "codigoSub": "1",
        "nombre": "Rolando Ivan PeC1aranda Devia"
    },
    {
        "codigoPre": 5400102679,
        "codigoSub": "1",
        "nombre": "morado sas"
    },
    {
        "codigoPre": 5400102681,
        "codigoSub": "1",
        "nombre": "ANGIE LIZETH ARIAS GONZALEZ"
    },
    {
        "codigoPre": 5400102684,
        "codigoSub": "1",
        "nombre": "ROSALBA ANGARITA PINEDA"
    },
    {
        "codigoPre": 5400102685,
        "codigoSub": "1",
        "nombre": "CLINICAS ODONTOLOGICAS DENTTALITY S.A.S."
    },
    {
        "codigoPre": 5400102686,
        "codigoSub": "1",
        "nombre": "Eric Garcia Granados"
    },
    {
        "codigoPre": 5400102687,
        "codigoSub": "1",
        "nombre": "ROMULO ALFONSO DURAN BARON"
    },
    {
        "codigoPre": 5400102688,
        "codigoSub": "1",
        "nombre": "Christiam Antonio Angarita Castilla"
    },
    {
        "codigoPre": 5400102689,
        "codigoSub": "1",
        "nombre": "Mayra Alejandra Rodriguez Carvajal"
    },
    {
        "codigoPre": 5400102692,
        "codigoSub": "1",
        "nombre": "myriam pedraza villamizar"
    },
    {
        "codigoPre": 5400102693,
        "codigoSub": "1",
        "nombre": "Wendy Carolina MartC-nez Remolina"
    },
    {
        "codigoPre": 5400102694,
        "codigoSub": "1",
        "nombre": "CARLOS ALBERTO MEJIA LAGUADO"
    },
    {
        "codigoPre": 5400102697,
        "codigoSub": "1",
        "nombre": "JOSE GREGORIO HERNANDEZ REY"
    },
    {
        "codigoPre": 5400102698,
        "codigoSub": "1",
        "nombre": "EMAD MUSTAFA SALEH ATTALAH"
    },
    {
        "codigoPre": 5400102699,
        "codigoSub": "1",
        "nombre": "GILBERTO AYALA ZAMBRANO"
    },
    {
        "codigoPre": 5400102700,
        "codigoSub": 2,
        "nombre": "HUBERTH FERNANDO VELA LOZADA"
    },
    {
        "codigoPre": 5400102701,
        "codigoSub": "1",
        "nombre": "EDGAR ALBERTO RAMIREZ ROJAS"
    },
    {
        "codigoPre": 5400102702,
        "codigoSub": "1",
        "nombre": "MARYI NUBIA SARMIENTO MEZA"
    },
    {
        "codigoPre": 5400102703,
        "codigoSub": "1",
        "nombre": "I.P.S. CURATEC CLINICA DE HERIDAS Y OSTOMIAS S.A.S."
    },
    {
        "codigoPre": 5400102704,
        "codigoSub": "1",
        "nombre": "Juan Carlos Brahim MuC1oz"
    },
    {
        "codigoPre": 5400102705,
        "codigoSub": "1",
        "nombre": "Kelly Tatianna YaC1ez Ibarra"
    },
    {
        "codigoPre": 5400102707,
        "codigoSub": "1",
        "nombre": "HUGO MAURICIO MARTINEZ VELASCO"
    },
    {
        "codigoPre": 5400102708,
        "codigoSub": "1",
        "nombre": "terapia globlal sas"
    },
    {
        "codigoPre": 5400102710,
        "codigoSub": "1",
        "nombre": "ALIX MARIA TORRES TARSITANO"
    },
    {
        "codigoPre": 5400102711,
        "codigoSub": "1",
        "nombre": "ZAMIR EID PAEZ MOJICA"
    },
    {
        "codigoPre": 5400102712,
        "codigoSub": "1",
        "nombre": "CHRISTIAM RICARDO DAZA CASTELLANOS"
    },
    {
        "codigoPre": 5400102713,
        "codigoSub": "1",
        "nombre": "RODRIGUEZ PEREZ JOSE LUIS"
    },
    {
        "codigoPre": 5400102714,
        "codigoSub": "1",
        "nombre": "yury isabel ibarra castellanos"
    },
    {
        "codigoPre": 5400102715,
        "codigoSub": "1",
        "nombre": "CARLOS ALBERTO GUTIERREZ QUINTERO"
    },
    {
        "codigoPre": 5400102716,
        "codigoSub": "1",
        "nombre": "CARLOS IGNACIO SEPULVEDA CARRERO"
    },
    {
        "codigoPre": 5400102717,
        "codigoSub": "1",
        "nombre": "cesar augusto salazar paez"
    },
    {
        "codigoPre": 5400102718,
        "codigoSub": 2,
        "nombre": "Katerine Maria Gil Leon"
    },
    {
        "codigoPre": 5400102719,
        "codigoSub": "1",
        "nombre": "Astrid Carolina Gutierrez Diaz"
    },
    {
        "codigoPre": 5400102720,
        "codigoSub": "1",
        "nombre": "TOMAS IGNACIO ALVA MARTINEZ"
    },
    {
        "codigoPre": 5400102722,
        "codigoSub": "1",
        "nombre": "miguel antonio parra leal"
    },
    {
        "codigoPre": 5400102723,
        "codigoSub": "1",
        "nombre": "Jesus Alberto Diaz Quintero"
    },
    {
        "codigoPre": 5400102724,
        "codigoSub": "1",
        "nombre": "ANDRES FELIPE MENESES PARRA"
    },
    {
        "codigoPre": 5400102725,
        "codigoSub": "1",
        "nombre": "MUTALIS SAS"
    },
    {
        "codigoPre": 5400102726,
        "codigoSub": "1",
        "nombre": "NeumologC-a y Servicios de RehabilitaciC3n NEUMOSER S.A.S"
    },
    {
        "codigoPre": 5400102727,
        "codigoSub": "1",
        "nombre": "NELLY ROCIO WILCHES RUEDA"
    },
    {
        "codigoPre": 5400102728,
        "codigoSub": "1",
        "nombre": "RAFAEL MARIA LUNA LARA"
    },
    {
        "codigoPre": 5400102729,
        "codigoSub": "1",
        "nombre": "Barbara Ortega De Grandas"
    },
    {
        "codigoPre": 5400102732,
        "codigoSub": "1",
        "nombre": "Isbelia Ortiz Antolinez"
    },
    {
        "codigoPre": 5400102733,
        "codigoSub": "1",
        "nombre": "Margil Melany MontaC1ez Pinto"
    },
    {
        "codigoPre": 5400102734,
        "codigoSub": "1",
        "nombre": "DERLY YURANY caballero ROPERO"
    },
    {
        "codigoPre": 5400102735,
        "codigoSub": "1",
        "nombre": "Upgds CENTRO DE FORMACION EL REENCUENTRO SAS"
    },
    {
        "codigoPre": 5400102736,
        "codigoSub": "1",
        "nombre": "CLINICA ODONTOLOGICA DR. JUAN CAMILO PAEZ S.A.S"
    },
    {
        "codigoPre": 5400102739,
        "codigoSub": 2,
        "nombre": "Leiben johan de jesus Benitez Mojica"
    },
    {
        "codigoPre": 5400102740,
        "codigoSub": "1",
        "nombre": "BETTY LORENA RINCON VARGAS"
    },
    {
        "codigoPre": 5400102741,
        "codigoSub": "1",
        "nombre": "Liliam Johanna Serna Davila"
    },
    {
        "codigoPre": 5400102742,
        "codigoSub": "1",
        "nombre": "CELSO ENRIQUE AVILA PUERTA"
    },
    {
        "codigoPre": 5400102743,
        "codigoSub": "1",
        "nombre": "LAIDY DIANA CONTRERAS VILLAMIZAR"
    },
    {
        "codigoPre": 5400102744,
        "codigoSub": "1",
        "nombre": "ZULMA LIOMARA RIVERA LAGUADO"
    },
    {
        "codigoPre": 5400102746,
        "codigoSub": "1",
        "nombre": "ANDREA KATHERINE ELAM MANOSALVA"
    },
    {
        "codigoPre": 5400102747,
        "codigoSub": "1",
        "nombre": "JOEL ARMANDO CALZADILLA PEREZ"
    },
    {
        "codigoPre": 5400102751,
        "codigoSub": "1",
        "nombre": "javier mauricio gonzalez perez"
    },
    {
        "codigoPre": 5400102752,
        "codigoSub": "1",
        "nombre": "aida yurley oliveros navarro"
    },
    {
        "codigoPre": 5400102754,
        "codigoSub": "1",
        "nombre": "WILSON MUC\u0011OZ SUAREZ"
    },
    {
        "codigoPre": 5400102755,
        "codigoSub": "1",
        "nombre": "SONIA LEONOR RIVERA VEGA"
    },
    {
        "codigoPre": 5400102756,
        "codigoSub": "1",
        "nombre": "Henry Alberto Parra Gutierrez"
    },
    {
        "codigoPre": 5400102758,
        "codigoSub": "1",
        "nombre": "MONICA MARIA MONROY PEREZ"
    },
    {
        "codigoPre": 5400102759,
        "codigoSub": "1",
        "nombre": "GAMAL SALIM PAEZ MOJICA"
    },
    {
        "codigoPre": 5400102762,
        "codigoSub": "1",
        "nombre": "ASTRID CAROLINA LOPEZ GOMEZ"
    },
    {
        "codigoPre": 5400102764,
        "codigoSub": "1",
        "nombre": "ALEXANDER MORENO FIGUEREDO"
    },
    {
        "codigoPre": 5400102765,
        "codigoSub": "1",
        "nombre": "Francy Julieth Gomez Posada"
    },
    {
        "codigoPre": 5400102766,
        "codigoSub": "1",
        "nombre": "OMAR DAVID RESTREPO SANCHEZ"
    },
    {
        "codigoPre": 5400102770,
        "codigoSub": "1",
        "nombre": "VERONICA ANDREA GUTIERREZ NAVARRETE"
    },
    {
        "codigoPre": 5400102772,
        "codigoSub": "1",
        "nombre": "SERGIO ANDRES MARTINEZ ARAQUE"
    },
    {
        "codigoPre": 5400102774,
        "codigoSub": "1",
        "nombre": "MARIA DE LOS ANGELES SARMIENTO QUINTERO"
    },
    {
        "codigoPre": 5400102779,
        "codigoSub": "1",
        "nombre": "Liliana Da Freitas De Andrade"
    },
    {
        "codigoPre": 5400102781,
        "codigoSub": "1",
        "nombre": "Luz Mery Serrano Rangel"
    },
    {
        "codigoPre": 5400102782,
        "codigoSub": "1",
        "nombre": "CENTRO ESPECIALIZADO EN ENFERMEDADES DEL SENO Upgds S.A.S"
    },
    {
        "codigoPre": 5400102783,
        "codigoSub": "1",
        "nombre": "ACTIVAR SALUD S.A.S"
    },
    {
        "codigoPre": 5400102784,
        "codigoSub": "1",
        "nombre": "KARLY PAOLA MOLINARES COVILLA"
    },
    {
        "codigoPre": 5400102785,
        "codigoSub": "1",
        "nombre": "ERIKA LILIANA LABRADOR PINTO"
    },
    {
        "codigoPre": 5400102786,
        "codigoSub": "1",
        "nombre": "GERARDO ANTONIO GONZALEZ GONGORA"
    },
    {
        "codigoPre": 5400102787,
        "codigoSub": "1",
        "nombre": "AIDS HEALTHCARE FOUNDATION COLOMBIA"
    },
    {
        "codigoPre": 5400102788,
        "codigoSub": "1",
        "nombre": "Claudia Marina Sarmiento Sandoval"
    },
    {
        "codigoPre": 5400102789,
        "codigoSub": "1",
        "nombre": "Leonel Alfredo Diaz Vera"
    },
    {
        "codigoPre": 5400102790,
        "codigoSub": "1",
        "nombre": "FUNDACION ORIENTAME"
    },
    {
        "codigoPre": 5400102791,
        "codigoSub": "1",
        "nombre": "COMITE INTERNACIONAL DE RESCATE INC INTERNATIONAL RESCUE COMMITTEE"
    },
    {
        "codigoPre": 5400102792,
        "codigoSub": "1",
        "nombre": "Marleny Ramirez Portilla"
    },
    {
        "codigoPre": 5400102793,
        "codigoSub": "1",
        "nombre": "Jorge Eliecer Ramirez Vasquez"
    },
    {
        "codigoPre": 5400102794,
        "codigoSub": "1",
        "nombre": "EVELYN LINDSAY TORRES SANCHEZ"
    },
    {
        "codigoPre": 5400102795,
        "codigoSub": "1",
        "nombre": "DIANA CAROLINA SUAREZ SIERRA"
    },
    {
        "codigoPre": 5400102796,
        "codigoSub": "1",
        "nombre": "DIEGO ANDRES SERNA SUAREZ"
    },
    {
        "codigoPre": 5400102797,
        "codigoSub": "1",
        "nombre": "PREVENCION SALUD Upgds LTDA."
    },
    {
        "codigoPre": 5400102798,
        "codigoSub": "1",
        "nombre": "STEPHANY LISBETH DELGADO RANGEL"
    },
    {
        "codigoPre": 5400102799,
        "codigoSub": "1",
        "nombre": "LUIS FERNANDO MARIC\u0011O MARTINEZ"
    },
    {
        "codigoPre": 5400102802,
        "codigoSub": "1",
        "nombre": "Upgds MEDICINA ESPECIALIZADA DEL RIESGO EN SALUD DEL SUR SAS"
    },
    {
        "codigoPre": 5400102803,
        "codigoSub": "1",
        "nombre": "ISAIAS DE JESUS DUQUE MORA"
    },
    {
        "codigoPre": 5400102804,
        "codigoSub": "1",
        "nombre": "JORGE LUIS GARCIA MENCO"
    },
    {
        "codigoPre": 5400102805,
        "codigoSub": "1",
        "nombre": "Marcela Quiceno Manosalva"
    },
    {
        "codigoPre": 5400102806,
        "codigoSub": "1",
        "nombre": "Daniel Yesid Moncada Ferreira"
    },
    {
        "codigoPre": 5400102810,
        "codigoSub": "1",
        "nombre": "JOHANA GIORGI AREVALO"
    },
    {
        "codigoPre": 5400102811,
        "codigoSub": "1",
        "nombre": "HUGO OSWALDO ALVARADO MORA"
    },
    {
        "codigoPre": 5400102814,
        "codigoSub": "1",
        "nombre": "KARINA DEL VALLE MARQUEZ LINARES"
    },
    {
        "codigoPre": 5400102818,
        "codigoSub": "1",
        "nombre": "Claudia Milena LeC3n Castro"
    },
    {
        "codigoPre": 5400102819,
        "codigoSub": "1",
        "nombre": "DIDIER DE JESUS BONILLA PEREZ"
    },
    {
        "codigoPre": 5400102820,
        "codigoSub": "1",
        "nombre": "ELIANA MARITZA GARCIA VILLAMIZAR"
    },
    {
        "codigoPre": 5400102821,
        "codigoSub": "1",
        "nombre": "AndrC)s Fernando RodrC-guez PabC3n"
    },
    {
        "codigoPre": 5400102823,
        "codigoSub": "1",
        "nombre": "SILVIA MARIA PEC\u0011ARANDA RAMIREZ"
    },
    {
        "codigoPre": 5400102825,
        "codigoSub": "1",
        "nombre": "WBEIMAR SAMPER MAESTRE"
    },
    {
        "codigoPre": 5400102826,
        "codigoSub": "1",
        "nombre": "Ingrid Yohana Leon Castro"
    },
    {
        "codigoPre": 5400102827,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS FARIA MALDONADO"
    },
    {
        "codigoPre": 5400102828,
        "codigoSub": "1",
        "nombre": "MARTHA CAROLINA FARIA MALDONADO"
    },
    {
        "codigoPre": 5400102829,
        "codigoSub": "1",
        "nombre": "RICHARD ADOLFO RAMIREZ GOMEZ"
    },
    {
        "codigoPre": 5400102830,
        "codigoSub": "1",
        "nombre": "CENTRO DE DIABETES Y METABOLISMO, CEDYM S.A.S."
    },
    {
        "codigoPre": 5400102831,
        "codigoSub": "1",
        "nombre": "FERNANDO QUINTERO TORRADO"
    },
    {
        "codigoPre": 5400102832,
        "codigoSub": "1",
        "nombre": "RAQUEL RAMIREZ IBARRA"
    },
    {
        "codigoPre": 5400102834,
        "codigoSub": "1",
        "nombre": "ERIKA YULIETH OSORIO PEREZ"
    },
    {
        "codigoPre": 5400102835,
        "codigoSub": "1",
        "nombre": "Rosalinda Theran Pacheco"
    },
    {
        "codigoPre": 5400102836,
        "codigoSub": "1",
        "nombre": "NP MEDICAL Upgds SAS"
    },
    {
        "codigoPre": 5400102838,
        "codigoSub": "1",
        "nombre": "johana marcela martinez marmol"
    },
    {
        "codigoPre": 5400102839,
        "codigoSub": "1",
        "nombre": "ANDRES AVELINO CASTRO SUAREZ"
    },
    {
        "codigoPre": 5400102840,
        "codigoSub": "1",
        "nombre": "astrid milena vecino bautista"
    },
    {
        "codigoPre": 5400102841,
        "codigoSub": "1",
        "nombre": "Nataly Rojas Silva"
    },
    {
        "codigoPre": 5400102842,
        "codigoSub": "1",
        "nombre": "SANDRA MILENA URIBE GRANADOS"
    },
    {
        "codigoPre": 5400102843,
        "codigoSub": "1",
        "nombre": "UNIDAD DE ENDOSCOPIA GASTROMED S.A.S."
    },
    {
        "codigoPre": 5400102844,
        "codigoSub": "1",
        "nombre": "IMAGENES DIAGNOSTICAS ECOFETAL UNIDAD DE MEDICINA MATERNO FETAL SAS"
    },
    {
        "codigoPre": 5400102845,
        "codigoSub": "1",
        "nombre": "JOSE JOAQUIN SANCHEZ TORREALBA"
    },
    {
        "codigoPre": 5400102846,
        "codigoSub": "1",
        "nombre": "Faiber IvC!n LC3pez Reyes"
    },
    {
        "codigoPre": 5400102849,
        "codigoSub": "1",
        "nombre": "JOSE FREDDY VESGA BONILLA"
    },
    {
        "codigoPre": 5400102852,
        "codigoSub": "1",
        "nombre": "MELIDA ROCIO VARGAS BARRIOS"
    },
    {
        "codigoPre": 5400102853,
        "codigoSub": "1",
        "nombre": "SILVANA SAGRA ESCALANTE"
    },
    {
        "codigoPre": 5400102854,
        "codigoSub": "1",
        "nombre": "MEDIKAP S.A.S."
    },
    {
        "codigoPre": 5400102857,
        "codigoSub": "1",
        "nombre": "MIGUEL ORIELSO CARDENAS ALVAREZ"
    },
    {
        "codigoPre": 5400102858,
        "codigoSub": "1",
        "nombre": "DANIELA RUBIO SANTOS"
    },
    {
        "codigoPre": 5400102859,
        "codigoSub": "1",
        "nombre": "Luz Edith Angulo Gamboa"
    },
    {
        "codigoPre": 5400102860,
        "codigoSub": "1",
        "nombre": "Nelcy Teresa Tamara Rivera"
    },
    {
        "codigoPre": 5400102863,
        "codigoSub": "1",
        "nombre": "Andrea Toscano Oyola"
    },
    {
        "codigoPre": 5400102864,
        "codigoSub": "1",
        "nombre": "JUAN SEBASTIAN PARRA CHARRIS"
    },
    {
        "codigoPre": 5400102865,
        "codigoSub": "1",
        "nombre": "ANGELICA MARIA VIVAS DUARTE"
    },
    {
        "codigoPre": 5400102866,
        "codigoSub": "1",
        "nombre": "FLOR DE LIZ CASADIEGO TORRADO"
    },
    {
        "codigoPre": 5400102867,
        "codigoSub": "1",
        "nombre": "CRISMARYTH DEL PILAR OCHOA MORALES"
    },
    {
        "codigoPre": 5400102868,
        "codigoSub": "1",
        "nombre": "jose ivan albarracin galvis"
    },
    {
        "codigoPre": 5400102869,
        "codigoSub": "1",
        "nombre": "JENNY ANDREINA NIETO ALBARRACIN"
    },
    {
        "codigoPre": 5400102873,
        "codigoSub": "1",
        "nombre": "DANIELA STEFANIA LOPEZ CORZO"
    },
    {
        "codigoPre": 5400102874,
        "codigoSub": "1",
        "nombre": "YENIS EDITH MARIN ZAPATA"
    },
    {
        "codigoPre": 5400102875,
        "codigoSub": "1",
        "nombre": "RODRIGUEZ RADIOLOGOS S.A.S."
    },
    {
        "codigoPre": 5400102881,
        "codigoSub": "1",
        "nombre": "VIMEDIC SALUD INTEGRAL SAS"
    },
    {
        "codigoPre": 5400102882,
        "codigoSub": "1",
        "nombre": "I.P.S MECAS SALUD DOMICILIARIA S.A.S"
    },
    {
        "codigoPre": 5400102884,
        "codigoSub": "1",
        "nombre": "agudelo gelvez karen maria"
    },
    {
        "codigoPre": 5400102885,
        "codigoSub": "1",
        "nombre": "ORLANDO ADOLFO FUENTES ROJAS"
    },
    {
        "codigoPre": 5400102886,
        "codigoSub": "1",
        "nombre": "CORPORACIC\u0013N SOCIAL Y EDUCATIVA FORMADORES SIGLO XXI"
    },
    {
        "codigoPre": 5400102889,
        "codigoSub": "1",
        "nombre": "JOHAN ENRIQUE ALFARO GARZON"
    },
    {
        "codigoPre": 5400102890,
        "codigoSub": "1",
        "nombre": "Greissly Dayana Navarro LC3pez"
    },
    {
        "codigoPre": 5400102891,
        "codigoSub": "1",
        "nombre": "BELKYS COROMOTO ALVAREZ GUILLEN"
    },
    {
        "codigoPre": 5400102892,
        "codigoSub": "1",
        "nombre": "maria alejandra arias bernal"
    },
    {
        "codigoPre": 5400102894,
        "codigoSub": "1",
        "nombre": "Maribell Cardenas Zerpa"
    },
    {
        "codigoPre": 5400102896,
        "codigoSub": "1",
        "nombre": "RED SALUD INTEGRAL Upgds S.A.S."
    },
    {
        "codigoPre": 5400102897,
        "codigoSub": "1",
        "nombre": "IMAGEN DENTAL INTEGRAL Upgds S.A.S."
    },
    {
        "codigoPre": 5400102898,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS VERGEL MARTINEZ"
    },
    {
        "codigoPre": 5400102899,
        "codigoSub": "1",
        "nombre": "Yaneth Eliana Pimiento Alvarez"
    },
    {
        "codigoPre": 5400102900,
        "codigoSub": "1",
        "nombre": "Maria Ximena Camargo GarcC-a"
    },
    {
        "codigoPre": 5400102901,
        "codigoSub": "1",
        "nombre": "Oscar Alejandro Cardenas Quintero"
    },
    {
        "codigoPre": 5400102904,
        "codigoSub": "1",
        "nombre": "Sindy Katherine Escobar Blanco"
    },
    {
        "codigoPre": 5400102905,
        "codigoSub": "1",
        "nombre": "Claudia Marcela Martinez Carrillo"
    },
    {
        "codigoPre": 5400102906,
        "codigoSub": "1",
        "nombre": "JOSE LUIS HERNANDEZ GONZALEZ"
    },
    {
        "codigoPre": 5400102907,
        "codigoSub": "1",
        "nombre": "OSCAR CHARRY TORRES"
    },
    {
        "codigoPre": 5400102908,
        "codigoSub": "1",
        "nombre": "OSCAR MARULANDA CALIXTO"
    },
    {
        "codigoPre": 5400102909,
        "codigoSub": "1",
        "nombre": "AndrC)s Felipe Arias SC!nchez"
    },
    {
        "codigoPre": 5400102910,
        "codigoSub": "1",
        "nombre": "PERFECT CLINIC S.A.S."
    },
    {
        "codigoPre": 5400102911,
        "codigoSub": "1",
        "nombre": "I.P.S GENERAL MEDICAL SERVICES COLOMBIA S.A.S"
    },
    {
        "codigoPre": 5400102912,
        "codigoSub": "1",
        "nombre": "GINA MARITZA VEGA PARRAGA"
    },
    {
        "codigoPre": 5400102913,
        "codigoSub": "1",
        "nombre": "Erika Liliana Duran Botello"
    },
    {
        "codigoPre": 5400102915,
        "codigoSub": "1",
        "nombre": "MAYRA ALEJANDRA LAGOS PEREZ"
    },
    {
        "codigoPre": 5400102917,
        "codigoSub": "1",
        "nombre": "Angel Aurelio Joves Contreras"
    },
    {
        "codigoPre": 5400102918,
        "codigoSub": "1",
        "nombre": "JESSIKA PATRICIA GOMEZ PEC\u0011A"
    },
    {
        "codigoPre": 5400102919,
        "codigoSub": "1",
        "nombre": "Juan Ricardo Maldonado Gutierrez"
    },
    {
        "codigoPre": 5400102920,
        "codigoSub": "1",
        "nombre": "Leidy Johana Ortiz Acevedo"
    },
    {
        "codigoPre": 5400102921,
        "codigoSub": "1",
        "nombre": "Luis Fernando MuC1oz Acosta"
    },
    {
        "codigoPre": 5400102922,
        "codigoSub": "1",
        "nombre": "VIRNA LUZ ROBALINO RAMIREZ"
    },
    {
        "codigoPre": 5400102923,
        "codigoSub": "1",
        "nombre": "henry sayago prato"
    },
    {
        "codigoPre": 5400102924,
        "codigoSub": "1",
        "nombre": "mariela acevedo contreras"
    },
    {
        "codigoPre": 5400102926,
        "codigoSub": "1",
        "nombre": "CARLOS FELIPE PEC\u0011ARANDA HENAO"
    },
    {
        "codigoPre": 5400102928,
        "codigoSub": "1",
        "nombre": "LILIANA CAROLINA VILLAMIZAR JAIMES"
    },
    {
        "codigoPre": 5400102929,
        "codigoSub": "1",
        "nombre": "EILEEN ZULAY MEDINA RODRIGUEZ"
    },
    {
        "codigoPre": 5400102931,
        "codigoSub": "1",
        "nombre": "Angela Patricia Bonilla Morales"
    },
    {
        "codigoPre": 5400102932,
        "codigoSub": "1",
        "nombre": "HADHASSAH ESTHER MANJARRES MORALES"
    },
    {
        "codigoPre": 5400102933,
        "codigoSub": "1",
        "nombre": "DANNA PATRICIA SANTOS MATAMOROS"
    },
    {
        "codigoPre": 5400102934,
        "codigoSub": "1",
        "nombre": "JESSICA LORENA PRATO VELASQUEZ"
    },
    {
        "codigoPre": 5400102935,
        "codigoSub": "1",
        "nombre": "Stefany Rodriguez Ariza"
    },
    {
        "codigoPre": 5400102936,
        "codigoSub": "1",
        "nombre": "JESSICA ISABEL MEJIA MALDONADO"
    },
    {
        "codigoPre": 5400102937,
        "codigoSub": "1",
        "nombre": "Julian Eduardo Pacheco Rojas"
    },
    {
        "codigoPre": 5400102938,
        "codigoSub": "1",
        "nombre": "JOHN JAIRO TORO ROJAS"
    },
    {
        "codigoPre": 5400102940,
        "codigoSub": "1",
        "nombre": "JOHANN ALEXANDER VILLAMIZAR LOBO"
    },
    {
        "codigoPre": 5400102941,
        "codigoSub": "1",
        "nombre": "BELMANN JAVIER GC\u0013MEZ PABC\u0013N"
    },
    {
        "codigoPre": 5400102942,
        "codigoSub": "1",
        "nombre": "Laura Ortega Guinguer"
    },
    {
        "codigoPre": 5400102943,
        "codigoSub": "1",
        "nombre": "CARLOS GABRIEL URIBE GARAY"
    },
    {
        "codigoPre": 5400102946,
        "codigoSub": "1",
        "nombre": "LUIS ALFREDO RODRIGUEZ RIOS"
    },
    {
        "codigoPre": 5400102947,
        "codigoSub": "1",
        "nombre": "CARLOS MARIO QUINTERO ARREGOCES"
    },
    {
        "codigoPre": 5400102948,
        "codigoSub": "1",
        "nombre": "MARIA FABIOLA LORENZO ABATE"
    },
    {
        "codigoPre": 5400102949,
        "codigoSub": "1",
        "nombre": "OSWALDO SARMIENTO PEC\u0011ARANDA"
    },
    {
        "codigoPre": 5400102952,
        "codigoSub": "1",
        "nombre": "Sandra Carolina Delgado Sierra"
    },
    {
        "codigoPre": 5400102953,
        "codigoSub": "1",
        "nombre": "marcela torrado montagut"
    },
    {
        "codigoPre": 5400102971,
        "codigoSub": "1",
        "nombre": "UCIS DE COLOMBIA S.A.S. Upgds"
    },
    {
        "codigoPre": 5400300708,
        "codigoSub": "1",
        "nombre": "LINA ESPERANZA ROPERO TORRADO"
    },
    {
        "codigoPre": 5400300786,
        "codigoSub": "1",
        "nombre": "HENRY PEREZ ALVAREZ"
    },
    {
        "codigoPre": 5400301020,
        "codigoSub": "1",
        "nombre": "EMPRESA SOCIAL DEL ESTADO HOSPITAL REGIONAL NOROCCIDENTAL"
    },
    {
        "codigoPre": 5400301091,
        "codigoSub": "1",
        "nombre": "ANGELA MARIA GARCIA BECERRA"
    },
    {
        "codigoPre": 5400301238,
        "codigoSub": "1",
        "nombre": "ALVARO MIGUEL REDONDO OLIVA"
    },
    {
        "codigoPre": 5400301734,
        "codigoSub": "1",
        "nombre": "KARISALUD Upgds LTDA."
    },
    {
        "codigoPre": 5400302299,
        "codigoSub": "1",
        "nombre": "JAIME ADALBERTO ROPERO GAONA"
    },
    {
        "codigoPre": 5400302435,
        "codigoSub": "1",
        "nombre": "Upgds NUEVO MILENIO SAS"
    },
    {
        "codigoPre": 5400302566,
        "codigoSub": "1",
        "nombre": "Pedro Alfonso Jaime Miranda"
    },
    {
        "codigoPre": 5409902690,
        "codigoSub": "1",
        "nombre": "Paola Andrea Rincon Morales"
    },
    {
        "codigoPre": 5412800641,
        "codigoSub": "1",
        "nombre": "EMPRESA SOCIAL DEL ESTADO HOSPITAL REGIONAL DE OCCIDENTE"
    },
    {
        "codigoPre": 5412801373,
        "codigoSub": "1",
        "nombre": "EGNA YAMILE CACERES CORREDOR"
    },
    {
        "codigoPre": 5412802241,
        "codigoSub": 7,
        "nombre": "yurley socorro hernandez cely"
    },
    {
        "codigoPre": 5412802887,
        "codigoSub": "1",
        "nombre": "mayerly diaz caceres"
    },
    {
        "codigoPre": 5417201083,
        "codigoSub": "1",
        "nombre": "E.S.E HOSPITAL REGIONAL SURORIENTAL"
    },
    {
        "codigoPre": 5417201310,
        "codigoSub": "1",
        "nombre": "YURLEY BELEN AGUILLON DUQUE"
    },
    {
        "codigoPre": 5417201525,
        "codigoSub": "1",
        "nombre": "JAIME RAMON OROZCO PINTO"
    },
    {
        "codigoPre": 5417202224,
        "codigoSub": "1",
        "nombre": "OLGA LUCIA RODRIGUEZ MIRANDA"
    },
    {
        "codigoPre": 5417202469,
        "codigoSub": "1",
        "nombre": "SHELIBER TATIANA SERRANO TAMI"
    },
    {
        "codigoPre": 5417202473,
        "codigoSub": "1",
        "nombre": "DIANA ZULAY GUTIERREZ PUERTO"
    },
    {
        "codigoPre": 5417202644,
        "codigoSub": "1",
        "nombre": "JORGE ALBERTO Calderon GAMBOA"
    },
    {
        "codigoPre": 5417202645,
        "codigoSub": "1",
        "nombre": "JAIRO JOSE SANTOS GRANADOS"
    },
    {
        "codigoPre": 5417202709,
        "codigoSub": "1",
        "nombre": "HENRY ALBERTO BUITRAGO MONTAC\u0011EZ"
    },
    {
        "codigoPre": 5417202721,
        "codigoSub": "1",
        "nombre": "MC3nica RocC-o HernC!ndez Valdivieso"
    },
    {
        "codigoPre": 5417202851,
        "codigoSub": "1",
        "nombre": "DAYANA NATHALI RODRIGUEZ YAC\u0011EZ"
    },
    {
        "codigoPre": 5417202871,
        "codigoSub": "1",
        "nombre": "SONIA CAROLINA PEC\u0011A RODRIGUEZ"
    },
    {
        "codigoPre": 5417202927,
        "codigoSub": "1",
        "nombre": "OSCAR SEGURA OLANO"
    },
    {
        "codigoPre": 5417402188,
        "codigoSub": "1",
        "nombre": "OSCAR ALBERTO PORTILLA PORTILLA"
    },
    {
        "codigoPre": 5417402402,
        "codigoSub": "1",
        "nombre": "NIDIAN ROCIO PORTILLA RODRIGUEZ"
    },
    {
        "codigoPre": 5417402664,
        "codigoSub": "1",
        "nombre": "LUDY ESPERANZA VILLAMIZAR JAIMES"
    },
    {
        "codigoPre": 5420600874,
        "codigoSub": "1",
        "nombre": "MAGALY ESPERANZA FLOREZ SALAZAR."
    },
    {
        "codigoPre": 5420601339,
        "codigoSub": "1",
        "nombre": "JAVIER ENRIQUE SANTIAGO CANTILLO"
    },
    {
        "codigoPre": 5420602253,
        "codigoSub": "1",
        "nombre": "SASKIA MARCELA CONTRERAS VERJEL"
    },
    {
        "codigoPre": 5420602780,
        "codigoSub": "1",
        "nombre": "Luz Bellanira Vanegas Suarez"
    },
    {
        "codigoPre": 5422302176,
        "codigoSub": "1",
        "nombre": "IRENE ANDREA LLANOS CASTAC\u0011EDA"
    },
    {
        "codigoPre": 5424502945,
        "codigoSub": "1",
        "nombre": "BETSY ALEXANDRA ARIAS LOZANO"
    },
    {
        "codigoPre": 5425001879,
        "codigoSub": "1",
        "nombre": "YANETH AMELIA JAIMES JAUREGUI"
    },
    {
        "codigoPre": 5425002647,
        "codigoSub": "1",
        "nombre": "ANGELA MARGARITA ALMANZA PINO"
    },
    {
        "codigoPre": 5426100205,
        "codigoSub": "1",
        "nombre": "EDGAR ANTONIO VELANDIA ULLOA"
    },
    {
        "codigoPre": 5426100598,
        "codigoSub": "1",
        "nombre": "LUIS EDUARDO ANGULO CONTRERAS"
    },
    {
        "codigoPre": 5426100939,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL JUAN LUIS LONDOC\u0011O"
    },
    {
        "codigoPre": 5426101867,
        "codigoSub": "1",
        "nombre": "I.P.S. CENTRO MEDICO Y DROGUERIA DEL CARIBE. S.A.S."
    },
    {
        "codigoPre": 5426101890,
        "codigoSub": "1",
        "nombre": "JULIO CESAR LUNA ARROYO"
    },
    {
        "codigoPre": 5426102028,
        "codigoSub": "1",
        "nombre": "Yadir Alejandro Bitar Caicedo"
    },
    {
        "codigoPre": 5426102512,
        "codigoSub": "1",
        "nombre": "GLORIA PATRICIA FLOREZ ESCALANTE"
    },
    {
        "codigoPre": 5431301021,
        "codigoSub": "1",
        "nombre": "E.S.E. HOSPITAL REGIONAL CENTRO"
    },
    {
        "codigoPre": 5434701355,
        "codigoSub": "1",
        "nombre": "E.S.E JOAQUIN EMIRO ESCOBAR"
    },
    {
        "codigoPre": 5437701905,
        "codigoSub": "1",
        "nombre": "LUZ DARY CARDENAS RANGEL"
    },
    {
        "codigoPre": 5439801444,
        "codigoSub": "1",
        "nombre": "EMPRESA SOCIAL DEL ESTADO DE PRIMER NIVEL DE ATENCION HOSPITAL ISABEL CELIS YAC\u0011EZ"
    },
    {
        "codigoPre": 5440500043,
        "codigoSub": "1",
        "nombre": "JOSE GREGORIO HERNANDEZ RIVERA"
    },
    {
        "codigoPre": 5440500658,
        "codigoSub": "1",
        "nombre": "LUZ MARINA PUENTES MORENO"
    },
    {
        "codigoPre": 5440501008,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL LOCAL MUNICIPIO LOS PATIOS"
    },
    {
        "codigoPre": 5440501575,
        "codigoSub": "1",
        "nombre": "Andrea Carolina PeC1aranda VillafaC1e"
    },
    {
        "codigoPre": 5440501607,
        "codigoSub": "1",
        "nombre": "RUBIELA EDITH JAIMES ESCALANTE"
    },
    {
        "codigoPre": 5440501695,
        "codigoSub": "1",
        "nombre": "CERTISALUD LTDA"
    },
    {
        "codigoPre": 5440501888,
        "codigoSub": "1",
        "nombre": "CENTRO MEDICO LA SAMARITANA LTDA"
    },
    {
        "codigoPre": 5440501930,
        "codigoSub": "1",
        "nombre": "ANDRES ELOY GALVIS JAIMES"
    },
    {
        "codigoPre": 5440501934,
        "codigoSub": 2,
        "nombre": "CARMEN YAJAIRA GUTIERREZ HERNANDEZ"
    },
    {
        "codigoPre": 5440501989,
        "codigoSub": 2,
        "nombre": "SAB ESTC\tTICA Y REHABILITACIC\u0013N S.A.S"
    },
    {
        "codigoPre": 5440502012,
        "codigoSub": 2,
        "nombre": "luis alberto bermejo cruz"
    },
    {
        "codigoPre": 5440502130,
        "codigoSub": "1",
        "nombre": "IVAN JOSE GELVEZ ORTIZ"
    },
    {
        "codigoPre": 5440502212,
        "codigoSub": "1",
        "nombre": "PAULA JOHANNA HERNANDEZ RIVERO"
    },
    {
        "codigoPre": 5440502215,
        "codigoSub": "1",
        "nombre": "JUAN MANUEL HERNANDEZ RIVERO"
    },
    {
        "codigoPre": 5440502259,
        "codigoSub": "1",
        "nombre": "DAVID ALEXANDER RODRIGUEZ PEC\u0011A"
    },
    {
        "codigoPre": 5440502281,
        "codigoSub": 4,
        "nombre": "MUTUAL HEALT CARE SALUD TOTAL PARA TODOS S.A.S"
    },
    {
        "codigoPre": 5440502322,
        "codigoSub": 4,
        "nombre": "FUNDACION FAMILIAR PRO REHABILITACION DE FARMACODEPENDEINTES FARO"
    },
    {
        "codigoPre": 5440502359,
        "codigoSub": "1",
        "nombre": "ONG CRECER EN FAMILIA"
    },
    {
        "codigoPre": 5440502364,
        "codigoSub": "1",
        "nombre": "Angela Junca Pabon"
    },
    {
        "codigoPre": 5440502419,
        "codigoSub": "1",
        "nombre": "ZABDY EDUARDO SANTOS COTE"
    },
    {
        "codigoPre": 5440502441,
        "codigoSub": "1",
        "nombre": "Mallerly Brigitte Villamizar Castellanos"
    },
    {
        "codigoPre": 5440502478,
        "codigoSub": "1",
        "nombre": "HERMANAS PEQUEC\u0011AS APOSTOLES DE LA REDENCION"
    },
    {
        "codigoPre": 5440502493,
        "codigoSub": "1",
        "nombre": "tatiana karine cruces martinez"
    },
    {
        "codigoPre": 5440502507,
        "codigoSub": "1",
        "nombre": "Sandra Patricia PeC1a Lizarazo"
    },
    {
        "codigoPre": 5440502531,
        "codigoSub": "1",
        "nombre": "ADRIANA LUCIA LIZCANO RODAS"
    },
    {
        "codigoPre": 5440502532,
        "codigoSub": "1",
        "nombre": "JESSICA PAOLA QUINTERO GALLARDO"
    },
    {
        "codigoPre": 5440502534,
        "codigoSub": "1",
        "nombre": "LUZ MARIA ARDILA"
    },
    {
        "codigoPre": 5440502543,
        "codigoSub": "1",
        "nombre": "THAIZ CAROLINA SANCHEZ MARTINEZ"
    },
    {
        "codigoPre": 5440502555,
        "codigoSub": "1",
        "nombre": "Nelson Fernando Correa Rodriguez"
    },
    {
        "codigoPre": 5440502556,
        "codigoSub": "1",
        "nombre": "Mayda Gissela MogollC3n Castellanos"
    },
    {
        "codigoPre": 5440502583,
        "codigoSub": "1",
        "nombre": "jairo alberto vivas florez"
    },
    {
        "codigoPre": 5440502655,
        "codigoSub": "1",
        "nombre": "AMAURY JOSE VILLALBA ORTEGA"
    },
    {
        "codigoPre": 5440502763,
        "codigoSub": "1",
        "nombre": "LEIDY VIVIANA SANCHEZ ALSINA"
    },
    {
        "codigoPre": 5440502778,
        "codigoSub": "1",
        "nombre": "CORPORACION ECLESIAL Y EDUCATIVA PARA EL DESARROLLO HUMANO SOSTENIBLE CAMBIO Y ESPERANZA"
    },
    {
        "codigoPre": 5440502800,
        "codigoSub": "1",
        "nombre": "jesus albeiro vargas niC1o"
    },
    {
        "codigoPre": 5440502801,
        "codigoSub": "1",
        "nombre": "Nigreisy del Valle Sanchez Contreras"
    },
    {
        "codigoPre": 5440502833,
        "codigoSub": "1",
        "nombre": "GLADYS LORENA BUITRAGO QUINTERO"
    },
    {
        "codigoPre": 5440502861,
        "codigoSub": "1",
        "nombre": "INGRID ROCIO DIAZ MALDONADO"
    },
    {
        "codigoPre": 5440502878,
        "codigoSub": "1",
        "nombre": "MARCO JOSE CAMACHO SABOGAL"
    },
    {
        "codigoPre": 5440502883,
        "codigoSub": "1",
        "nombre": "Kathrin Emireya Villamizar RincC3n"
    },
    {
        "codigoPre": 5440502903,
        "codigoSub": "1",
        "nombre": "yiseth yoanny ojeda maldonado"
    },
    {
        "codigoPre": 5440502925,
        "codigoSub": "1",
        "nombre": "Andres Campuzano Arboleda"
    },
    {
        "codigoPre": 54498,
        "codigoSub": 0,
        "nombre": "SecretarC-a de Salud Municipal de OcaC1a"
    },
    {
        "codigoPre": 5449800062,
        "codigoSub": "1",
        "nombre": "CARLOS JULIO VARGAS SANCHEZ"
    },
    {
        "codigoPre": 5449800078,
        "codigoSub": "1",
        "nombre": "GLORIA DEL CARMEN ALVAREZ SANGUINO"
    },
    {
        "codigoPre": 5449800079,
        "codigoSub": "1",
        "nombre": "TERESA DE JESUS ALVAREZ SANGUINO"
    },
    {
        "codigoPre": 5449800094,
        "codigoSub": "1",
        "nombre": "MAURICIO ENRIQUE LEMUS MAICHEL"
    },
    {
        "codigoPre": 5449800095,
        "codigoSub": "1",
        "nombre": "LUIS FERNANDO CARVAJALINO CALLE"
    },
    {
        "codigoPre": 5449800217,
        "codigoSub": "1",
        "nombre": "ALIDA DEL SOCORRO QUINTERO CONTRERAS"
    },
    {
        "codigoPre": 5449800264,
        "codigoSub": "1",
        "nombre": "MARTHA CECILIA SARMIENTO CASADIEGO"
    },
    {
        "codigoPre": 5449800280,
        "codigoSub": "1",
        "nombre": "EDUARDO QUINTERO MONTAGUTH"
    },
    {
        "codigoPre": 5449800281,
        "codigoSub": "1",
        "nombre": "GLORIA MARCELA QUINTERO POSADA"
    },
    {
        "codigoPre": 5449800291,
        "codigoSub": "1",
        "nombre": "IVAN AREVALO AREVALO"
    },
    {
        "codigoPre": 5449800330,
        "codigoSub": "1",
        "nombre": "ADRIANA RODRIGUEZ NAVAS"
    },
    {
        "codigoPre": 5449800351,
        "codigoSub": "1",
        "nombre": "LEONARDO ENRIQUE JACOME CABRALES"
    },
    {
        "codigoPre": 5449800398,
        "codigoSub": "1",
        "nombre": "ESTELIA DEL SALVADOR PEREZ CASTILLA"
    },
    {
        "codigoPre": 5449800445,
        "codigoSub": "1",
        "nombre": "carlos alberto ojeda claro"
    },
    {
        "codigoPre": 5449800481,
        "codigoSub": "1",
        "nombre": "MARGARITA SOFIA DURAN SANTANA"
    },
    {
        "codigoPre": 5449800545,
        "codigoSub": "1",
        "nombre": "JUAN BAUTISTA GUTIERREZ CASADO"
    },
    {
        "codigoPre": 5449800547,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL EMIRO QUINTERO CAÑIZAREZ"
    },
    {
        "codigoPre": 5449800580,
        "codigoSub": "1",
        "nombre": "ALBERTO ELIAS NUMA ILLERA"
    },
    {
        "codigoPre": 5449800582,
        "codigoSub": "1",
        "nombre": "LIGIA SUAREZ DE GUTIERREZ"
    },
    {
        "codigoPre": 5449800583,
        "codigoSub": "1",
        "nombre": "MARITZA PEC\u0011ALOSA GRANADOS"
    },
    {
        "codigoPre": 5449800584,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS JIMENEZ ILLERA"
    },
    {
        "codigoPre": 5449800606,
        "codigoSub": "1",
        "nombre": "CLINICA Y DROGUERIA NTRA SRA DE TORCOROMA S.A.S."
    },
    {
        "codigoPre": 5449800609,
        "codigoSub": "1",
        "nombre": "ALBERTO ALVAREZ GALLARDO"
    },
    {
        "codigoPre": 5449800636,
        "codigoSub": "1",
        "nombre": "EDMUNDO CASTRO GARCIA"
    },
    {
        "codigoPre": 5449800637,
        "codigoSub": "1",
        "nombre": "JORGE ANGEL LUQUETTA ARDILA"
    },
    {
        "codigoPre": 5449800645,
        "codigoSub": "1",
        "nombre": "JACQUELINE LEMUS MAICHEL"
    },
    {
        "codigoPre": 5449800646,
        "codigoSub": "1",
        "nombre": "YESID OSWALDO QUINTERO MEZA"
    },
    {
        "codigoPre": 5449800647,
        "codigoSub": "1",
        "nombre": "ANGELA PAOLA OSORIO SERRANO"
    },
    {
        "codigoPre": 5449800648,
        "codigoSub": "1",
        "nombre": "MIGUEL ALFREDO GANDUR VASQUEZ"
    },
    {
        "codigoPre": 5449800649,
        "codigoSub": "1",
        "nombre": "LEDY KARINA ROPERO PACHECO"
    },
    {
        "codigoPre": 5449800650,
        "codigoSub": "1",
        "nombre": "EYLER ALFONSO PEREZ SERRANO"
    },
    {
        "codigoPre": 5449800651,
        "codigoSub": "1",
        "nombre": "CARMEN SOFIA TELLO HERNANDEZ"
    },
    {
        "codigoPre": 5449800652,
        "codigoSub": "1",
        "nombre": "CLAUDIA CELINA HADDAD LINERO"
    },
    {
        "codigoPre": 5449800660,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS CASADIEGO AMAYA"
    },
    {
        "codigoPre": 5449800661,
        "codigoSub": "1",
        "nombre": "MILDRETH AMANDA CARRASCAL TORRADO"
    },
    {
        "codigoPre": 5449800663,
        "codigoSub": "1",
        "nombre": "ERWING GELVES AROCHA"
    },
    {
        "codigoPre": 5449800765,
        "codigoSub": "1",
        "nombre": "JUAN PABLO PORTILLO VERA"
    },
    {
        "codigoPre": 5449800801,
        "codigoSub": "1",
        "nombre": "CARLOS ALBERTO CACERES GONZALEZ"
    },
    {
        "codigoPre": 5449800807,
        "codigoSub": "1",
        "nombre": "LABORATORIO CLINICO ESPECIALIZADO LTDA"
    },
    {
        "codigoPre": 5449800811,
        "codigoSub": "1",
        "nombre": "HECTOR PATIC\u0011O MORENO"
    },
    {
        "codigoPre": 5449800866,
        "codigoSub": "1",
        "nombre": "JESUS PERRONI YAC\u0011EZ"
    },
    {
        "codigoPre": 5449800923,
        "codigoSub": "1",
        "nombre": "JAZMIN JAIME BAYONA"
    },
    {
        "codigoPre": 5449800924,
        "codigoSub": "1",
        "nombre": "VIVASALUD I.P.S. S.A.S"
    },
    {
        "codigoPre": 5449800925,
        "codigoSub": "1",
        "nombre": "HENRY ALBERTO ROYERO GUTIERREZ"
    },
    {
        "codigoPre": 5449800938,
        "codigoSub": "1",
        "nombre": "IRINA TORRES HERNANDEZ"
    },
    {
        "codigoPre": 5449800997,
        "codigoSub": "1",
        "nombre": "ALVARO AREVALO DURAN"
    },
    {
        "codigoPre": 5449801000,
        "codigoSub": "1",
        "nombre": "CLAUDIO JOSE CANOSA CAMPO"
    },
    {
        "codigoPre": 5449801012,
        "codigoSub": "1",
        "nombre": "HUGO ALBERTO REYES ALVAREZ"
    },
    {
        "codigoPre": 5449801013,
        "codigoSub": "1",
        "nombre": "JOSE LUIS VELASQUEZ SEPULVEDA"
    },
    {
        "codigoPre": 5449801137,
        "codigoSub": "1",
        "nombre": "WILLIAM QUINTERO VERGEL"
    },
    {
        "codigoPre": 5449801176,
        "codigoSub": "1",
        "nombre": "Duley Mauricio Jacome Jacome"
    },
    {
        "codigoPre": 5449801206,
        "codigoSub": "1",
        "nombre": "CARMELO DE JESUS SALAZAR GARCIA"
    },
    {
        "codigoPre": 5449801258,
        "codigoSub": "1",
        "nombre": "IVONE ELIANA RINCON CELY"
    },
    {
        "codigoPre": 5449801271,
        "codigoSub": "1",
        "nombre": "SOFIA MILENA MANZANO GUZMAN"
    },
    {
        "codigoPre": 5449801312,
        "codigoSub": "1",
        "nombre": "LAUDY JOHANNA JACOME SOLANO"
    },
    {
        "codigoPre": 5449801313,
        "codigoSub": "1",
        "nombre": "MYRIAM ROSA JACOME RINCON"
    },
    {
        "codigoPre": 5449801317,
        "codigoSub": "1",
        "nombre": "YESID EDUARDO CONRADO PIMIENTA"
    },
    {
        "codigoPre": 5449801348,
        "codigoSub": "1",
        "nombre": "CLAUDIA MARCELA JAIME QUINTERO"
    },
    {
        "codigoPre": 5449801351,
        "codigoSub": "1",
        "nombre": "LEONARDO AUGUSTO HERRERA NUMA"
    },
    {
        "codigoPre": 5449801445,
        "codigoSub": "1",
        "nombre": "I.P.S. CLINICA DIVINO NIC\u0011O"
    },
    {
        "codigoPre": 5449801534,
        "codigoSub": "1",
        "nombre": "MARIA ALEJANDRA CABRALES AREVALO"
    },
    {
        "codigoPre": 5449801567,
        "codigoSub": "1",
        "nombre": "AMBULANCIAS MEDICAS DE OCAC\u0011A- AMBUMED S.A.S."
    },
    {
        "codigoPre": 5449801598,
        "codigoSub": 2,
        "nombre": "SANAMEDIC SOCIEDAD POR ACCIONES SIMPLIFICADA - S.A.S"
    },
    {
        "codigoPre": 5449801614,
        "codigoSub": "1",
        "nombre": "I.P.S LIGA CONTRA EL CC\u0001NCER CAPC\fTULO OCAC\u0011A"
    },
    {
        "codigoPre": 5449801635,
        "codigoSub": "1",
        "nombre": "CLEMENCIA VICTORIA CABRALES ACOSTA"
    },
    {
        "codigoPre": 5449801636,
        "codigoSub": "1",
        "nombre": "SAMUEL ANTONIO JIMENEZ ILLERA"
    },
    {
        "codigoPre": 5449801639,
        "codigoSub": "1",
        "nombre": "Laritza Liliana Quintero Barros"
    },
    {
        "codigoPre": 5449801686,
        "codigoSub": "1",
        "nombre": "CORPORACION MI Upgds SANTANDER"
    },
    {
        "codigoPre": 5449801703,
        "codigoSub": "1",
        "nombre": "Claudia Ximena Rincon Navarro"
    },
    {
        "codigoPre": 5449801704,
        "codigoSub": "1",
        "nombre": "WILLIAM ANTONIO AMAYA CANO"
    },
    {
        "codigoPre": 5449801728,
        "codigoSub": 3,
        "nombre": "ZAIDY YAHAIRA BUITRAGO CANDELO"
    },
    {
        "codigoPre": 5449801735,
        "codigoSub": "1",
        "nombre": "CONFESALUD Upgds LTDA."
    },
    {
        "codigoPre": 5449801742,
        "codigoSub": "1",
        "nombre": "MEDI-CARE LIMITADA"
    },
    {
        "codigoPre": 5449801765,
        "codigoSub": "1",
        "nombre": "SALUD & ESTETICA CLINICA ODONTOLOGICA Upgds LIMITADA"
    },
    {
        "codigoPre": 5449801782,
        "codigoSub": "1",
        "nombre": "ANA AMINTA ARIAS GARCIA"
    },
    {
        "codigoPre": 5449801784,
        "codigoSub": "1",
        "nombre": "MILDRED TORCOROMA TORRES PEREZ"
    },
    {
        "codigoPre": 5449801794,
        "codigoSub": "1",
        "nombre": "CLINICA INTEGRAL OFTALMOQUIRURGICA CIO LTDA"
    },
    {
        "codigoPre": 5449801843,
        "codigoSub": "1",
        "nombre": "CENTRO DE ECO-RADIODIAGNOSTICOS S.A.S"
    },
    {
        "codigoPre": 5449801845,
        "codigoSub": "1",
        "nombre": "ODOES ODONTOLOGOS ESPECIALISTAS SAS"
    },
    {
        "codigoPre": 5449801846,
        "codigoSub": "1",
        "nombre": "IN LINE CENTRO DE FISIOTERAPIA Y ESTETICA OCAC\u0011A E.U."
    },
    {
        "codigoPre": 5449801889,
        "codigoSub": "1",
        "nombre": "CENTRO REHABILITAR SAS"
    },
    {
        "codigoPre": 5449801898,
        "codigoSub": "1",
        "nombre": "juan carlos gutierrez suarez"
    },
    {
        "codigoPre": 5449801901,
        "codigoSub": "1",
        "nombre": "CENTRO DE REHABILITACION FISICA Y ASESORIA SEXUAL CERAS SAS"
    },
    {
        "codigoPre": 5449801906,
        "codigoSub": "1",
        "nombre": "RODRIGO ENRIQUE OVALLE DAZA"
    },
    {
        "codigoPre": 5449801922,
        "codigoSub": "1",
        "nombre": "CUERPO DE BOMBEROS VOLUNTARIOS DE OCAC\u0011A"
    },
    {
        "codigoPre": 5449801931,
        "codigoSub": "1",
        "nombre": "CARLO JORGE SANTANA HERRERA"
    },
    {
        "codigoPre": 5449801940,
        "codigoSub": "1",
        "nombre": "VITAL MEDICAL CARE VIMEC S.A.S"
    },
    {
        "codigoPre": 5449801985,
        "codigoSub": "1",
        "nombre": "FREDY DIAZ ARCINIEGAS"
    },
    {
        "codigoPre": 5449801986,
        "codigoSub": "1",
        "nombre": "Kely Oliveria Correa Santana"
    },
    {
        "codigoPre": 5449801997,
        "codigoSub": "1",
        "nombre": "Oscar Eduardo Rochel Quintero"
    },
    {
        "codigoPre": 5449801998,
        "codigoSub": "1",
        "nombre": "Fundacion Centro de terapia Integral Amor \"FUNDAMOR\""
    },
    {
        "codigoPre": 5449802007,
        "codigoSub": "1",
        "nombre": "MARIO ALFONSO CARRASCAL QUIN"
    },
    {
        "codigoPre": 5449802026,
        "codigoSub": "1",
        "nombre": "NINFA JISSELA VILLAMIZAR SANCHEZ"
    },
    {
        "codigoPre": 5449802106,
        "codigoSub": "1",
        "nombre": "DR PROSALUD Upgds S.A.S"
    },
    {
        "codigoPre": 5449802114,
        "codigoSub": "1",
        "nombre": "UNIDAD ESPECIALIZADA EN ATENCIC\u0013N TERAPC\tUTICA LTDA"
    },
    {
        "codigoPre": 5449802124,
        "codigoSub": "1",
        "nombre": "SERVICIOS MEDICOS INTEGRADOS MEDICA Upgds SAS"
    },
    {
        "codigoPre": 5449802158,
        "codigoSub": "1",
        "nombre": "HECTOR ALFONSO PATIC\u0011O HENAO"
    },
    {
        "codigoPre": 5449802159,
        "codigoSub": "1",
        "nombre": "DOMENICA AYMONE SILVA COLMENARES"
    },
    {
        "codigoPre": 5449802184,
        "codigoSub": "1",
        "nombre": "Maria Camila Ropero Ortega"
    },
    {
        "codigoPre": 5449802203,
        "codigoSub": "1",
        "nombre": "YENICA ALEXANDRA PACHECO PACHECO"
    },
    {
        "codigoPre": 5449802206,
        "codigoSub": "1",
        "nombre": "CLAUDIA MENESES JACOME"
    },
    {
        "codigoPre": 5449802227,
        "codigoSub": "1",
        "nombre": "gisseth lorena sanchez lazaro"
    },
    {
        "codigoPre": 5449802233,
        "codigoSub": "1",
        "nombre": "LEONARDO JOSE ALVAREZ ALVAREZ"
    },
    {
        "codigoPre": 5449802242,
        "codigoSub": "1",
        "nombre": "CENTRO DE ATENCION NEUROPSIQUIATRICO DE OCAC\u0011A S.A.S."
    },
    {
        "codigoPre": 5449802289,
        "codigoSub": "1",
        "nombre": "MAGDA BEATRIZ TORO LEIVA"
    },
    {
        "codigoPre": 5449802290,
        "codigoSub": "1",
        "nombre": "FABIAN NAVARRO MELO"
    },
    {
        "codigoPre": 5449802317,
        "codigoSub": "1",
        "nombre": "ABEL JOSE AVILA PEREZ"
    },
    {
        "codigoPre": 5449802352,
        "codigoSub": "1",
        "nombre": "JUAN FERNANDO AGUDELO BERRIO"
    },
    {
        "codigoPre": 5449802354,
        "codigoSub": "1",
        "nombre": "MARIA NEREIDA JACOME RINCON"
    },
    {
        "codigoPre": 5449802355,
        "codigoSub": "1",
        "nombre": "CARLOS ALFREDO PEREA VELASQUEZ"
    },
    {
        "codigoPre": 5449802356,
        "codigoSub": "1",
        "nombre": "juliana Patricia Chacon Gomez"
    },
    {
        "codigoPre": 5449802360,
        "codigoSub": "1",
        "nombre": "maria andrea perez ramirez"
    },
    {
        "codigoPre": 5449802362,
        "codigoSub": "1",
        "nombre": "FUNDACION OPCION VIDA"
    },
    {
        "codigoPre": 5449802378,
        "codigoSub": "1",
        "nombre": "ASISMEDIC S.A.S."
    },
    {
        "codigoPre": 5449802397,
        "codigoSub": "1",
        "nombre": "MARIO ALEJANDRO CONTRERAS GUTIERREZ DE PIC\u0011ERES"
    },
    {
        "codigoPre": 5449802403,
        "codigoSub": "1",
        "nombre": "O2VITAL S.A.S"
    },
    {
        "codigoPre": 5449802439,
        "codigoSub": "1",
        "nombre": "CONGREGACIC\u0013N HIJAS DE NUESTRA SEC\u0011ORA DE LA EUCARISTIA"
    },
    {
        "codigoPre": 5449802460,
        "codigoSub": "1",
        "nombre": "DUBYS SUHEY RINCON GUERRERO"
    },
    {
        "codigoPre": 5449802499,
        "codigoSub": "1",
        "nombre": "REH@BITAR OCAC\u0011A"
    },
    {
        "codigoPre": 5449802508,
        "codigoSub": "1",
        "nombre": "Carmensa Jaime Quintero"
    },
    {
        "codigoPre": 5449802542,
        "codigoSub": "1",
        "nombre": "EDGARDO ENRIQUE PABA GONZALEZ"
    },
    {
        "codigoPre": 5449802546,
        "codigoSub": "1",
        "nombre": "LEIDY MARCELA QUINTERO JAIME"
    },
    {
        "codigoPre": 5449802551,
        "codigoSub": "1",
        "nombre": "ROLANDO ENRIQUE PEC\u0011ALOZA GUALDRON"
    },
    {
        "codigoPre": 5449802569,
        "codigoSub": "1",
        "nombre": "CLC\rNICA ODONTOLOGICA ESPECIALIZADA PINZC\u0013N GROUP S.A.S."
    },
    {
        "codigoPre": 5449802587,
        "codigoSub": "1",
        "nombre": "HOSPICLINIC DE COLOMBIA S.A.S."
    },
    {
        "codigoPre": 5449802599,
        "codigoSub": "1",
        "nombre": "Juan Carlos Perez Parada"
    },
    {
        "codigoPre": 5449802628,
        "codigoSub": "1",
        "nombre": "INSTITUTO MEDICO DEL ORIENTE SAS"
    },
    {
        "codigoPre": 5449802637,
        "codigoSub": "1",
        "nombre": "MEDILAF S.A.S"
    },
    {
        "codigoPre": 5449802667,
        "codigoSub": "1",
        "nombre": "Natalia Yocasta Ramirez Herrera"
    },
    {
        "codigoPre": 5449802680,
        "codigoSub": "1",
        "nombre": "Rafael Dario Valencia Arenas"
    },
    {
        "codigoPre": 5449802682,
        "codigoSub": "1",
        "nombre": "YESIKA NATALY DIAZ GUERRERO"
    },
    {
        "codigoPre": 5449802683,
        "codigoSub": "1",
        "nombre": "ENRIQUE ALBERTO SILVA GOVEA"
    },
    {
        "codigoPre": 5449802730,
        "codigoSub": "1",
        "nombre": "Distrimateriales Loma S.A.S"
    },
    {
        "codigoPre": 5449802731,
        "codigoSub": "1",
        "nombre": "MARIA STEFANIA SALAZAR BLOISE"
    },
    {
        "codigoPre": 5449802738,
        "codigoSub": "1",
        "nombre": "Jose Luis Wilches Torrado"
    },
    {
        "codigoPre": 5449802771,
        "codigoSub": "1",
        "nombre": "Katherine Prado Garay"
    },
    {
        "codigoPre": 5449802776,
        "codigoSub": "1",
        "nombre": "CARLOS ANDRES VARGAS NIC\u0011O"
    },
    {
        "codigoPre": 5449802807,
        "codigoSub": "1",
        "nombre": "Sergio Andres Castillo Perez"
    },
    {
        "codigoPre": 5449802808,
        "codigoSub": "1",
        "nombre": "Eduardo Alejandro Gonzales Osorio"
    },
    {
        "codigoPre": 5449802809,
        "codigoSub": "1",
        "nombre": "Margarita Lucia Reina Cuevas"
    },
    {
        "codigoPre": 5449802850,
        "codigoSub": "1",
        "nombre": "CENTRO DE ATENCION MI RENACER S.A.S."
    },
    {
        "codigoPre": 5449802855,
        "codigoSub": "1",
        "nombre": "CMD Upgds CENTRO MEDICO Y DIAGNOSTICO DE OCAC\u0011A S.A.S."
    },
    {
        "codigoPre": 5449802856,
        "codigoSub": "1",
        "nombre": "M&S SOLUTIONS SAS"
    },
    {
        "codigoPre": 5449802870,
        "codigoSub": "1",
        "nombre": "MERLYS FRANCO RIOS"
    },
    {
        "codigoPre": 5449802872,
        "codigoSub": "1",
        "nombre": "NAZLY TATIANA ALVAREZ ALVAREZ"
    },
    {
        "codigoPre": 5449802893,
        "codigoSub": "1",
        "nombre": "YUDISAY MOLINA MORA"
    },
    {
        "codigoPre": 5449802895,
        "codigoSub": "1",
        "nombre": "JULIAN ANDRES QUINTERO CONTRERAS"
    },
    {
        "codigoPre": 5449802902,
        "codigoSub": "1",
        "nombre": "MAYRA MINELLY VERJEL HERNANDEZ"
    },
    {
        "codigoPre": 5449802930,
        "codigoSub": "1",
        "nombre": "MARIA FERNANDA SANCHEZ GUTIERREZ"
    },
    {
        "codigoPre": 5451800051,
        "codigoSub": "1",
        "nombre": "NIDIA LUCERO DUARTE SUAREZ"
    },
    {
        "codigoPre": 5451800082,
        "codigoSub": "1",
        "nombre": "JORGE JEOVANI GONZALEZ CASTRO"
    },
    {
        "codigoPre": 5451800110,
        "codigoSub": "1",
        "nombre": "PATRICIA GARCIA VILLAMIZAR"
    },
    {
        "codigoPre": 5451800146,
        "codigoSub": "1",
        "nombre": "ROSA EDITH RODRIGUEZ MENDOZA"
    },
    {
        "codigoPre": 5451800177,
        "codigoSub": "1",
        "nombre": "YOLANDA CONTRERAS DE MARQUEZ"
    },
    {
        "codigoPre": 5451800178,
        "codigoSub": "1",
        "nombre": "EDINSON HANS ARCINIEGAS SALAZAR"
    },
    {
        "codigoPre": 5451800179,
        "codigoSub": "1",
        "nombre": "ANA BENILDA GELVEZ RAMIREZ"
    },
    {
        "codigoPre": 5451800222,
        "codigoSub": "1",
        "nombre": "RUBEN DARIO BAUTISTA GAMBOA"
    },
    {
        "codigoPre": 5451800300,
        "codigoSub": "1",
        "nombre": "MANUEL EDUARDO CASTELLANOS SERRANO"
    },
    {
        "codigoPre": 5451800321,
        "codigoSub": 3,
        "nombre": "EDNA MILENA RIVERA LEAL"
    },
    {
        "codigoPre": 5451800336,
        "codigoSub": "1",
        "nombre": "MARIA TERESA ROSAL GARCIA"
    },
    {
        "codigoPre": 5451800343,
        "codigoSub": "1",
        "nombre": "CARLOS MIGUEL ALVAREZ ROJAS"
    },
    {
        "codigoPre": 5451800355,
        "codigoSub": "1",
        "nombre": "DORA ESTELLA CHAVEZ CABEZA"
    },
    {
        "codigoPre": 5451800356,
        "codigoSub": "1",
        "nombre": "LUIS ENRIQUE DUARTE CASTILLO"
    },
    {
        "codigoPre": 5451800358,
        "codigoSub": "1",
        "nombre": "LUIS ALBERTO COTE RIVERA"
    },
    {
        "codigoPre": 5451800359,
        "codigoSub": "1",
        "nombre": "CESAR ALFREDO CAICEDO GARCIA"
    },
    {
        "codigoPre": 5451800360,
        "codigoSub": "1",
        "nombre": "JORGE ARMANDO YANEZ RODRIGUEZ"
    },
    {
        "codigoPre": 5451800372,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL SAN JUAN DE DIOS DE PAMPLONA"
    },
    {
        "codigoPre": 5451800464,
        "codigoSub": "1",
        "nombre": "CENTRO MEDICO INTEGRAL SERVICIOS DE SALUD \"C.M.I.\" S.A."
    },
    {
        "codigoPre": 5451800477,
        "codigoSub": "1",
        "nombre": "gloria maria caicedo camargo"
    },
    {
        "codigoPre": 5451800478,
        "codigoSub": "1",
        "nombre": "EDDY AMPARO ACEVEDO CONTRERAS"
    },
    {
        "codigoPre": 5451800479,
        "codigoSub": "1",
        "nombre": "JULIO ANTONIO SALAMANCA GODOY"
    },
    {
        "codigoPre": 5451800538,
        "codigoSub": "1",
        "nombre": "SONIA PATRICIA SANTAFE PATIC\u0011O"
    },
    {
        "codigoPre": 5451800539,
        "codigoSub": "1",
        "nombre": "SOCIEDAD CLINICA PAMPLONA LTDA."
    },
    {
        "codigoPre": 5451800667,
        "codigoSub": "1",
        "nombre": "FANY PEC\u0011A JAIMES"
    },
    {
        "codigoPre": 5451800871,
        "codigoSub": "1",
        "nombre": "SIGFRIDO ENRIQUE BOBREK OROZCO"
    },
    {
        "codigoPre": 5451800872,
        "codigoSub": "1",
        "nombre": "JAVIER AUGUSTO ESTEVEZ PARADA"
    },
    {
        "codigoPre": 5451800958,
        "codigoSub": "1",
        "nombre": "UNIVERSIDAD DE PAMPLONA"
    },
    {
        "codigoPre": 5451801001,
        "codigoSub": "1",
        "nombre": "GLADYS ROJAS LINDARTE"
    },
    {
        "codigoPre": 5451801068,
        "codigoSub": "1",
        "nombre": "NEISON CALDERON ROJAS"
    },
    {
        "codigoPre": 5451801172,
        "codigoSub": 2,
        "nombre": "ASOCIACION DE PRESTADORES DE SERVICIOS Y SUMINISTROS DE SALUD - ASSALUD"
    },
    {
        "codigoPre": 5451801264,
        "codigoSub": "1",
        "nombre": "MARTHA LUCIA BERMUDEZ MUC\u0011OZ"
    },
    {
        "codigoPre": 5451801509,
        "codigoSub": "1",
        "nombre": "ROSA ANREINA SILVA BOHORQUEZ"
    },
    {
        "codigoPre": 5451801517,
        "codigoSub": 2,
        "nombre": "MARCOS HUMBERTO PISCIOTTI ORTEGA"
    },
    {
        "codigoPre": 5451801573,
        "codigoSub": "1",
        "nombre": "ERIKA PAOLA CARDENAS GOMEZ"
    },
    {
        "codigoPre": 5451801856,
        "codigoSub": "1",
        "nombre": "Fundacion Amiga del Paciente"
    },
    {
        "codigoPre": 5451801981,
        "codigoSub": "1",
        "nombre": "Laritza Lizbeth CaC1as Carrillo"
    },
    {
        "codigoPre": 5451801982,
        "codigoSub": "1",
        "nombre": "Claudia Rocio Gelvez Mendoza"
    },
    {
        "codigoPre": 5451801983,
        "codigoSub": "1",
        "nombre": "Natalia Patricia Vera Rodriguez"
    },
    {
        "codigoPre": 5451802029,
        "codigoSub": "1",
        "nombre": "JAIRO ALBERTO GONZALEZ CASTRO"
    },
    {
        "codigoPre": 5451802030,
        "codigoSub": "1",
        "nombre": "LUCIA JACKELINE BARRERA NAVARRETE"
    },
    {
        "codigoPre": 5451802078,
        "codigoSub": "1",
        "nombre": "leidy dayanna orozco monsalve"
    },
    {
        "codigoPre": 5451802099,
        "codigoSub": "1",
        "nombre": "yudith alexandra portilla cuellar"
    },
    {
        "codigoPre": 5451802166,
        "codigoSub": "1",
        "nombre": "OLGA LUCIA VARGAS GAYON"
    },
    {
        "codigoPre": 5451802228,
        "codigoSub": "1",
        "nombre": "ALICIA VILLAMIZAR DELGADO"
    },
    {
        "codigoPre": 5451802249,
        "codigoSub": "1",
        "nombre": "STEPHANY SIERRA VARGAS"
    },
    {
        "codigoPre": 5451802321,
        "codigoSub": "1",
        "nombre": "MONICA ANDREA MEJIA JAIMES"
    },
    {
        "codigoPre": 5451802407,
        "codigoSub": "1",
        "nombre": "CENTRO INTEGRAL DE DIAGNOSTICO MEDICO I.P.S. S.A.S."
    },
    {
        "codigoPre": 5451802431,
        "codigoSub": "1",
        "nombre": "NYDIA MILENA CAICEDO SUAREZ"
    },
    {
        "codigoPre": 5451802513,
        "codigoSub": "1",
        "nombre": "Claudia Patricia Porras Rozo"
    },
    {
        "codigoPre": 5451802517,
        "codigoSub": "1",
        "nombre": "Alexander Luis Reyes NuC1ez"
    },
    {
        "codigoPre": 5451802545,
        "codigoSub": "1",
        "nombre": "RENNY JAIMES BELTRAN"
    },
    {
        "codigoPre": 5451802553,
        "codigoSub": "1",
        "nombre": "FabiC!n Mauricio Leal Jaimes"
    },
    {
        "codigoPre": 5451802554,
        "codigoSub": "1",
        "nombre": "JUDITH PATRICIA MORALES VELANDIA"
    },
    {
        "codigoPre": 5451802629,
        "codigoSub": "1",
        "nombre": "Instituto superior de educacion rural"
    },
    {
        "codigoPre": 5451802663,
        "codigoSub": "1",
        "nombre": "MARIA NEIDA PARRA RODRIGUEZ"
    },
    {
        "codigoPre": 5451802666,
        "codigoSub": "1",
        "nombre": "DIEGO ANDRES DUARTE ORTEGA"
    },
    {
        "codigoPre": 5451802703,
        "codigoSub": "1",
        "nombre": "NIDYA GISELA RAMON SALCEDO"
    },
    {
        "codigoPre": 5451802737,
        "codigoSub": "1",
        "nombre": "SULVEY MARINA CAPACHO SALAZAR"
    },
    {
        "codigoPre": 5451802748,
        "codigoSub": "1",
        "nombre": "MIRIAM KATERINA CONTRERAS AVENDAC\u0011O"
    },
    {
        "codigoPre": 5451802753,
        "codigoSub": "1",
        "nombre": "Franklin Alberto Contreras Perez"
    },
    {
        "codigoPre": 5451802815,
        "codigoSub": "1",
        "nombre": "Yenifer Yuliana Mogollon Cabeza"
    },
    {
        "codigoPre": 5451802824,
        "codigoSub": "1",
        "nombre": "HELP TRAUMA SALUD Y ORTOPEDIA Upgds SAS"
    },
    {
        "codigoPre": 5451802837,
        "codigoSub": "1",
        "nombre": "Yelitze CastaC1o Zapata"
    },
    {
        "codigoPre": 5451802879,
        "codigoSub": "1",
        "nombre": "roque luis jordan zapata"
    },
    {
        "codigoPre": 5455300104,
        "codigoSub": "1",
        "nombre": "MARIALENA GALINDO MARQUEZ"
    },
    {
        "codigoPre": 5455301976,
        "codigoSub": "1",
        "nombre": "jesus alirio echavez becerra"
    },
    {
        "codigoPre": 5467302612,
        "codigoSub": 2,
        "nombre": "RONALD ARMANDO RAMIREZ CRISTANCHO"
    },
    {
        "codigoPre": 5472001683,
        "codigoSub": "1",
        "nombre": "CARLOS RANGEL PEC\u0011ARANDA"
    },
    {
        "codigoPre": 5472001835,
        "codigoSub": "1",
        "nombre": "Upgds ARANASALUD S.A.S."
    },
    {
        "codigoPre": 5472002001,
        "codigoSub": "1",
        "nombre": "LYDIA MARINA RANGEL GONZALEZ"
    },
    {
        "codigoPre": 5472002620,
        "codigoSub": "1",
        "nombre": "Eliana Juliette Blanco PeC1aranda"
    },
    {
        "codigoPre": 5474302401,
        "codigoSub": "1",
        "nombre": "Edwin velasco carrillo"
    },
    {
        "codigoPre": 5480002156,
        "codigoSub": "1",
        "nombre": "DROGUERIA MAGRETH S.A.S."
    },
    {
        "codigoPre": 5481000036,
        "codigoSub": "1",
        "nombre": "SERVICIOS INTEGRALES DE SALUD LIMITADA"
    },
    {
        "codigoPre": 5481001073,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL REGIONAL NORTE"
    },
    {
        "codigoPre": 5481001219,
        "codigoSub": "1",
        "nombre": "CUERPO DE BOMBEROS VOLUNTARIOS DE TIBU"
    },
    {
        "codigoPre": 5481001497,
        "codigoSub": "1",
        "nombre": "Upgds CENTRO CLINICO INTEGRAL TIBU LTDA"
    },
    {
        "codigoPre": 5481001611,
        "codigoSub": "1",
        "nombre": "JORGE LEONARDO REYES LOPEZ"
    },
    {
        "codigoPre": 5481001612,
        "codigoSub": "1",
        "nombre": "DIANA PATRICIA FONSECA BERNAL"
    },
    {
        "codigoPre": 5481002471,
        "codigoSub": "1",
        "nombre": "CENTRO DE REHABILITACIC\u0013N INTEGRAL SAN LUIS BELTRAN S.A.S."
    },
    {
        "codigoPre": 5481002760,
        "codigoSub": "1",
        "nombre": "Nhora Solangy Rojas Olivares"
    },
    {
        "codigoPre": 5481002761,
        "codigoSub": "1",
        "nombre": "JUAN CARLOS CACERES MEDINA"
    },
    {
        "codigoPre": 5481002775,
        "codigoSub": "1",
        "nombre": "Evelyn Isolmar Diaz Rueda"
    },
    {
        "codigoPre": 5481002848,
        "codigoSub": "1",
        "nombre": "Mayerly Andrea Triana Martinez"
    },
    {
        "codigoPre": 5481002877,
        "codigoSub": "1",
        "nombre": "JULIO CESAR ORBEGOZO MEDINA"
    },
    {
        "codigoPre": 5482000090,
        "codigoSub": "1",
        "nombre": "JESUS MANTILLA MARIC\u0011O"
    },
    {
        "codigoPre": 5482000347,
        "codigoSub": "1",
        "nombre": "Judith Valencia Jaimes"
    },
    {
        "codigoPre": 5482000659,
        "codigoSub": "1",
        "nombre": "ALEXANDRA DEL PILAR RANGEL CARRILLO"
    },
    {
        "codigoPre": 5482001384,
        "codigoSub": "1",
        "nombre": "NYDIA MARIA GRANADOS VILLAMIZAR"
    },
    {
        "codigoPre": 5482001432,
        "codigoSub": "1",
        "nombre": "MAIRA LORENA OCHOA MEDINA"
    },
    {
        "codigoPre": 5482001977,
        "codigoSub": "1",
        "nombre": "LINNEY YAJAIRA DAZA TORRES"
    },
    {
        "codigoPre": 5482002279,
        "codigoSub": "1",
        "nombre": "Upgds SAN FRANCISCO TOLEDO S.A.S"
    },
    {
        "codigoPre": 5482002302,
        "codigoSub": 2,
        "nombre": "karen lizeth prado valdez"
    },
    {
        "codigoPre": 5482002750,
        "codigoSub": "1",
        "nombre": "LIZETH KARINA CARRILLO PARADA"
    },
    {
        "codigoPre": 5487102363,
        "codigoSub": "1",
        "nombre": "MARIA ALEJANDRA HERNANDEZ GONZALEZ"
    },
    {
        "codigoPre": 5487400546,
        "codigoSub": "1",
        "nombre": "SERGIO IVAN GELVEZ AROCHA"
    },
    {
        "codigoPre": 5487400802,
        "codigoSub": "1",
        "nombre": "UNIMEDICA S.A.S."
    },
    {
        "codigoPre": 5487400973,
        "codigoSub": "1",
        "nombre": "ESE HOSPITAL LOCAL JORGE CRISTO SAHIUM VILLA DEL ROSARIO"
    },
    {
        "codigoPre": 5487401671,
        "codigoSub": "1",
        "nombre": "FABIAN LEONARDO GELVEZ MARTINEZ"
    },
    {
        "codigoPre": 5487401754,
        "codigoSub": "1",
        "nombre": "INTEGRADOS EN SALUD Upgds LTDA"
    },
    {
        "codigoPre": 5487401787,
        "codigoSub": "1",
        "nombre": "MARIA INES VELANDIA DELGADO"
    },
    {
        "codigoPre": 5487401863,
        "codigoSub": "1",
        "nombre": "JAVIER ALEXIS ALVAREZ ARCINIEGAS"
    },
    {
        "codigoPre": 5487402323,
        "codigoSub": "1",
        "nombre": "Ramiro Corzo QuiC1onez"
    },
    {
        "codigoPre": 5487402434,
        "codigoSub": "1",
        "nombre": "CAROLINA ISABEL GARZA RAMIREZ"
    },
    {
        "codigoPre": 5487402518,
        "codigoSub": "1",
        "nombre": "INVERSIONES EN SEGURIDAD Y SALUD S.A.S"
    },
    {
        "codigoPre": 5487402530,
        "codigoSub": "1",
        "nombre": "WALTER JULIAN ARANGO MONTAGUT"
    },
    {
        "codigoPre": 5487402580,
        "codigoSub": "1",
        "nombre": "Leyda Yurley Sayago Prato"
    },
    {
        "codigoPre": 5487402736,
        "codigoSub": "1",
        "nombre": "Fabian Eduardo Melendez Ceballos"
    },
    {
        "codigoPre": 5487402757,
        "codigoSub": "1",
        "nombre": "FERNANDO ENRIQUE RODRIGUEZ RODRIGUEZ"
    },
    {
        "codigoPre": 5487402769,
        "codigoSub": "1",
        "nombre": "BRISMAN SOLIMAR MEDINA PERALTA"
    },
    {
        "codigoPre": 5487402773,
        "codigoSub": "1",
        "nombre": "Karen Julieth Garcia Garcia"
    },
    {
        "codigoPre": 5487402777,
        "codigoSub": "1",
        "nombre": "MARITZA COROMOTO CHACON PORTILLA"
    },
    {
        "codigoPre": 5487402812,
        "codigoSub": "1",
        "nombre": "magdely yusledy rondon melendez"
    },
    {
        "codigoPre": 5487402813,
        "codigoSub": "1",
        "nombre": "LUZ ENEIDA RUIZ DURAN"
    },
    {
        "codigoPre": 5487402816,
        "codigoSub": "1",
        "nombre": "carlos julio ocariz silva"
    },
    {
        "codigoPre": 5487402817,
        "codigoSub": "1",
        "nombre": "DIOCESIS DE CUCUTA"
    },
    {
        "codigoPre": 5487402862,
        "codigoSub": "1",
        "nombre": "Ivonne Viviana Castellanos Herrera"
    },
    {
        "codigoPre": 5487402876,
        "codigoSub": "1",
        "nombre": "KARLA MINELLI NEIRA GAMBOA"
    },
    {
        "codigoPre": 5487402880,
        "codigoSub": "1",
        "nombre": "ANTONIO RICAURTE AGUIRRE MANOTAS"
    },
    {
        "codigoPre": 5487402914,
        "codigoSub": "1",
        "nombre": "Luz Mallely Luna Mendieta"
    },
    {
        "codigoPre": 5487402916,
        "codigoSub": "1",
        "nombre": "PAOLA ISABEL RUIZ TORRES"
    },
    {
        "codigoPre": 5487402951,
        "codigoSub": "1",
        "nombre": "JENNIFER STEFANY SEQUEDA FAJARDO"
    }
];


export default UPGDS;
