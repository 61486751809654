const DIVIPOLA = [
    {codigoDepartamento: "54", codigoMunicipio: "54003", departamento: "Norte de Santander", municipio: "Ábrego"},
    {codigoDepartamento: "54", codigoMunicipio: "54051", departamento: "Norte de Santander", municipio: "Arboledas"},
    {codigoDepartamento: "54", codigoMunicipio: "54099", departamento: "Norte de Santander", municipio: "Bochalema"},
    {codigoDepartamento: "54", codigoMunicipio: "54109", departamento: "Norte de Santander", municipio: "Bucarasica"},
    {codigoDepartamento: "54", codigoMunicipio: "54128", departamento: "Norte de Santander", municipio: "Cáchira"},
    {codigoDepartamento: "54", codigoMunicipio: "54125", departamento: "Norte de Santander", municipio: "Cácota"},
    {codigoDepartamento: "54", codigoMunicipio: "54172", departamento: "Norte de Santander", municipio: "Chinácota"},
    {codigoDepartamento: "54", codigoMunicipio: "54174", departamento: "Norte de Santander", municipio: "Chitagá"},
    {codigoDepartamento: "54", codigoMunicipio: "54206", departamento: "Norte de Santander", municipio: "Convención"},
    {codigoDepartamento: "54", codigoMunicipio: "54001", departamento: "Norte de Santander", municipio: "Cúcuta"},
    {codigoDepartamento: "54", codigoMunicipio: "54223", departamento: "Norte de Santander", municipio: "Cucutilla"},
    {codigoDepartamento: "54", codigoMunicipio: "54239", departamento: "Norte de Santander", municipio: "Durania"},
    {codigoDepartamento: "54", codigoMunicipio: "54245", departamento: "Norte de Santander", municipio: "El Carmen"},
    {codigoDepartamento: "54", codigoMunicipio: "54250", departamento: "Norte de Santander", municipio: "El Tarra"},
    {codigoDepartamento: "54", codigoMunicipio: "54261", departamento: "Norte de Santander", municipio: "El Zulia"},
    {codigoDepartamento: "54", codigoMunicipio: "54313", departamento: "Norte de Santander", municipio: "Gramalote"},
    {codigoDepartamento: "54", codigoMunicipio: "54344", departamento: "Norte de Santander", municipio: "Hacarí"},
    {codigoDepartamento: "54", codigoMunicipio: "54347", departamento: "Norte de Santander", municipio: "Herrán"},
    {codigoDepartamento: "54", codigoMunicipio: "54385", departamento: "Norte de Santander", municipio: "La Esperanza"},
    {codigoDepartamento: "54", codigoMunicipio: "54398", departamento: "Norte de Santander", municipio: "La Playa"},
    {codigoDepartamento: "54", codigoMunicipio: "54377", departamento: "Norte de Santander", municipio: "Labateca"},
    {codigoDepartamento: "54", codigoMunicipio: "54405", departamento: "Norte de Santander", municipio: "Los Patios"},
    {codigoDepartamento: "54", codigoMunicipio: "54418", departamento: "Norte de Santander", municipio: "Lourdes"},
    {codigoDepartamento: "54", codigoMunicipio: "54480", departamento: "Norte de Santander", municipio: "Mutiscua"},
    {codigoDepartamento: "54", codigoMunicipio: "54498", departamento: "Norte de Santander", municipio: "Ocaña"},
    {codigoDepartamento: "54", codigoMunicipio: "54518", departamento: "Norte de Santander", municipio: "Pamplona"},
    {codigoDepartamento: "54", codigoMunicipio: "54520", departamento: "Norte de Santander", municipio: "Pamplonita"},
    {codigoDepartamento: "54", codigoMunicipio: "54553", departamento: "Norte de Santander", municipio: "Puerto Santander"},
    {codigoDepartamento: "54", codigoMunicipio: "54599", departamento: "Norte de Santander", municipio: "Ragonvalia"},
    {codigoDepartamento: "54", codigoMunicipio: "54660", departamento: "Norte de Santander", municipio: "Salazar"},
    {codigoDepartamento: "54", codigoMunicipio: "54670", departamento: "Norte de Santander", municipio: "San Calixto"},
    {codigoDepartamento: "54", codigoMunicipio: "54673", departamento: "Norte de Santander", municipio: "San Cayetano"},
    {codigoDepartamento: "54", codigoMunicipio: "54680", departamento: "Norte de Santander", municipio: "Santiago"},
    {codigoDepartamento: "54", codigoMunicipio: "54720", departamento: "Norte de Santander", municipio: "Sardinata"},
    {codigoDepartamento: "54", codigoMunicipio: "54743", departamento: "Norte de Santander", municipio: "Silos"},
    {codigoDepartamento: "54", codigoMunicipio: "54800", departamento: "Norte de Santander", municipio: "Teorama"},
    {codigoDepartamento: "54", codigoMunicipio: "54810", departamento: "Norte de Santander", municipio: "Tibú"},
    {codigoDepartamento: "54", codigoMunicipio: "54820", departamento: "Norte de Santander", municipio: "Toledo"},
    {codigoDepartamento: "54", codigoMunicipio: "54871", departamento: "Norte de Santander", municipio: "Villa Caro"},
    {codigoDepartamento: "54", codigoMunicipio: "54874", departamento: "Norte de Santander", municipio: "Villa del Rosario"},

];

export default DIVIPOLA;