import {Alert, Button, Card, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";

import tiposIdentificacion from "../../../helpers/tiposIdentificacion";
import personCreateResolver from "../../../validations/personCreateResolver";
import {useEffect, useState} from "react";
import useAuth from "../../../auth/useAuth";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const CreatePerson = ({location}) => {

    const [identificacion] = useState(location.state);

    const {createPatient} = useAuth();

    const history = useHistory();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personCreateResolver});
    const {errors} = formState;

    const onSubmit = async (formData) => {

        const dividirTipoDocumento = formData.tipoDocumento.split(' - ');
        formData.tipoDocumento = dividirTipoDocumento[1];

        const persona = await createPatient(formData);
        // Luego de crear la persona direccionar a crear la muestra
        if(persona){
            toast.success('Persona creada exitosamente, ya puede asocia una muestra a la persona...')
            reset();
            // redireccion para asociar muestra
            history.push({
                pathname: '/persona/home'
            })
        } else {
            toast.error('Error al crear la persona')
        }


    }

    useEffect(() => {
        if(identificacion) reset({
            numDocumento: identificacion,
        });
    }, [reset, identificacion])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3 className="text-center my-3"> Crear persona</h3>
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '400px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Tipo de documento</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoDocumento")}
                                >
                                    <option value="">Seleccione un tipo</option>
                                    {tiposIdentificacion.map( tipo => <option key={tipo.sigla}>{tipo.tipo} - {tipo.sigla}</option> )}
                                </Form.Control>
                                {errors?.tipoDocumento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoDocumento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Identificación</Form.Label>
                                <Form.Control
                                    disabled={true}
                                    placeholder="Escriba la identificación"
                                    {...register("numDocumento")}
                                    type="text"
                                />
                                {errors?.numDocumento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.numDocumento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Primer nombre</Form.Label>
                                <Form.Control
                                    placeholder="Escriba primer nombre"
                                    {...register("primerNombre")}
                                    type="text"
                                />
                                {errors?.primerNombre && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.primerNombre.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Primer apellido</Form.Label>
                                <Form.Control
                                    placeholder="Escriba primer apellido"
                                    {...register("primerApellido")}
                                    type="text"
                                />
                                {errors?.primerApellido && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.primerApellido.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Crear persona</Button>
                        </Form>
                    </Card>
                </div>
            </div>

        </div>
    );
};

export default CreatePerson;