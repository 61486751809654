import React from 'react';
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import routes from "../../helpers/routes";

const CovidHomePage = () => {

    const {getPatient} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {

        const persona = await getPatient(formData.identificacion)
        if(persona) {
            toast.success('El paciente existe, cree la muestra a continuación...')
            //console.log('Existe, asociar muestra a la identificacion de la persona')
            reset();
            // redireccion para asociar muestra
            history.push({
                pathname: routes.muestra.covid.crear,
                state: persona
            })

        } else {
            toast.warn('El paciente no existe')
        }

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Crear muestra Covid</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación del paciente</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar muestra(s) del paciente</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

export default CovidHomePage;