import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import tiposIdentificacion from "../helpers/tiposIdentificacion";


const schema = yup.object().shape({
    tipoDocumento: yup
        .string("El tipo de identificación debe ser un texto")
        .required("Debe seleccionar un tipo de identificación")
        .oneOf(tiposIdentificacion.map( tipo => (`${tipo.tipo} - ${tipo.sigla}`)), "El tipo de identificación no es válido, elija uno"),
    numDocumento: yup
        .string("La identificación debe ser un texto")
        .required("Debe ingresar la identificación"),
    primerNombre: yup
        .string("El primer nombre debe ser un texto")
        .required("Debe ingresar el primer nombre"),
    primerApellido: yup
        .string("El primer apellido debe ser un texto")
        .required("Debe ingresar el primer apellido")
   })

export default yupResolver(schema)