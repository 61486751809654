import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import BRIGADA from "../helpers/brigada";

const schema = yup.object().shape({

    fecha: yup
        .date()
        .typeError('Ingresa una fecha DD/MM/AAA')
        .required("Debe ingresar fecha de prueba"),
    brigada: yup
        .string('El lugar es un string')
        .required("Debe seleccionar un lugar")
        .oneOf(BRIGADA.map( brigada => (`${brigada}`)), "El lugar no es válido, elija uno"),

   })

export default yupResolver(schema)