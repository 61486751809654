

const SumaryPage = () => {
    //console.log('Descargar los datos del día')

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col text-center"><h3>Antígenos Cúcuta</h3></div>
            </div>
            <div className="row mb-4">
                <div className="col-6">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen de hoy</h3>
                        <p className="small">Muestras ingresadas: </p>
                        <p className="small">Muestras procesadas: </p>
                        <p className="small">Muestras positivas:</p>
                        <p className="small">Muestras negativas:</p>
                        <p className="small">Muestras en procesamiento:</p>
                        <p className="small">Muestras sin procesar:</p>
                        <p className="small">Muestras indeterminadas:</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen Total</h3>
                        <p className="small">Muestras procesadas: </p>
                        <p className="small">Muestras positivas:</p>
                        <p className="small">Muestras negativas:</p>
                        <p className="small">Muestras en procesamiento:</p>
                        <p className="small">Muestras sin procesar:</p>
                        <p className="small">Muestras indeterminadas:</p>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col text-center"><h3>Antígenos Pamplona</h3></div>
            </div>
            <div className="row mb-4">
                <div className="col-6">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen de hoy</h3>
                        <p className="small">Muestras ingresadas: </p>
                        <p className="small">Muestras procesadas: </p>
                        <p className="small">Muestras positivas:</p>
                        <p className="small">Muestras negativas:</p>
                        <p className="small">Muestras en procesamiento:</p>
                        <p className="small">Muestras sin procesar:</p>
                        <p className="small">Muestras indeterminadas:</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen Total</h3>
                        <p className="small">Muestras procesadas: </p>
                        <p className="small">Muestras positivas:</p>
                        <p className="small">Muestras negativas:</p>
                        <p className="small">Muestras en procesamiento:</p>
                        <p className="small">Muestras sin procesar:</p>
                        <p className="small">Muestras indeterminadas:</p>
                    </div>
                </div>
            </div>
            {/*<div className="row">
                <div className="col">
                    <iframe title="01" src="https://ourworldindata.org/explorers/coronavirus-data-explorer?facet=none&Metric=Confirmed+cases&Interval=Weekly&Relative+to+Population=true&Align+outbreaks=false&country=~COL&hideControls=true" loading="lazy" width="100%" height="600px"></iframe>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <iframe title="02" src="https://ourworldindata.org/grapher/total-cases-covid-19?tab=map" width="100%" height="600px"></iframe>
                </div>
            </div>*/}









        </div>
    );
};

export default SumaryPage;