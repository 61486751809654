import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import tiposMuestra from "../helpers/tiposMuestra";

const schema = yup.object().shape({
    consecutivo: yup
        .number("El debe ser un consecutivo")
        .positive("No puede ser número negativo")
        .integer("No puede ser un decimal")
        .typeError('Debe ingresar un consecutivo')
        .required("Debe ingresar un consecutivo"),

    fechaMuestra: yup
        .date()
        .typeError('Ingresa una fecha DD/MM/AAA'),

/*    horaMuestra: yup
        .number("Debe ingresar un número")
        .positive("No puede ser número negativo")
        .integer("No puede ser un decimal")
        .typeError('Debe ingresar la hora')
        .min('00', 'La hora debe ser igual o mayor a 00')
        .max('23', 'La hora debe ser menor o igual a 23')
        .required("Debe ingresar un consecutivo"),

    minMuestra: yup
        .number("Debe ingresar un número")
        .positive("No puede ser número negativo")
        .integer("No puede ser un decimal")
        .typeError('Debe ingresar el minuto')
        .min('00', 'El min debe ser igual o mayor a 00')
        .max('59', 'El min debe ser menor o igual a 59')
        .required("Debe ingresar un consecutivo"),*/
    tipoMuestra: yup
        .string("El tipo de muestra debe ser un texto")
        .required("Debe seleccionar un tipo de muestra")
        .oneOf(tiposMuestra.map( tipo => (`${tipo}`)), "El tipo de muestra no es válido, elija uno"),
   })

export default yupResolver(schema)