import React from 'react';
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import roles from "../../helpers/roles";
import routes from "../../helpers/routes";

const CovidListPage = ({location}) => {
    const persona = location.state;
    const {covid} = persona;

    const {getSample, hasRole} = useAuth();
    console.log('ROLE', hasRole(roles.administrador))

    const history = useHistory();


    const handleEditar = async (idMuestra) => {

        // llamar a la api por los datos de la muestra, capturarlos y redireccionar a SivigilaUpdatePage
        const muestra = await getSample(idMuestra);
        if(muestra){
            history.push({
                pathname: routes.muestra.covid.actualizar,//'/muestra/actualizar',
                state: {muestra, persona}
            })
        } else {
            toast.error('Error al buscar la muestra')
        }


        // Despues de editar ma muestra redireccionar a este listado y actualizar las muestras
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card border-0 shadow p-3 mt-4">
                        <h3 className="text-center">Listado de muestras Covid</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card border-0 shadow p-3 my-3">
                        <p><span className="font-weight-bold">Paciente:</span> {persona.primerNombre} {persona.primerApellido}</p>
                        <p><span className="font-weight-bold">Identificación:</span> {persona.numDocumento}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ul className="list-group">
                        {
                            covid.map((examen, cont) => (
                                <li className="list-group-item col-md-8 card border-0 shadow mb-2"key={examen.identificador}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        {cont + 1}) <span className="font-weight-bold">Fecha de la muestra: </span>
                                                        <span className="mx-2">
                                                        {new Date(examen.fechaCreacionMuestra).getDate()}/
                                                            {new Date(examen.fechaCreacionMuestra).getMonth()+1}/
                                                            {new Date(examen.fechaCreacionMuestra).getFullYear()} - {new Date(examen.fechaCreacionMuestra).getHours()}:
                                                            {new Date(examen.fechaCreacionMuestra).getMinutes()} Hrs
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="text-right">
                                                        <button className="btn btn-sm btn-warning mx-2" onClick={() => handleEditar(examen.identificador)}>Editar</button>
                                                        <button disabled={!hasRole(roles.administrador)} className="btn btn-sm btn-danger mx-2" data-toggle="tooltip" data-placement="right" title="Solo el Admin puede eliminar muestras">Eliminar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CovidListPage;