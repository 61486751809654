import {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {getFechaHoy} from "../../helpers/calcularFecha";
import sampleAntigenoCreateResolver from "../../validations/sampleAntigenoCreateResolver";
import NOMBREPRUEBA from "../../helpers/nombrePrueba";
import TIPOSPRUEBA from "../../helpers/tiposPrueba";
import BRIGADA from "../../helpers/brigada";


const AntigenoCreatePage = ({location}) => {

    const history = useHistory();
    const {createSampleAntigeno} = useAuth();
    const [patient] = useState(location.state);
    const [fecha, setFecha] = useState('ninguna');
    const [fechaHoy] = useState(getFechaHoy());
    const [rojo, setRojo] = useState(false)
    const {antigeno} = patient;



    useEffect(() => {

        if(antigeno.length > 0 && fechaHoy){
            const ultimo = antigeno[antigeno.length - 1];

            const {fechaCreacionMuestra} = ultimo;
            const date = new Date(fechaCreacionMuestra);

            let anioU = date.getFullYear('America/Bogota') + '';
            let mesU = date.getMonth('America/Bogota') + 1 + '';
            let diaU = date.getDate('America/Bogota') + '';

            diaU = diaU.length === 1 ? '0' + diaU : diaU;
            mesU = mesU.length === 1 ? '0' + mesU : mesU;

            setFecha(`${diaU}/${mesU}/${anioU}`)

            if(fechaHoy === fecha){
                setRojo(true)
                toast.error('Tenga precaución para no repetir muestras al paciente, ya tiene creada muestra con fecha de hoy')
            } else {
                setRojo(false)
            }

        }
    },[antigeno, fecha, fechaHoy])


    const {register, handleSubmit, formState, reset} = useForm({resolver: sampleAntigenoCreateResolver});
    const {errors} = formState;


    const onSubmit = async (formData) => {
        formData.numDocumento = patient.numDocumento;
        const fecha = formData.fechaPrueba;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fechaPrueba = `${dia}/${mes}/${anio}`

        // Enviar al servidor con cedula del paciente y asociar la muestra
        //const muestra = await createSampleCovid(formData);
        const muestra = await createSampleAntigeno(formData);

        if(muestra){
            toast.success('Muestra asociada exitosamente')
            reset();

            // redireccion para asociar muestra
            history.push({
                pathname: '/sumary',
            })
        } else {
            toast.error('Error al asociar la muestra')
        }

    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className="card shadow border-0 mt-3">
                        <h3 className="text-center my-3">Asociar muestra Antígeno al paciente</h3>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mt-3">

                </div>
                <div className="col-md-4 mt-3">
                    <div className="card shadow border-0 mt2 p-3">
                        <h6 className="font-weight-bold text-center">Datos del paciente:</h6>
                        <small className="font-weight-bold text-center">{patient.tipoDocumento}: {patient.numDocumento} - {patient.primerNombre} {patient.primerApellido}</small>
                        <small className="font-weight-bold text-center">Muestras asociadas al paciente: {patient.antigeno && patient.antigeno.length}</small>
                        <small className="font-weight-bold text-center">Fecha de la ultima muestra: <span className={ rojo ? 'text-danger': 'text-dark'}>{patient.covid && fecha}</span></small>
                    </div>

                    <div className="card shadow border-0 mt-2 p-3">
                        <Form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row">
                                <div className="col-5 text-center">
                                    <Form.Group>
                                        <Form.Label className="small font-weight-bold">Consecutivo</Form.Label>
                                        <Form.Control
                                            placeholder="#"
                                            type="number"
                                            {...register("consecutivo")}
                                        />
                                        {errors?.consecutivo && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.consecutivo.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-7 text-center">
                                    <Form.Group>
                                        <Form.Label className="small font-weight-bold">Lugar</Form.Label>
                                        <Form.Control
                                            as="select"
                                            {...register("brigada")}
                                        >
                                            <option value="">Lugar</option>
                                            {BRIGADA.map( brigada => <option key={brigada}>{brigada}</option> )}
                                        </Form.Control>
                                        {errors?.brigada && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.brigada.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>


                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Fecha de prueba</Form.Label>
                                <Form.Control
                                    placeholder="Escriba la fecha de prueba"
                                    {...register("fechaPrueba")}
                                    type="date"
                                />
                                {errors?.fechaPrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fechaPrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Nombre de prueba</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("nombrePrueba")}
                                >
                                    <option value="">Seleccione una prueba</option>
                                    {NOMBREPRUEBA.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.nombrePrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.nombrePrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Tipo de prueba</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoPrueba")}
                                >
                                    <option value="">Seleccione tipo</option>
                                    {TIPOSPRUEBA.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.tipoPrueba && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoPrueba.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>


                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Asociar muestra a paciente</Button>
                        </Form>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default AntigenoCreatePage;