const tiposIdentificacion = [
    {tipo: 'Número de identificación tributario', sigla: 'NIT'},
    {tipo: 'Cédula de ciudadania', sigla: 'CC'},
    {tipo: 'Cédula de extranjeria', sigla: 'CE'},
    {tipo: 'Carné diplomático', sigla: 'CD'},
    {tipo: 'Pasaporte', sigla: 'PA'},
    {tipo: 'Permiso especial de permanencia', sigla: 'PE'},
    {tipo: 'Residente especial para la paz', sigla: 'RE'},
    {tipo: 'Tarjeta de identidad', sigla: 'TI'},
    {tipo: 'Salvoconducto', sigla: 'SA'},
    {tipo: 'Registro civil', sigla: 'RC'},
    {tipo: 'Certificado de nacido vivo', sigla: 'CN'},
    {tipo: 'Adulto sin identificar', sigla: 'AS'},
    {tipo: 'Menor sin identificar', sigla: 'MS'}
];

export default tiposIdentificacion;