import React from 'react';
import {Alert} from "react-bootstrap";




const ResultadosAntigenoListPage = ({location}) => {

    const { resultados, fechaRes } = location.state;

    return(
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3 className="text-center my-3">Listado de resultados antígeno</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="card border-0 shadow p-3 my-3">
                        <p><span className="font-weight-bold">Fecha:</span> {fechaRes}</p>
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col">
                    <div className="p-1">
                        <ul className="list-group">
                                {
                                    resultados.map((resultado) => (
                                        <li className="list-group-item card border-0 shadow mb-2"key={resultado.id}>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">
                                                            <div>
                                                                <span className="small font-weight-bold"> Consecutivo: </span><span className="small">{resultado.consecutivo}</span> <span className="small font-weight-bold">Lugar: </span><span className="small">{resultado.brigada}</span> <span className="small font-weight-bold">Fecha:</span>
                                                                <span className="small mx-2">
                                                {new Date(resultado.fechaCreacion).getDate()}/
                                                                    {new Date(resultado.fechaCreacion).getMonth()+1}/
                                                                    {new Date(resultado.fechaCreacion).getFullYear()} - {(new Date(resultado.fechaCreacion).getHours()).toString().length === 1 ? `0${new Date(resultado.fechaCreacion).getHours()}`: new Date(resultado.fechaCreacion).getHours()}:
                                                                    {(new Date(resultado.fechaCreacion).getMinutes()).toString().length === 1 ? `0${new Date(resultado.fechaCreacion).getMinutes()}` : new Date(resultado.fechaCreacion).getMinutes()} Hrs,</span><span className="small font-weight-bold">Documento: </span> <span className="small">{resultado.tipoIdentificacion} - {resultado.numDocumento}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 my-auto">
                                                            <div className="text-center">
                                                                { !resultado.resultado ?
                                                                    <Alert variant="danger">
                                                                        <span className="text-center small font-weight-bold">No tiene resultado</span>
                                                                    </Alert>
                                                                    :
                                                                    <Alert variant="success">
                                                                        <span className="small font-weight-bold">{resultado.resultado}</span>
                                                                    </Alert>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    ))
                                }

                        </ul>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default ResultadosAntigenoListPage;