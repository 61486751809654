import {Alert, Button, Form} from "react-bootstrap";
import tiposIdentificacion from "../../../helpers/tiposIdentificacion";
import React, {useEffect, useState} from "react";
import useAuth from "../../../auth/useAuth";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import genero from "../../../helpers/genero";
import paises from "../../../helpers/paises";
import divipola from "../../../helpers/departamento";
import personUpdateResolver from "../../../validations/personUpdateResolver";
import {toast} from "react-toastify";
import EAPBS from "../../../helpers/eapbs";
import REGIMENES from "../../../helpers/regimenes";



const UpdatePerson = ({location}) => {

    const [persona] = useState(location.state);
    const [numDocumentoAnterior] = useState(persona.numDocumento)

    const {updatePatient} = useAuth();

    const history = useHistory();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personUpdateResolver});
    const {errors, dirtyFields} = formState;
    const isDirty = !!Object.keys(dirtyFields).length;

    useEffect(() => {
        if(persona) {
            const dividirTipoDocumento = persona.tipoDocumento?.split(' - ');
            let infoTipoDocumento = '';
            if(dividirTipoDocumento){
                infoTipoDocumento = tiposIdentificacion.find(tipoDocumentos => tipoDocumentos.sigla === dividirTipoDocumento[0] && tipoDocumentos.nombre === dividirTipoDocumento[1]);
            }


            let nacimiento = undefined;
            let anioN = undefined;
            let mesN = undefined;
            let diaN = undefined;

            if(persona.fechaNacimiento !== undefined && persona.fechaNacimiento !== '') {
                nacimiento = persona.fechaNacimiento.replace('/', '-');
                nacimiento = nacimiento.replace('/', '-');
                anioN = nacimiento.slice(0, 4);
                mesN = nacimiento.slice(5, 7);
                diaN = nacimiento.slice(8, 10);
            }

            let infoLugarResidencia = undefined;
            if(persona.municipioResidencia !== undefined && persona.municipioResidencia !== '') {
                infoLugarResidencia = divipola.find(divipola => divipola.codigoMunicipio === persona.municipioResidencia);
            }

            reset({
                tipoDocumento: infoTipoDocumento ? `${infoTipoDocumento.tipo} - ${infoTipoDocumento.sigla}`:'', // Arreglar visualización del tipo de documento
                numDocumento: numDocumentoAnterior,
                primerNombre: persona.primerNombre,
                segundoNombre: persona.segundoNombre,
                primerApellido: persona.primerApellido,
                segundoApellido: persona.segundoApellido,
                fechaNacimiento: ((persona.fechaNacimiento !== undefined) && (persona.fechaNacimiento !== ''))? `${anioN}-${mesN}-${diaN}`:'',//'1985-01-26',//persona.fechaNacimiento,yyyy-MM-dd
                sexo: persona.sexo,
                email: persona.email,
                eps: persona.eps,
                regimen: persona.regimen,
                paisNacimiento: persona.paisNacimiento,
                lugarResidencia: ((infoLugarResidencia !== undefined) && (infoLugarResidencia !== '')) ? `${infoLugarResidencia.municipio} - ${infoLugarResidencia.departamento}` : '',
                direccion: persona.direccion,
                telefono: persona.telefono
            });
        }
    }, [reset, persona, numDocumentoAnterior]);

    const onSubmit = async (formData) => {
        // No enviar peticiones al servidor si el usuario no ha editado ningun campo
        if(!isDirty) return;

        formData.numDocumentoAnterior = numDocumentoAnterior;
        const dividirTipoDocumento = formData.tipoDocumento.split(' - ');
        formData.tipoDocumento = dividirTipoDocumento[1];


        let infoLugarResidencia = undefined;
        if(formData.lugarResidencia !== undefined && formData.lugarResidencia !== '') {
            const divisionResidencia = formData.lugarResidencia.split(' - ');
            infoLugarResidencia = divipola.find(divipola => ( (divipola.municipio === divisionResidencia[0]) && (divipola.departamento === divisionResidencia[1]) ) );
            formData.municipioResidencia = infoLugarResidencia.codigoMunicipio;
            formData.departamentoResidencia = infoLugarResidencia.codigoDepartamento;
        } else {
            formData.municipioResidencia = '';
            formData.departamentoResidencia = '';
        }

        if(formData.fechaNacimiento.length !== 10){
            formData.fechaNacimiento = "";
        }

        const updatePersona = await updatePatient(formData);

        // Luego de actualizar la persona direccionar a resumen sumary
        if(updatePersona){
            toast.success('Persona actualizada exitosamente')
            reset();

            // redireccion para /sumary
            history.push({
                pathname: '/sumary',
                state: persona
            })
        } else {
            toast.error('Error al actualizar la persona')
        }


    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card shadow border-0 mt-3">
                        <h3 className="text-center my-3">Actualizar los datos básicos del paciente</h3>
                    </div>
                </div>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <div className="card shadow border-0 mt-2 p-3">
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Tipo de documento</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoDocumento")}
                                >
                                    <option value="">Seleccione un tipo</option>
                                    {tiposIdentificacion.map( tipo => <option key={tipo.sigla}>{tipo.tipo} - {tipo.sigla}</option> )}
                                </Form.Control>
                                {errors?.tipoDocumento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoDocumento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Identificación</Form.Label>
                                <Form.Control
                                    placeholder="Escriba la identificación"
                                    {...register("numDocumento")}
                                    type="text"
                                />
                                {errors?.numDocumento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.numDocumento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Primer nombre</Form.Label>
                                <Form.Control
                                    placeholder="Escriba primer nombre"
                                    {...register("primerNombre")}
                                    type="text"
                                />
                                {errors?.primerNombre && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.primerNombre.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Segundo nombre</Form.Label>
                                <Form.Control
                                    placeholder="Escriba segundo nombre"
                                    {...register("segundoNombre")}
                                    type="text"
                                />
                                {errors?.segundoNombre && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.segundoNombre.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Primer apellido</Form.Label>
                                <Form.Control
                                    placeholder="Escriba primer apellido"
                                    {...register("primerApellido")}
                                    type="text"
                                />
                                {errors?.primerApellido && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.primerApellido.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Segundo apellido</Form.Label>
                                <Form.Control
                                    placeholder="Escriba segundo apellido"
                                    {...register("segundoApellido")}
                                    type="text"
                                />
                                {errors?.segundoApellido && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.segundoApellido.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </div>
                    </div>

                    <div className="col-md-4 mt-3">
                        <div className="card shadow border-0 mt-2 p-3">
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Fecha de nacimiento</Form.Label>
                                <Form.Control
                                    placeholder="Escriba fecha de nacimiento"
                                    {...register("fechaNacimiento")}
                                    type="date"
                                />
                                {errors?.fechaNacimiento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fechaNacimiento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Sexo</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("sexo")}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {genero.map( genero => <option key={genero.tipo}>{genero.tipo}</option> )}
                                </Form.Control>
                                {errors?.sexo && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.sexo.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Correo electrónico</Form.Label>
                                <Form.Control
                                    placeholder="Escriba el email"
                                    {...register("email")}
                                    type="email"
                                />
                                {errors?.email && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.email.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Código EPS (EAPB)</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("eps")}
                                >
                                    <option value="">Seleccione un código</option>
                                    {EAPBS.map( eapb => <option key={eapb.codigoEps}>{eapb.codigoEps} - {eapb.nombre}</option> )}
                                </Form.Control>
                                {errors?.eps && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.eps.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label  className="small font-weight-bold">Regimen</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("regimen")}
                                >
                                    <option value="">Seleccione un regimen</option>
                                    {REGIMENES.map( reg => <option key={reg}>{reg}</option> )}
                                </Form.Control>
                                {errors?.regimen && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.regimen.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>

                        </div>
                    </div>

                    <div className="col-md-4 mt-3">
                        <div className="card shadow border-0 mt-2 p-3">
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">País nacimiento</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("paisNacimiento")}
                                >
                                    <option value="">Seleccione un país</option>
                                    {paises.map( pais => <option key={pais.nombre}>{pais.nombre}</option> )}
                                </Form.Control>
                                {errors?.paisNacimiento && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.paisNacimiento.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Lugar de residencia</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("lugarResidencia")}
                                >
                                    <option value="">Seleccione uno</option>
                                    {divipola.map( lugar => <option key={lugar.codigoMunicipio}>{lugar.municipio} - {lugar.departamento}</option> )}
                                </Form.Control>
                                {errors?.lugarResidencia && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.lugarResidencia.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Dirección</Form.Label>
                                <Form.Control
                                    placeholder="Escriba la dirección"
                                    {...register("direccion")}
                                    type="text"
                                />
                                {errors?.direccion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.direccion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small font-weight-bold">Teléfono</Form.Label>
                                <Form.Control
                                    placeholder="Digite el teléfono"
                                    {...register("telefono")}
                                    type="text"
                                />
                                {errors?.telefono && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.telefono.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                disabled={!isDirty}
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Actualizar persona</Button>
                        </div>
                    </div>

            </div>
            </Form>
        </div>
    );
};

export default UpdatePerson;