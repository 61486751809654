import useAuth from "../auth/useAuth";
import {useLocation} from "react-router-dom";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import loginResolver from "../validations/loginResolver";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import {useRef, useState} from "react";

const LoginPage = () => {

    const  captcha = useRef();

    const {register, handleSubmit, formState, reset} = useForm({resolver: loginResolver});
    const {errors} = formState;

    const location = useLocation();
    const {login} = useAuth();

    const [activar, setActivar] = useState(null);


    const onSubmit = async (formData) => {
        if(activar){
            //LLamar a la funcion de login del AuthProvider
            formData.password = formData.pass;
            const respuesta = await login(formData, location.state?.from);
            if(respuesta) {
                toast.success('Ingreso correcto')
            } else {
                toast.error('Error de usuario y/o contraseña')

                captcha.current.reset();
                reset();
                setActivar(false);
            }
        } else {
            toast.error('Por favor, acepte el Captcha.')
        }

    }
    
    const onChange = (value) => {
        setActivar(true);
    }



    return (
        <Container className="mt-5">

            <Row className="row">
                <Col className="col-8 offset-2 align-self-center">

                        <Row className="row">
                                <Col className="col header-content-left text-center">
                                    <img className="my-4 ml-4" src="/img/logo_unipamplona.png" alt="logo unipamplona" height="226"/>
                                </Col>

                                <div className="col header-content-right">
                                    <h3 className="text-center">Ingreso</h3>

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group>
                                            <Form.Label>Usuario</Form.Label>
                                            <Form.Control
                                                placeholder="Escriba un usuario"
                                                {...register("email")}
                                                type="email"
                                            />
                                            {errors?.email && (
                                                <Form.Text>
                                                    <Alert variant="danger">
                                                        {errors.email.message}
                                                    </Alert>
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Contraseña</Form.Label>
                                            <Form.Control
                                                placeholder="Escriba un contraseña"
                                                {...register("pass")}
                                                type="password"
                                            />
                                            {errors?.pass && (
                                                <Form.Text>
                                                    <Alert variant="danger">
                                                        {errors.pass.message}
                                                    </Alert>
                                                </Form.Text>
                                            )}
                                        </Form.Group>

                                        <div className="form_group_recaptcha">
                                            <ReCAPTCHA
                                               type="image"
                                               ref={captcha}
                                               sitekey="6LdLUiYdAAAAANAPoB7uJeKQnawVBSCVr4EQBDRR"
                                               onChange={onChange}
                                            />
                                        </div>




                                        <Button
                                            disabled={!activar}
                                            className="mt-3"
                                            variant="outline-dark"
                                            block
                                            onClick={handleSubmit(onSubmit)}
                                        >Ingresar</Button>
                                    </Form>

                                </div>

                        </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;