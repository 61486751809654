const paises = [
    { nombre: 'AFGANISTÁN'},
    { nombre: 'ALBANIA'},
    { nombre: 'ALEMANIA'},
    { nombre: 'ANDORRA'},
    { nombre: 'ANGOLA'},
    { nombre: 'ANGUILA'},
    { nombre: 'ANTÁRTIDA'},
    { nombre: 'ANTIGUA Y BARBUDA'},
    { nombre: 'ANTILLAS NEERLANDESAS'},
    { nombre: 'ARABIA SAUDÍ'},
    { nombre: 'ARGELIA'},
    { nombre: 'ARGENTINA'},
    { nombre: 'ARMENIA'},
    { nombre: 'ARUBA'},
    { nombre: 'AUSTRALIA'},
    { nombre: 'AUSTRIA'},
    { nombre: 'AZERBAIYÁN'},
    { nombre: 'BAHAMAS'},
    { nombre: 'BAHREIN'},
    { nombre: 'BANGLADESH'},
    { nombre: 'BARBADOS'},
    { nombre: 'BÉLGICA'},
    { nombre: 'BÉLICE'},
    { nombre: 'BENIN'},
    { nombre: 'BERMUDAS'},
    { nombre: 'BHUTÁN'},
    { nombre: 'BIELORRUSIA'},
    { nombre: 'BOLIVIA'},
    { nombre: 'BONAIRE SAN EUSTAQUIO Y SABA'},
    { nombre: 'BOSNIA Y HERZEGOVINA'},
    { nombre: 'BOTSUANA'},
    { nombre: 'BRASIL'},
    { nombre: 'BRUNEI DARUSSALAM'},
    { nombre: 'BULGARIA'},
    { nombre: 'BURKINA FASO'},
    { nombre: 'BURUNDI'},
    { nombre: 'CABO VERDE'},
    { nombre: 'CAMBOYA'},
    { nombre: 'CAMERÚN'},
    { nombre: 'CANADÁ'},
    { nombre: 'CHAD'},
    { nombre: 'CHILE'},
    { nombre: 'CHINA'},
    { nombre: 'CHIPRE'},
    { nombre: 'COLOMBIA'},
    { nombre: 'COMORAS'},
    { nombre: 'COSTA DE MARFIL'},
    { nombre: 'COSTA RICA'},
    { nombre: 'CROACIA'},
    { nombre: 'CUBA'},
    { nombre: 'CURAZAO'},
    { nombre: 'DINAMARCA'},
    { nombre: 'DJIBOUTI'},
    { nombre: 'DOMINICA'},
    { nombre: 'ECUADOR'},
    { nombre: 'EGIPTO'},
    { nombre: 'EL SALVADOR'},
    { nombre: 'EMIRATOS ARABES UNIDOS'},
    { nombre: 'ERITREA'},
    { nombre: 'ESLOVENIA'},
    { nombre: 'ESPAÑA'},
    { nombre: 'ESTADO DE LA CIUDAD DEL VATICANO (SANTA SE'},
    { nombre: 'ESTADO FEDERAL DE MICRONESIA'},
    { nombre: 'ESTADOS UNIDOS DE AMERICA'},
    { nombre: 'ESTONIA'},
    { nombre: 'ETIOPÍA'},
    { nombre: 'FEDERACIÓN DE RUSIA'},
    { nombre: 'FIJI'},
    { nombre: 'FILIPINAS'},
    { nombre: 'FINLANDIA'},
    { nombre: 'FRANCIA'},
    { nombre: 'FRANCIA METROPOLITANA'},
    { nombre: 'GABÓN'},
    { nombre: 'GAMBIA'},
    { nombre: 'GEORGIA'},
    { nombre: 'GEORGIA DEL SUR E ISLAS SANDWICH DEL SUR'},
    { nombre: 'GHANA'},
    { nombre: 'GIBRALTAR'},
    { nombre: 'GRANADA'},
    { nombre: 'GRECIA'},
    { nombre: 'GROENLANDIA'},
    { nombre: 'GUADALUPE'},
    { nombre: 'GUAM'},
    { nombre: 'GUATEMALA'},
    { nombre: 'GUAYANA FRANCESA'},
    { nombre: 'GUERNSEY'},
    { nombre: 'GUINEA'},
    { nombre: 'GUINEA ECUATORIAL'},
    { nombre: 'GUINEA-BISSAU'},
    { nombre: 'GUYANA'},
    { nombre: 'HAITÍ'},
    { nombre: 'HONDURAS'},
    { nombre: 'HONG KONG'},
    { nombre: 'HUNGRÍA'},
    { nombre: 'INDIA'},
    { nombre: 'INDONESIA'},
    { nombre: 'IRAQ'},
    { nombre: 'IRLANDA'},
    { nombre: 'ISLA BOUVET'},
    { nombre: 'ISLA CHRISTMAS'},
    { nombre: 'ISLA DE MAN'},
    { nombre: 'ISLA NIUE'},
    { nombre: 'ISLA NORFOLK'},
    { nombre: 'ISLA PITCAIRN'},
    { nombre: 'ISLANDIA'},
    { nombre: 'ISLAS ÅLAND'},
    { nombre: 'ISLAS CAIMÁN'},
    { nombre: 'ISLAS COCOS(KEELING)'},
    { nombre: 'ISLAS COOK'},
    { nombre: 'ISLAS FEROE'},
    { nombre: 'ISLAS HEARD Y MCDONALD'},
    { nombre: 'ISLAS MALVINAS(FALKLAND)'},
    { nombre: 'ISLAS MARIANAS DEL NORTE'},
    { nombre: 'ISLAS MARSHALL'},
    { nombre: 'ISLAS PERIFÉRICAS MENORES DE LOS ESTADOS U'},
    { nombre: 'ISLAS SALOMÓN'},
    { nombre: 'ISLAS SVALBARD Y JAN MAYEN'},
    { nombre: 'ISLAS TURCAS Y CAICOS'},
    { nombre: 'ISLAS VÍRGENES BRITÁNICAS'},
    { nombre: 'ISLAS VÍRGENES DE LOS ESTADOS UNIDOS'},
    { nombre: 'ISLAS WALLIS Y FUTUNA'},
    { nombre: 'ISRAEL'},
    { nombre: 'ITALIA'},
    { nombre: 'JAMAICA'},
    { nombre: 'JAPÓN'},
    { nombre: 'JERSEY'},
    { nombre: 'JORDANIA'},
    { nombre: 'KAZAJISTÁN'},
    { nombre: 'KENYA'},
    { nombre: 'KIRGUISTÁN'},
    { nombre: 'KIRIBATI'},
    { nombre: 'KUWAIT'},
    { nombre: 'LESOTO'},
    { nombre: 'LETONIA'},
    { nombre: 'LIBANO'},
    { nombre: 'LIBERIA'},
    { nombre: 'LIBIA'},
    { nombre: 'LIECHTENSTEIN'},
    { nombre: 'LITUANIA'},
    { nombre: 'LUXEMBURGO'},
    { nombre: 'MACAO'},
    { nombre: 'MACEDONIA DEL NORTE'},
    { nombre: 'MADAGASCAR'},
    { nombre: 'MALASIA'},
    { nombre: 'MALAWI'},
    { nombre: 'MALDIVAS'},
    { nombre: 'MALÍ'},
    { nombre: 'MALTA'},
    { nombre: 'MARRUECOS'},
    { nombre: 'MARTINICA'},
    { nombre: 'MAURICIO'},
    { nombre: 'MAURITANIA'},
    { nombre: 'MAYOTTE'},
    { nombre: 'MÉXICO'},
    { nombre: 'MÓNACO'},
    { nombre: 'MONGOLIA'},
    { nombre: 'MONTENEGRO'},
    { nombre: 'MONTSERRAT'},
    { nombre: 'MOZAMBIQUE'},
    { nombre: 'MYANMAR'},
    { nombre: 'NAMIBIA'},
    { nombre: 'NAURU'},
    { nombre: 'NEPAL'},
    { nombre: 'NICARAGUA'},
    { nombre: 'NÍGER'},
    { nombre: 'NIGERIA'},
    { nombre: 'NORUEGA'},
    { nombre: 'NUEVA CALEDONIA'},
    { nombre: 'NUEVA ZELANDA'},
    { nombre: 'OMÁN'},
    { nombre: 'PAÍSES BAJOS'},
    { nombre: 'PAKISTÁN'},
    { nombre: 'PALAU'},
    { nombre: 'PANAMÁ'},
    { nombre: 'PAPUA NUEVA GUINEA'},
    { nombre: 'PARAGUAY'},
    { nombre: 'PERÚ'},
    { nombre: 'POLINESIA FRANCESA'},
    { nombre: 'POLONIA'},
    { nombre: 'PORTUGAL'},
    { nombre: 'PUERTO RICO'},
    { nombre: 'QATAR'},
    { nombre: 'REINO UNIDO'},
    { nombre: 'REPÚBLICA CENTROAFRICANA'},
    { nombre: 'REPÚBLICA CHECA'},
    { nombre: 'REPÚBLICA DE COREA'},
    { nombre: 'REPÚBLICA DE MOLDOVA'},
    { nombre: 'REPUBLICA DEMOCRÁTICA DEL CONGO'},
    { nombre: 'REPÚBLICA DOMINICANA'},
    { nombre: 'REPÚBLICA ESLOVACA / ESLOVAQUIA'},
    { nombre: 'REPÚBLICA ISLÁMICA DE IRÁN'},
    { nombre: 'REPUBLICA POPULAR DEL CONGO'},
    { nombre: 'REPÚBLICA POPULAR DEMOCRÁTICA DE COREA'},
    { nombre: 'REPÚBLICA POPULAR DEMOCRÁTICA LAO'},
    { nombre: 'REPÚBLICA UNIDA DE TANZANIA'},
    { nombre: 'REUNIÓN'},
    { nombre: 'RUANDA'},
    { nombre: 'RUMANIA'},
    { nombre: 'SAHARA OCCIDENTAL'},
    { nombre: 'SAMOA'},
    { nombre: 'SAMOA ESTADOUNIDENSE'},
    { nombre: 'SAN BARTOLOMÉ'},
    { nombre: 'SAN KITTS Y NEVIS'},
    { nombre: 'SAN MARINO'},
    { nombre: 'SAN MARTIN'},
    { nombre: 'SAN PEDRO Y MIGUELON'},
    { nombre: 'SAN VICENTE Y LAS GRANADINAS'},
    { nombre: 'SANTA HELENA'},
    { nombre: 'SANTA LUCIA'},
    { nombre: 'SANTO TOMÉ Y PRÍNCIPE'},
    { nombre: 'SENEGAL'},
    { nombre: 'SERBIA'},
    { nombre: 'SEYCHELLES'},
    { nombre: 'SIERRA LEONA'},
    { nombre: 'SINGAPUR'},
    { nombre: 'SINT MAARTEN'},
    { nombre: 'SIRIA'},
    { nombre: 'SOMALIA'},
    { nombre: 'SRI LANKA'},
    { nombre: 'SUDÁFRICA'},
    { nombre: 'SUDÁN'},
    { nombre: 'SUDÁN DEL SUR'},
    { nombre: 'SUECIA'},
    { nombre: 'SUIZA'},
    { nombre: 'SURINAME'},
    { nombre: 'SWAZILANDIA'},
    { nombre: 'TAILANDIA'},
    { nombre: 'TAIWÁN'},
    { nombre: 'TAJIKISTÁN'},
    { nombre: 'TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO'},
    { nombre: 'TERRITORIO OCUPADO DE PALESTINA'},
    { nombre: 'TERRITORIOS AUSTRALES FRANCESES'},
    { nombre: 'TIMOR ORIENTAL'},
    { nombre: 'TOGO'},
    { nombre: 'TOKELAU'},
    { nombre: 'TONGA'},
    { nombre: 'TRINIDAD Y TOBAGO'},
    { nombre: 'TÚNEZ'},
    { nombre: 'TURKMENISTAN'},
    { nombre: 'TURQUÍA'},
    { nombre: 'TUVALU'},
    { nombre: 'UCRANIA'},
    { nombre: 'UGANDA'},
    { nombre: 'URUGUAY'},
    { nombre: 'UZBEKISTAN'},
    { nombre: 'VANUATU'},
    { nombre: 'VENEZUELA'},
    { nombre: 'VIETNAM'},
    { nombre: 'YEMEN'},
    { nombre: 'YUGOSLAVIA'},
    { nombre: 'ZAMBIA'},
    { nombre: 'ZIMBABWE'},
];
export default paises;