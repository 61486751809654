import {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import tiposMuestra from "../../helpers/tiposMuestra";
import {useForm} from "react-hook-form";
import sampleCreateResolver from "../../validations/sampleCreateResolver";
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {getFechaHoy} from "../../helpers/calcularFecha";


const CovidCreatePage = ({location}) => {

    const [patient] = useState(location.state);
    const [fecha, setFecha] = useState('ninguna');
    const [fechaHoy] = useState(getFechaHoy());
    const [rojo, setRojo] = useState(false)
    const {covid} = patient;



    useEffect(() => {

        if(covid.length > 0 && fechaHoy){
            const ultimo = covid[covid.length - 1];

            const {fechaCreacionMuestra} = ultimo;
            const date = new Date(fechaCreacionMuestra);

            let anioU = date.getFullYear('America/Bogota') + '';
            let mesU = date.getMonth('America/Bogota') + 1 + '';
            let diaU = date.getDate('America/Bogota') + '';

            diaU = diaU.length === 1 ? '0' + diaU : diaU;
            mesU = mesU.length === 1 ? '0' + mesU : mesU;

            setFecha(`${diaU}/${mesU}/${anioU}`)

            if(fechaHoy === fecha){
                setRojo(true)
                toast.error('Tenga precaución para no repetir muestras al paciente, ya tiene creada muestra con fecha de hoy')
            } else {
                setRojo(false)
            }

        }
    },[covid, fecha, fechaHoy])


    const {register, handleSubmit, formState, reset} = useForm({resolver: sampleCreateResolver});
    const {errors} = formState;

    const {createSampleCovid} = useAuth();

    const history = useHistory();

    const onSubmit = async (formData) => {
        formData.numDocumento = patient.numDocumento;
        const fecha = formData.fechaMuestra;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fechaMuestra = `${dia}/${mes}/${anio}`

        // Enviar al servidor con cedula del paciente y asociar la muestra
        const muestra = await createSampleCovid(formData);

        if(muestra){
            toast.success('Muestra asociada exitosamente')
            reset();

            // redireccion para asociar muestra
            history.push({
                pathname: '/sumary',
            })
        } else {
            toast.error('Error al asociar la muestra')
        }

    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className="card shadow border-0 mt-3">
                        <h3 className="text-center my-3">Asociar muestra Covid al paciente</h3>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mt-3">

                </div>
                <div className="col-md-4 mt-3">
                    <div className="card shadow border-0 mt2 p-3">
                        <h6 className="font-weight-bold text-center">Datos del paciente:</h6>
                        <small className="font-weight-bold text-center">{patient.tipoDocumento}: {patient.numDocumento} - {patient.primerNombre} {patient.primerApellido}</small>
                        <small className="font-weight-bold text-center">Muestras asociadas al paciente: {patient.covid && patient.covid.length}</small>
                        <small className="font-weight-bold text-center">Fecha de la ultima muestra: <span className={ rojo ? 'text-danger': 'text-dark'}>{patient.covid && fecha}</span></small>
                    </div>

                    <div className="card shadow border-0 mt-2 p-3">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Consecutivo del día</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register("consecutivo")}
                                />
                                {errors?.consecutivo && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.consecutivo.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Fecha de toma de muestra</Form.Label>
                                <Form.Control
                                    placeholder="Escriba la identificación"
                                    {...register("fechaMuestra")}
                                    type="date"
                                />
                                {errors?.fechaMuestra && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.fechaMuestra.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            {/*<div className="row">
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>Hora de toma muestra</Form.Label>
                                        <Form.Control
                                            {...register("horaMuestra")}
                                            type="number"
                                        />
                                        {errors?.horaMuestra && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.horaMuestra.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>Min de toma muestra</Form.Label>
                                        <Form.Control
                                            {...register("minMuestra")}
                                            type="number"
                                        />
                                        {errors?.minMuestra && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.minMuestra.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>*/}
                            <Form.Group>
                                <Form.Label>Tipo de muestra</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("tipoMuestra")}
                                >
                                    <option value="">Seleccione un tipo</option>
                                    {tiposMuestra.map( tipo => <option key={tipo}>{tipo}</option> )}
                                </Form.Control>
                                {errors?.tipoMuestra && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.tipoMuestra.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <br/>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Asociar muestra a paciente</Button>
                        </Form>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CovidCreatePage;