import React from 'react';
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";

const SampleFindUpdatePage = () => {

    const {getPatient} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {
        //const {}

        const persona = await getPatient(formData.identificacion)
        if(persona) {
            //detectar si la persona tiene muestras y si son de la fecha actual cargar el actual o crear uno nuevo
            const {examenes} = persona;
            if(examenes.length > 0){
                reset();
                // redireccion para asociar muestra
                history.push({
                    pathname: '/muestra/listar',
                    state: persona
                })
            } else{
                toast.warn('El paciente no tiene exámenes')
            }
            /*toast.success('La persona ya existe, cree la muestra a continuación...')
            //console.log('Existe, asociar muestra a la identificacion de la persona')
            reset();
            // redireccion para asociar muestra
            history.push({
                pathname: '/muestra/crear',
                state: persona
            })*/

        } else {
            toast.warn('La persona no existe')
            /*reset();
            history.push({
                pathname: '/persona/crear',
                state: formData.identificacion
            })*/
        }

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Buscar muestras por paciente</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación del paciente</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar muestra(s)</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

export default SampleFindUpdatePage;