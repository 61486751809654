import React from 'react';
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

import routes from "../../helpers/routes";
import roles from "../../helpers/roles";

const AntigenoListPage = ({location}) => {
    const persona = location.state;
    const {antigeno} = persona;

    const {getSampleAntigeno, deleteSampleAntigeno, hasRole} = useAuth();

    const history = useHistory();


    const handleEditar = async (idMuestra) => {

        // llamar a la api por los datos de la muestra, capturarlos y redireccionar a SivigilaUpdatePage
        const muestra = await getSampleAntigeno(idMuestra);
        if(muestra){
            history.push({
                pathname: routes.muestra.antigeno.actualizar,//'/muestra/actualizar',
                state: {muestra, persona}
            })
        } else {
            toast.error('Error al buscar la muestra')
        }


        // Despues de editar ma muestra redireccionar a este listado y actualizar las muestras
    }

    const handleEliminar = async (idPersona, idAntigenoArray, idAntigeno) => {
        /*console.log('idPersona', idPersona)
        console.log('idAntigenoArray', idAntigenoArray)
        console.log('idAntigeno', idAntigeno)*/

        // llamar a la api por los datos de la muestra, capturarlos y redireccionar a SivigilaUpdatePage
        const muestra = await deleteSampleAntigeno(idPersona, idAntigenoArray, idAntigeno);
        if(muestra){
            toast.success('Prueba antígeno eliminada correctamente')

            history.push({
                pathname: routes.sumary
            })
        } else {
            toast.error('Error al eliminar la prueba antígeno')
        }

    }

    const handleSubirFicha = (id) => {

    }
    const handleDescargarFicha = (id) => {

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card border-0 shadow p-3 mt-4">
                        <h3 className="text-center">Listado de pruebas Antígeno del paciente</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card border-0 shadow p-3 my-3">
                        <p><span className="font-weight-bold">Paciente:</span> {persona.primerNombre} {persona.primerApellido}</p>
                        <p><span className="font-weight-bold">Identificación:</span> {persona.numDocumento}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card border-0 shadow">
                        <table className="table table-hover table-sm small my-auto ">
                            <thead>
                            <tr>
                                <th className="text-center" scope="col">#</th>
                                <th className="text-center" scope="col">Consecutivo</th>
                                <th className="text-center" scope="col">Lugar</th>
                                <th className="text-center" scope="col">Fecha de prueba</th>
                                <th className="text-center" scope="col">Información faltante</th>
                                <th className="text-center" scope="col">Opciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                antigeno.map((examen, indx) => (
                                    <tr key={examen._id}>
                                        <th className="text-center" scope="row">{indx + 1}</th>
                                        <td className="text-center">{examen.consecutivo}</td>
                                        <td className="text-center">{examen.brigada}</td>
                                        <td className="text-center">
                                            {new Date(examen.fechaCreacionMuestra).getDate()}/
                                            {new Date(examen.fechaCreacionMuestra).getMonth()+1}/
                                            {new Date(examen.fechaCreacionMuestra).getFullYear()} - {new Date(examen.fechaCreacionMuestra).getHours()}:
                                            {new Date(examen.fechaCreacionMuestra).getMinutes()} Hrs
                                        </td>
                                        <td className="text-center">Informacion faltante</td>
                                        <td className="text-center">
                                            <div className="text-right">
                                                <button className="btn btn-sm btn-warning mx-2" onClick={() => handleEditar(examen.identificador)}>Editar</button>
                                                <button className="btn btn-sm btn-secondary mx-2" onClick={() => handleSubirFicha(examen.identificador)}>Subir ficha</button>
                                                <button className="btn btn-sm btn-success mx-2" onClick={() => handleDescargarFicha(examen.identificador)}>Descargar ficha</button>

                                                <button
                                                    className="btn btn-sm btn-danger mx-2"
                                                    disabled={!hasRole(roles.laboratorista) }
                                                    onClick={() => handleEliminar(persona._id, examen._id, examen.identificador)}
                                                    data-toggle="tooltip" data-placement="lefth" title={!hasRole(roles.laboratorista) ? "Solo LABORATORISTA puede eliminar muestras":""}
                                                >
                                                    Eliminar
                                                </button>
                                            </div>
                                        </td>

                                    </tr>
                                ))

                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AntigenoListPage;